/* eslint-disable arrow-body-style */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { thirdPartyAgreements } from '../../../utils/BookingUtils';

export const ReviewTermsAndConditions = ({ detailFlight, reviewData, setReviewData }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div className='CheckoutForm__disclaimer'>
        Terms of your booking:
        <ul style={{ paddingLeft: 16, marginTop: 10 }}>
          <li>
            I accept that my flight is subject to the terms of my chosen{' '}
            <a
              href={thirdPartyAgreements[detailFlight.validatingCarrierCode]}
              rel='noreferrer'
              target='_blank'
            >
              {' '}
              Travel Provider.
            </a>
          </li>
          <li>
            I acknowledge that once my 24 hour cooling off period expires, refunds are subject to
            fare rules and may not be permitted.
          </li>
        </ul>
        <div style={{marginBottom: 10}}>
          By clicking on the button below, I acknowledge that I have read and accept the{' '}
          <NavLink to='/terms'>Terms of Use</NavLink>,{' '}
          <HashLink to='/terms#cancellation-policy'>Cancelation Policy</HashLink> and{' '}
          <HashLink to='/privacy-policy'>Privacy Policy</HashLink> and I am responsible for adhering
          to any Government travel advice.
        </div>
      </div>
    </div>
  );
};
