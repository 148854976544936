import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button as ReactstrapButton } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = (props) => {
  const [areYouSure, setAreYouSure] = useState(false);

  return (
    <ReactstrapButton
      {...props}
      className={['Button', props.className].join(' ')}
      onClick={() => (areYouSure ? props.onConfirm() : props.confirm ? setAreYouSure(true) : props.onClick())}
    >
      {props.loading && <div className="LoadingDots" />}
      {!props.loading && (
        areYouSure
          ? <span>Are you sure?</span>
          : (
            <span>{props.icon
                        && (
                        <FontAwesomeIcon
                          className="Button__icon"
                          icon={props.icon}
                        />
                        )}
              {props.children}
            </span>
          )
      )}
    </ReactstrapButton>
  );
};

Button.propTypes = {
  loading: PropTypes.bool,
  block: PropTypes.bool,
  confirm: PropTypes.bool,
};

Button.defaultProps = {
  loading: false,
  block: false,
  confirm: false
};

export default Button;
