import React from 'react';
import { Table } from 'reactstrap';
import { Footer } from '.';

const Coronavirus = () => (
  <div className="main-container how-it-work-container">
    <div className="header">
      <div className="header-content text-center">
        <h1 className="title">Coronavirus (COVID-19) Update</h1>
        {/* <p className="sub-title">How Does Pay Later Travel Work?</p> */}
      </div>
    </div>
    <div className="content cv" style={{ paddingTop: '40px', paddingBottom: '40px' }}>
      <div className="container">
        <p>We understand that many people are concerned about how the Coronavirus (COVID-19) could affect their travel plans. To help you, we are continually monitoring this situation to give our customers the information and help they need.</p>
        <p>We’re here to help with any travel related questions. As you’ll appreciate, we are receiving a lot of enquiries right now. In addition, airlines, hotels and other travel partners are continually reviewing their policies as the situation develops.</p>
        <p>So, unless you are due to travel within the next two weeks, we advise you to hold off contacting us until nearer your travel date. That way, when you do get in touch, we’ll have the very latest information and advice for you.</p>
        <hr style={{ visibility: 'hidden' }} />
        <h2>We recommend you regularly check the latest government advice</h2>
        <p><a href="https://www.smartraveller.gov.au/" target="_blank" rel="noreferrer">Australian Government Smart Traveller website</a></p>
        <p><a href="https://www.who.int/" target="_blank" rel="noreferrer">Word Health Organisation</a></p>
        <p><a href="https://coronavirus.jhu.edu/map.html" target="_blank" rel="noreferrer">John Hopkins University&apos;s detailed map of the spread of the virus</a></p>
        <hr style={{ visibility: 'hidden' }} />
        <h2>For Changes and Cancellations (COVID-19)</h2>
        <p>The Airlines are actioning enquiries with priority being given to those travelling within the next four weeks:</p>
        <ul style={{ fontSize: '22px' }}>
          <li>
            If you&apos;re travelling
            <b>within 24 hours</b>
            , please contact the airline directly regarding your flights.
            {' '}
          </li>
          <li>
            If you&apos;re travelling
            <b>within 3 days</b>
            , please contact the airline directly to make your cancellation.
            {' '}
          </li>
          <li>
            If you&apos;re travelling
            <b>within 7-14 days</b>
            , we are prioritising your response.
          </li>
        </ul>
        <hr style={{ visibility: 'hidden' }} />
        <h2>Impact on Travel (Summary)</h2>
        <p>Each of Pay Later Travel’s suppliers (Airlines) have their own policies regarding changes and cancellations. Suppliers have been updating their policies frequently as the global situation evolves. Pay Later Travel will assist our customers with changing or cancelling their bookings based on each Airline’s current policy. Here is the latest information we have from our main suppliers:</p>
        <Table striped style={{ fontSize: '18px', wordBreak: 'break-word', marginBottom: '30px' }}>
          {/* <table width="1170"> */}
          <thead>
            <tr>
              <th>
                <strong>Supplier</strong>
              </th>
              <th>
                <strong>URL</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Air Canada
              </td>
              <td>
                <a href="https://www.aircanada.com/ca/en/aco/home/book/travel-news-and-updates/2020/covid-19.html">https://www.aircanada.com/ca/en/aco/home/book/travel-news-and-updates/20...</a>
              </td>
            </tr>
            <tr>
              <td>
                Air China
              </td>
              <td>
                <a href="https://www.airchina.com.au/AU/GB/info/news/">https://www.airchina.com.au/AU/GB/info/news/</a>
              </td>
            </tr>
            <tr>
              <td>
                Air France
              </td>
              <td>
                <a href="https://wwws.airfrance.com.au/">https://wwws.airfrance.com.au/</a>
              </td>
            </tr>
            <tr>
              <td>
                Air India
              </td>
              <td>
                <a href="http://www.airindia.in/images/pdf/Waiver-due-COVID-19-14th-March-2020.pdf">http://www.airindia.in/images/pdf/Waiver-due-COVID-19-14th-March-2020.pdf</a>
              </td>
            </tr>
            <tr>
              <td>
                Air New Zealand
              </td>
              <td>
                <a href="https://www.airnewzealand.com/travel-alerts#SfgQWwKAXFfu1atRl3K7">https://www.airnewzealand.com/travel-alerts#SfgQWwKAXFfu1atRl3K7</a>
              </td>
            </tr>
            <tr>
              <td>
                All Nippon Airways
              </td>
              <td>
                <a href="https://www.ana.co.jp/en/jp/notice/notice_011.html">https://www.ana.co.jp/en/jp/notice/notice_011.html</a>
              </td>
            </tr>
            <tr>
              <td>
                American Airlines
              </td>
              <td>
                <a href="https://www.americanairlines.com.au/i18n/travel-info/travel-alerts.jsp">https://www.americanairlines.com.au/i18n/travel-info/travel-alerts.jsp</a>
              </td>
            </tr>
            <tr>
              <td>
                Austrian Airlines
              </td>
              <td>
                <a href="https://www.austrian.com/Info/Flightinformation/Travel%20Alerts.aspx?sc_lang=en&amp;cc=AU">https://www.austrian.com/Info/Flightinformation/Travel%20Alerts.aspx?sc_...</a>
              </td>
            </tr>
            <tr>
              <td>
                British Airways
              </td>
              <td>
                <a href="https://www.britishairways.com/en-us/information/travel-advice">https://www.britishairways.com/en-us/information/travel-advice</a>
              </td>
            </tr>
            <tr>
              <td>
                Cathay Pacific Airways
              </td>
              <td>
                <a href="https://www.cathaypacific.com/cx/en_AU/travel-information/travel-preparation/travel-advisories/novel-coronavirus-information-centre/information-and-updates.html">https://www.cathaypacific.com/cx/en_AU/travel-information/travel-prepara...</a>
              </td>
            </tr>
            <tr>
              <td>
                China Airlines
              </td>
              <td>
                <a href="https://www.china-airlines.com/au/en/discover/news/travel-advisory?travelAlert=36257-7030">https://www.china-airlines.com/au/en/discover/news/travel-advisory?trave...</a>
              </td>
            </tr>
            <tr>
              <td>
                China Eastern Airlines
              </td>
              <td>
                <a href="https://us.ceair.com/newCMS/us/en/content/en_News/TravelAlert/202001/t20200124_13804.html">https://us.ceair.com/newCMS/us/en/content/en_News/TravelAlert/202001/t20...</a>
              </td>
            </tr>
            <tr>
              <td>
                China Southern
              </td>
              <td>
                <a href="https://www.csair.com/au/en/about/news/news/2020/1dvta13osg8i0.shtml">https://www.csair.com/au/en/about/news/news/2020/1dvta13osg8i0.shtml</a>
              </td>
            </tr>
            <tr>
              <td>
                Delta Air Lines
              </td>
              <td>
                <a href="https://pro.delta.com/content/agency/au/en/news/exception-policy-archive/2020/march-2020/coronavirus-situation---change-fee---bulletin-2.html">https://pro.delta.com/content/agency/au/en/news/exception-policy-archive...</a>
              </td>
            </tr>
            <tr>
              <td>
                Emirates
              </td>
              <td>
                <a href="https://www.emirates.com/au/english/help/travel-updates/#3515">https://www.emirates.com/au/english/help/travel-updates/#3515</a>
              </td>
            </tr>
            <tr>
              <td>
                Etihad Airways
              </td>
              <td>
                <a href="https://www.etihad.com/en/travel-updates/covid-19">https://www.etihad.com/en/travel-updates/covid-19</a>
              </td>
            </tr>
            <tr>
              <td>
                Fiji Airways
              </td>
              <td>
                <a href="https://pro.delta.com/content/agency/au/en/news/exception-policy-archive/2020/march-2020/coronavirus-situation---change-fee---bulletin-2.html">https://pro.delta.com/content/agency/au/en/news/exception-policy-archive...</a>
              </td>
            </tr>
            <tr>
              <td>
                Hawaiian Airlines
              </td>
              <td>
                <a href="https://www.hawaiianairlines.com.au/coronavirus">https://www.hawaiianairlines.com.au/coronavirus</a>
              </td>
            </tr>
            <tr>
              <td>
                Japan Airlines
              </td>
              <td>
                <a href="https://www.jal.co.jp/jp/en/info/2020/other/200228/?_ga=2.30407671.933826762.1583420585-967388713.1583420585">https://www.jal.co.jp/jp/en/info/2020/other/200228/?_ga=2.30407671.93382...</a>
              </td>
            </tr>
            <tr>
              <td>
                Jetstar
              </td>
              <td>
                <a href="https://www.jetstar.com/au/en/travel-alerts">https://www.jetstar.com/au/en/travel-alerts</a>
              </td>
            </tr>
            <tr>
              <td>
                KLM
              </td>
              <td>
                <a href="https://www.klm.com/travel/au_en/prepare_for_travel/up_to_date/flight_update/index.htm">https://www.klm.com/travel/au_en/prepare_for_travel/up_to_date/flight_up...</a>
              </td>
            </tr>
            <tr>
              <td>
                Korean Air
              </td>
              <td>
                <a href="https://www.koreanair.com/content/koreanair/global/en/customer-support.html#faqs">https://www.koreanair.com/content/koreanair/global/en/customer-support.h...</a>
              </td>
            </tr>
            <tr>
              <td>
                LATAM Airlines
              </td>
              <td>
                <a href="https://www.latam.com/en_us/press-room/declarations/what-you-should-know-before-you-start-your-travel/">https://www.latam.com/en_us/press-room/declarations/what-you-should-know...</a>
              </td>
            </tr>
            <tr>
              <td>
                Lufthansa
              </td>
              <td>
                <a href="https://www.lufthansa.com/de/en/flight-information">https://www.lufthansa.com/de/en/flight-information</a>
              </td>
            </tr>
            <tr>
              <td>
                Malaysia Airlines
              </td>
              <td>
                <a href="https://www.malaysiaairlines.com/au/en/advisory/coronavirus-entry-restrictions.html">https://www.malaysiaairlines.com/au/en/advisory/coronavirus-entry-restri...</a>
              </td>
            </tr>
            <tr>
              <td>
                Philippine Airlines
              </td>
              <td>
                <a href="https://www.philippineairlines.com/en/faqs/2019novelcoronavirusncov">https://www.philippineairlines.com/en/faqs/2019novelcoronavirusncov</a>
              </td>
            </tr>
            <tr>
              <td>
                Qantas Airways
              </td>
              <td>
                <a href="https://www.qantas.com/au/en/travel-info/travel-updates.html">https://www.qantas.com/au/en/travel-info/travel-updates.html</a>
              </td>
            </tr>
            <tr>
              <td>
                Qatar Airways
              </td>
              <td>
                <a href="https://qatarairways.zendesk.com/hc/en-us/articles/360006229097-COVID-19-Coronavirus-Update">https://qatarairways.zendesk.com/hc/en-us/articles/360006229097-COVID-19...</a>
              </td>
            </tr>
            <tr>
              <td>
                Royal Brunei Airlines
              </td>
              <td>
                <a href="https://www.flyroyalbrunei.com/australia/en/covid-19-updates/">https://www.flyroyalbrunei.com/australia/en/covid-19-updates/</a>
              </td>
            </tr>
            <tr>
              <td>
                Singapore Airlines
              </td>
              <td>
                <a href="https://www.singaporeair.com/en_UK/sg/travel-info/covid-19/">https://www.singaporeair.com/en_UK/sg/travel-info/covid-19/</a>
              </td>
            </tr>
            <tr>
              <td>
                South African Airways
              </td>
              <td>
                <a href="https://www.flysaa.com/">https://www.flysaa.com/</a>
              </td>
            </tr>
            <tr>
              <td>
                SriLankan Airlines
              </td>
              <td>
                <a href="https://www.srilankan.com/en_uk/au">https://www.srilankan.com/en_uk/au</a>
              </td>
            </tr>
            <tr>
              <td>
                Swiss
              </td>
              <td>
                <a href="https://www.swiss.com/ch/en/various/breaking-news">https://www.swiss.com/ch/en/various/breaking-news</a>
              </td>
            </tr>
            <tr>
              <td>
                Thai Airways
              </td>
              <td>
                <a href="https://www.thaiairways.com/en/news/news_announcement/news_detail/coronavirus.page">https://www.thaiairways.com/en/news/news_announcement/news_detail/corona...</a>
              </td>
            </tr>
            <tr>
              <td>
                Tiger Air
              </td>
              <td>
                <a href="https://tigerair.com.au/information-coronavirus">https://tigerair.com.au/information-coronavirus</a>
              </td>
            </tr>
            <tr>
              <td>
                Turkish Airlines
              </td>
              <td>
                <a href="https://www.turkishairlines.com/en-int/index.html">https://www.turkishairlines.com/en-int/index.html</a>
              </td>
            </tr>
            <tr>
              <td>
                United Airlines
              </td>
              <td>
                <a href="https://www.united.com/ual/en/us/fly/travel/notices.html">https://www.united.com/ual/en/us/fly/travel/notices.html</a>
              </td>
            </tr>
            <tr>
              <td>
                Virgin Atlantic
              </td>
              <td>
                <a href="https://flywith.virginatlantic.com/gb/en/news/coronavirus.html">https://flywith.virginatlantic.com/gb/en/news/coronavirus.html</a>
              </td>
            </tr>
            <tr>
              <td>
                Virgin Australia
              </td>
              <td>
                <a href="https://www.virginaustralia.com/au/en/bookings/flight-status/travel-alerts/">https://www.virginaustralia.com/au/en/bookings/flight-status/travel-alerts/</a>
              </td>
            </tr>
          </tbody>
          {/* </table> */}
        </Table>

        <p>Once again, we appreciate your patience and understanding. Our team will respond to your request with priority being given to those travelling within the next four weeks. </p>
        <p>Rest assured we are working around the clock to assist all our customers as quickly as possible. We do thank you for your patience and understanding at this time while we do our very best to accommodate all our customers. </p>
        <p>
          Kind regards,
          <br />
          {' '}
          Pay Later Travel Team
        </p>
      </div>
    </div>
    <Footer />
  </div>
);

export default Coronavirus;
