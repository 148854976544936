import {
  Button,
  Grid,
  Paper,
  Popover,
  Radio,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SlideCarouselWrapper from '../../common/SlideCarouselWrapper';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import WifiIcon from '@mui/icons-material/Wifi';
import { _url } from '../../../config/utils';
import { CurrencyText, PrimaryButton } from '../../common/CommonStyle';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { dangerTextColor, successTextColor } from '../../../constants/ColorConstants';
import FullyRefundable from './popup/FullyRefundable';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PriceDetailPopup from './popup/PriceDetailPopup';
import PropertyReserveModal from './PropertyReserveModal';
import Amount from '../../common/Amount';

const PropertyRoomCard = ({ room, handleRoomSelect }) => {
  const [openRefundableDialog, setOpenRefundableDialog] = useState(false);
  const [anchorElPrice, setAnchorElPrice] = useState(null);
  const openPriceDetails = Boolean(anchorElPrice);
  const [extraFees, setExtraFees] = useState(room?.rates_extras?.[0]?.rate_id || '');
  const [roomPrice, setRoomPrice] = useState(null);
  const [openReserveModal, setOpenReserveModal] = useState(false);

  useEffect(() => {
    if (extraFees) {
      setRoomPrice(room.rates.find((item) => item.rateId === extraFees));
    } else {
      setRoomPrice(room.rates[0]);
    }
  }, [extraFees]);

  const renderPriceDetails = () => (
    <Popover
      open={openPriceDetails}
      id={'Price details'}
      anchorEl={anchorElPrice}
      PaperProps={{
        style: {
          padding: '20px',
          width: '400px',
        },
      }}
      onClose={() => setAnchorElPrice(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <PriceDetailPopup
        rates={
          extraFees ? room.rates.filter((item) => item.rateId === extraFees)[0] : room.rates[0]
        }
        handleRoomSelect={() => {
          setOpenReserveModal(true);
        }}
      />
    </Popover>
  );

  return (
    <Paper
      style={{
        borderRadius: '5px',
        height: '100%',
      }}
      elevation={1}
    >
      <SlideCarouselWrapper
        additionalImgStyle={{
          borderRadius: '5px 5px 0px 0px',
          height: 150,
        }}
        images={room.images}
      />
      <Grid p={'20px'}>
        <Typography variant='body1' fontWeight='bold'>
          {room.name}
        </Typography>
        {room.propertyCollect && (
          <Typography mt={'5px'} variant='body1' color={successTextColor}>
            <CheckIcon style={{ fontSize: 18, marginRight: 5 }} /> Reserve now, pay later
          </Typography>
        )}
        {room.area.sqFeet && (
          <Typography display={'flex'} alignItems={'center'} mt={'5px'} variant='body1'>
            <img
              style={{ height: 18, width: 18, marginRight: 5 }}
              src={_url('assets/icons/area.svg')}
              alt={'area'}
            />
            {room.area.sqFeet} sq ft. {room.area.sqMeters} sq mt.
          </Typography>
        )}
        {room.freeWifi && (
          <Typography display={'flex'} alignItems={'center'} mt={'5px'} variant='body1'>
            <WifiIcon style={{ fontSize: 18, marginRight: 5 }} />
            Free wifi
          </Typography>
        )}
        {room.sleepCount && (
          <Typography display={'flex'} alignItems={'center'} mt={'5px'} variant='body1'>
            <PeopleOutlineOutlinedIcon style={{ fontSize: 18, marginRight: 5 }} />
            Sleeps: {room.sleepCount}
          </Typography>
        )}
        <Typography display={'flex'} alignItems={'center'} my={'10px'} variant='body1'>
          <HotelOutlinedIcon style={{ fontSize: 18, marginRight: 5 }} />
          {room.bedDescription}
        </Typography>
      </Grid>
      <Grid p={'10px'} container spacing={'10px'}>
        <Grid item xs={6}>
          <Grid pl={'10px'}>
            <Typography variant='body1' fontWeight={'bold'}>
              {roomPrice?.refundable ? (
                <>
                  <span style={{ color: successTextColor }}>Fully refundable</span>
                  <InfoOutlinedIcon
                    onClick={(e) => setOpenRefundableDialog(true)}
                    style={{ fontSize: 18, marginLeft: 5, cursor: 'pointer' }}
                  />
                </>
              ) : (
                <>
                  <span style={{ color: dangerTextColor }}>Non-Refundable</span>
                  <InfoOutlinedIcon
                    onClick={() => setOpenRefundableDialog(true)}
                    style={{ fontSize: 18, marginLeft: 5, cursor: 'pointer' }}
                  />
                </>
              )}
            </Typography>
          </Grid>
          {roomPrice?.fees && (
            <Grid mt={'10px'} pl={'10px'}>
              <CurrencyText
                style={{ display: 'flex', alignItems: 'baseline', gap: 5, marginBottom: 0 }}
              >
                <Amount
                  price={roomPrice?.fees.total.inclusive || 0}
                  currency={roomPrice?.fees.total.currency}
                />
              </CurrencyText>
            </Grid>
          )}
          {roomPrice?.fees.total.roomPrices.length > 1 && (
            <Grid pl={'10px'}>
              <Typography
                style={{ marginBottom: 10, display: 'flex', alignItems: 'baseline', gap: 5 }}
              >
                for {roomPrice?.fees.total.roomPrices.length} Rooms
              </Typography>
            </Grid>
          )}
        </Grid>
        {room.rates_extras.length > 0 && (
          <Grid item xs={6}>
            <Typography variant={'body1'} fontWeight={700}>
              Customise your stay
            </Typography>
            {room.rates_extras.map((rate) => (
              <Grid
                onClick={() => setExtraFees(rate.rate_id)}
                style={{ cursor: 'pointer' }}
                display={'flex'}
                alignItems={'center'}
              >
                <Radio
                  checked={extraFees === rate.rate_id}
                  size={'small'}
                  value={rate.rate_id}
                  label={rate.name}
                />
                <Typography variant={'body1'}>{rate.name}</Typography>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      <Grid p={'10px'}>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Button
              endIcon={<KeyboardArrowDownOutlinedIcon />}
              style={{ textTransform: 'none' }}
              variant={'text'}
              onClick={(e) => setAnchorElPrice(e.currentTarget)}
            >
              Price details
            </Button>
          </Grid>
          <Grid item>
            <PrimaryButton
              variant={'contained'}
              onClick={() => {
                setOpenReserveModal(true);
              }}
            >
              Reserve
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      {openRefundableDialog && (
        <FullyRefundable
          handleClose={() => setOpenRefundableDialog(false)}
          rates={
            extraFees ? room.rates.filter((item) => item.rateId === extraFees)[0] : room.rates[0]
          }
          isRefundable={room.rates[0].refundable}
        />
      )}
      {openReserveModal && (
        <PropertyReserveModal
          rateDetail={
            extraFees ? room.rates.filter((item) => item.rateId === extraFees) : room.rates
          }
          handleRoomSelect={(roomDetail) =>
            handleRoomSelect({
              ...roomDetail,
              roomId: room.roomId,
            })
          }
          handleClose={() => setOpenReserveModal(false)}
        />
      )}
      {renderPriceDetails()}
    </Paper>
  );
};

export default PropertyRoomCard;
