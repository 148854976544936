import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'
import { brokenImage } from '../../../constants/AppConstants'
import GalleryView from './GalleryView'
import { ImageGridCOntainer, ShowAllImagesButton } from './Style'

const PropertyImageList = ({ propertyImage }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const primaryImageHeight = mobile ? '300px' : '500px'
  const regularImageHeight = mobile ? '150px' : '250px'

  const [openGallery, setOpenGallery] = useState(false)
  if (!mobile) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} height={primaryImageHeight}>
          <ImageGridCOntainer
            onClick={() => setOpenGallery(true)}
            url={propertyImage?.[0]?.imageUrl || brokenImage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={1} marginBottom='8px' height={regularImageHeight}>
            <Grid item xs={6}>
              <ImageGridCOntainer
                onClick={() => setOpenGallery(true)}
                url={propertyImage?.[1]?.imageUrl || brokenImage}
              />
            </Grid>
            <Grid item xs={6}>
              <ImageGridCOntainer
                onClick={() => setOpenGallery(true)}
                url={propertyImage?.[2]?.imageUrl || brokenImage}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} height={regularImageHeight}>
            <Grid item xs={6}>
              <ImageGridCOntainer
                onClick={() => setOpenGallery(true)}
                url={propertyImage?.[3]?.imageUrl || brokenImage}
              />
            </Grid>
            <Grid item xs={6} position='relative'>
              <ImageGridCOntainer url={propertyImage?.[4]?.imageUrl || brokenImage} />
              <ShowAllImagesButton
                onClick={() => setOpenGallery(true)}
                variant='contained'
                size='small'
              >
                Show All Images
              </ShowAllImagesButton>
            </Grid>
          </Grid>
          {openGallery && (
            <GalleryView handleClose={() => setOpenGallery(false)} propertyImage={propertyImage} />
          )}
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} height={primaryImageHeight}>
        <ImageGridCOntainer
          onClick={() => setOpenGallery(true)}
          url={propertyImage?.[0]?.imageUrl || brokenImage}
        />
      </Grid>
      <Grid item xs={12} position='relative'>
        <ImageGridCOntainer url={propertyImage?.[4]?.imageUrl || brokenImage} />
        <ShowAllImagesButton onClick={() => setOpenGallery(true)} variant='contained' size='small'>
          Show All Images
        </ShowAllImagesButton>
      </Grid>
      {openGallery && (
        <GalleryView handleClose={() => setOpenGallery(false)} propertyImage={propertyImage} />
      )}
    </Grid>
  )
}

export default PropertyImageList
