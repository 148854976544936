import React from 'react';
import { IS_USA_SITE } from '../constants';
import AuPrivacyPolicy from './privacypolicy/AuPrivacyPolicy';
import UsaPrivacyPolicy from './privacypolicy/UsaPrivacyPolicy';

const PrivacyPolicy = () => {
    if (IS_USA_SITE) {
        return <UsaPrivacyPolicy />;
    }

    return <AuPrivacyPolicy />;
};

export default PrivacyPolicy;
