import moment from 'moment';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchForm from '../searchform/index';
import { useDispatch } from 'react-redux';
import { updateSearchFlights, updateSearchLoading } from '../../redux/modules/search';

const EditSearch = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.setOpen) props.setOpen(false);
    if (props.editSearchOpenOnDesktop && window.innerWidth > 768) setIsOpen(true);
  }, []);

  const editSearch = (query, auth) => {
    if (props.productType === 'hotels') {
      dispatch(updateSearchFlights(query));
    } else {
      dispatch(updateSearchLoading());
      // supply included carrier codes for change flights edit search
      if (props.changeFlights) {
        const allCarriers = props.booking.lines[0].product.itineraries.map(
          (itinerary) => itinerary.segments.map((segment) => segment.carrierCode)[0],
        );
        const carriers = [...new Set(allCarriers)];
        query.includedAirlineCodes = carriers.toString();
        dispatch(updateSearchFlights(query));
      } else {
        dispatch(updateSearchFlights(query, null, auth));
      }
    }
    if (props.onSearch) props.onSearch();

    if (props.setOpen) {
      props.setOpen(false);
    }
    props.setOpen(false);
    setIsOpen(false);
    if (props.editSearchOpenOnDesktop && window.innerWidth > 768) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const searchQuery = props.searchQuery || {};
  const open = props.open || isOpen;

  if (props.productType === 'hotels') {
    const checkInDate = moment(searchQuery.checkInDate).format('D MMM');
    const checkOutDate = moment(searchQuery.checkOutDate).format('D MMM');

    return (
      <div
        className={classNames('EditSearch', {
          expanded: open,
        })}
      >
        {!open && (
          <div
            className='EditSearch__currentSearchDetails'
            onClick={() => {
              if (this.props.setOpen) {
                this.props.setOpen(true);
              } else {
                this.setState({ open: true });
              }
            }}
          >
            <div className='EditSearch__info'>
              {searchQuery.destination && searchQuery.destination.includes('_') && (
                <span>
                  {searchQuery.destination.split('_')[0]},{' '}
                  {searchQuery.destination.split('_')[1].replace('/', '')}
                </span>
              )}
            </div>
            <div className='EditSearch__info EditSearch__infoDatesAndTravellers'>
              <div className='EditSearch__infoDates'>
                <span>{`${checkInDate} - ${checkOutDate}`}</span>
              </div>
              {searchQuery.childAges && (
                <div className='EditSearch__infoTravellers'>
                  <span>
                    {searchQuery.adults + searchQuery.childAges.length}{' '}
                    {`${
                      searchQuery.adults + searchQuery.childAges.length > 1 ? 'guests' : 'guest'
                    }`}
                    , {searchQuery.rooms} room
                    {searchQuery.rooms > 1 ? 's' : ''}
                  </span>
                </div>
              )}
            </div>
            <div className='EditSearch__icon'>
              <FontAwesomeIcon icon='search' />
            </div>
          </div>
        )}
        {open && <SearchForm editSearch={editSearch} />}
        {!props.open && open && (
          <p className='close' onClick={() => setIsOpen(false)}>
            Close
          </p>
        )}
      </div>
    );
  }

  const { type, origin, multiCityLegs } = props.searchQuery;

  if (type === 'multi-city') {
    if (!multiCityLegs) return false;
  } else if (!origin) return false;

  const originIata = type === 'multi-city' ? multiCityLegs[0].origin.id : searchQuery.origin.id;

  const destinationIata =
    type === 'multi-city'
      ? multiCityLegs[multiCityLegs.length - 1].destination.id
      : searchQuery.destination.id;

  const departureDate = moment(
    type === 'multi-city' ? multiCityLegs[0].departureDate : searchQuery.departureDate,
  ).format('D MMM');
  const returnDate = moment(
    type === 'multi-city'
      ? multiCityLegs[multiCityLegs.length - 1].departureDate
      : searchQuery.returnDate,
  ).format('D MMM');
  
  const shortLocations = `${originIata} - ${destinationIata}`;

  return (
    <div
      className={classNames('EditSearch', {
        expanded: open,
      })}
    >
      {!open && (
        <div
          className='EditSearch__currentSearchDetails'
          onClick={() => {
            if (props.setOpen) {
              props.setOpen(true);
            } else {
              setIsOpen();
            }
          }}
        >
          <div className='EditSearch__info'>
            <span className='EditSearch__locations'>{shortLocations} </span>
            <span className='search-info__airport-codes__multi-city-flights'>
              {type === 'multi-city' && `(${multiCityLegs.length} flights)`}
            </span>
          </div>
          <div className='EditSearch__info EditSearch__infoDatesAndTravellers'>
            <div className='EditSearch__infoDates'>
              <span>
                {departureDate} {type !== 'one-way' && ` - ${returnDate}`}
              </span>
            </div>
            <div className='EditSearch__infoTravellers'>
              <span>
                {searchQuery.adults + searchQuery.children + searchQuery.infants}{' '}
                {`${
                  searchQuery.adults + searchQuery.children + searchQuery.infants > 1
                    ? 'Guests'
                    : 'Guest'
                }`}
              </span>
            </div>
          </div>
          <div className='EditSearch__icon'>
            <FontAwesomeIcon icon='search' />
          </div>
        </div>
      )}
      {open && <SearchForm editSearch={editSearch} />}
      {!props.open && open && (
        <p className='close' onClick={() => props.setOpen(false)}>
          Close
        </p>
      )}
    </div>
  );
};

export default EditSearch;
