import { Typography } from '@mui/material';
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const ReviewTermsAndConditionPrompt = ({ handleClose }) => (
    <Modal isOpen toggle={handleClose} centered className="text-center">
        <ModalHeader toggle={handleClose}>Confirm</ModalHeader>
        <ModalBody>
            <Typography variant={'body1'}>
                Please review the terms and conditions and click on the checkbox to confirm.
            </Typography>
        </ModalBody>
    </Modal>
);

export default ReviewTermsAndConditionPrompt;
