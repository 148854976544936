import { reduce } from 'lodash';
import InternalApi from '../../../config/internalApi';
import { getStaysDetailApiUrl } from '../../../constants/APIConstants';

export const getArrayOfItems = (count, name) => {
    const items = [];
    for (let i = 0; i < count; i++) {
        items.push(name);
    }
    return items;
};

const transformHotelSearch = (data) => {
    if (data.length === 0) {
        return [];
    }
    const result = data.map((hotelDetail) => ({
        chepestRoom: {
            total: Number(hotelDetail.starts_from.total_price_for_all_rooms),
            totalRooms: hotelDetail.starts_from.rooms_prices.length
        },
        hotelId: hotelDetail.property_id,
        keyPolicies: hotelDetail.key_policies,
        region: hotelDetail.region,
        paymentPlanAvailable: false,
        fullAddress: {
            city: hotelDetail.address.city,
            country: hotelDetail.address.country_code,
            state: hotelDetail.address.state_province_name,
            street_address: hotelDetail.address.line_1,
            zip_code: hotelDetail.address.postal_code
        },
        ratings: hotelDetail.ratings,
        hotelName: hotelDetail.name,
        score: hotelDetail.score,
        images: hotelDetail.images.map((image) => ({
            id: image.links?.['1000px']?.href || image.links?.['350px'].href,
            imageType: image.type,
            url: image.links?.['1000px']?.href || image.links?.['350px'].href
        }))
    }));
    return result.filter((item) => item.images.length > 0);
};

export const getChildrenAges = (childrenAges) => {
    if (childrenAges) {
        return childrenAges.split(',').map((item) => ({
            age: parseInt(item, 10)
        }));
    }
    return [];
};

export const getTotalAdults = (roomInfo) =>
    reduce(
        roomInfo,
        function (sum, n) {
            return sum + n.adult;
        },
        0
    );

export const getChildren = (roomInfo) => {
    const children = [];
    roomInfo.forEach((item) => {
        if (item.children.length > 0) {
            children.push(...item.children.map((child) => ({ age: child.age })));
        }
    });

    return children;
};

export const transformOccupanies = (values) =>
    values.map((item) => ({
        adults: item.adult,
        children: item.children.map((child) => ({
            age: child.age
        }))
    }));
    
export const getStaysList = (values, successCallBack, errorCallBack) => {
    InternalApi.post(getStaysDetailApiUrl(), {
        region: values.locationCode,
        checkin: values.checkInDate,
        checkout: values.checkOutDate,
        occupanies: transformOccupanies(JSON.parse(values.roomInfo)),
        is_from_portal: values.isFromPortal
    })
        .then((res) => {
            successCallBack(sortHotelList(transformHotelSearch(res), 'RECOMMENDED'));
        })
        .catch(() => errorCallBack());
};

export const sortHotelList = (hotelList, value) => {
    if (value === 'RECOMMENDED') {
        return hotelList.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
    }
    if (value === 'PRICE_LOW_TO_HIGH') {
        return hotelList.sort((a, b) => parseFloat(a.chepestRoom.total) - parseFloat(b.chepestRoom.total));
    }
    if (value === 'PRICE_HIGH_TO_LOW') {
        return hotelList.sort((a, b) => parseFloat(b.chepestRoom.total) - parseFloat(a.chepestRoom.total));
    }
    if (value === 'RATING_HIGH_TO_LOW') {
        return hotelList.sort((a, b) => b.rating - a.rating);
    }
    return hotelList.sort((a, b) => a.rating - b.rating);
};

export const getFilteredHotelList = (hotelList, filter, sortBy) => {
    const searchFilter = hotelList.filter(
        ({ hotelName }) => hotelName === '' || hotelName.toLowerCase().includes(filter.searchText.toLowerCase())
    );

    const priceFilter = searchFilter.filter(
        ({ chepestRoom }) =>
            parseFloat(chepestRoom.total) >= parseFloat(filter.priceFilter[0]) &&
            parseFloat(chepestRoom.total) <= parseFloat(filter.priceFilter[1])
    );
    return sortHotelList(priceFilter, sortBy);
};

export const getRatingText = (rating) => {
    const ratingTextMap = [
        {
            min: 0,
            max: 3.4,
            text: ''
        },
        {
            min: 3.5,
            max: 3.99,
            text: 'Good'
        },
        {
            min: 4,
            max: 4.29,
            text: 'Very Good'
        },
        {
            min: 4.3,
            max: 4.49,
            text: 'Excellent'
        },
        {
            min: 4.5,
            max: 4.69,
            text: 'Wonderful'
        },
        {
            min: 4.7,
            max: 5,
            text: 'Exceptional'
        }
    ];

    if (!rating) {
        return '';
    }

    return ratingTextMap.find((item) => rating >= item.min && rating <= item.max).text;

    //     Exceptional >=4.7+
    // Wonderful >=4.5+
    // Excellent >=4.3+
    // Very good >=4+
    // Good >=3.5+
};
