import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { Box } from '@mui/material';
import { StyledDatePickerTextField } from './Style';
import moment from 'moment';

const BasicDateRangePicker = ({
  startDate,
  endDate,
  startDateLabel,
  endDateLabel,
  handleDateChange,
  minimumDate,
  maximumDate,
  textFieldType
}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: startDateLabel, end: endDateLabel }}
    >
      <DateRangePicker
        value={[startDate, endDate]}
        onChange={(newValue) => {
          handleDateChange({
            startDate: new Date(newValue[0]),
            endDate: newValue[1] ? new Date(newValue[1]) : null,
          });
        }}
        inputFormat={'DD MMM'}
        maxDate={new Date(maximumDate) || new Date(moment().add('12', 'months'))}
        minDate={minimumDate ? new Date(minimumDate) : new Date()}
        renderInput={(startProps, endProps) => (
          <Box display={'flex'} gap={'10px'}>
            <StyledDatePickerTextField
              placeholder={'Add Date'}
              textfieldtype={textFieldType}
              style={{ background: 'white', borderRadius: 10, width: '100%' }}
              {...startProps}
              inputProps={{
                ...startProps.inputProps,
                placeholder: 'Add Date',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <StyledDatePickerTextField
              style={{ marginLeft: 5, borderRadius: 10, background: 'white', width: '100%' }}
              {...endProps}
              textfieldtype={textFieldType}
              inputProps={{
                ...endProps.inputProps,
                placeholder: 'Add Date',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  );
};

export default BasicDateRangePicker;
