import { Box, ClickAwayListener, TextField } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import React, { useState } from 'react'
import { StyledDatePickerTextField } from './Style'

const MuiSingleDatePicker = ({
  date,
  isError,
  label,
  minimumDate,
  handleDateChange,
  type,
  onFieldBlur,
  maximumDate,
  errorMessage,
  dateFormat,
  ...rest
}) => {
  const [openDatepicker, setOpenDatepicker] = useState(false)

  const handleChange = (newValue) => {
    handleDateChange(new Date(newValue))
    setOpenDatepicker(false)
  }

  if (type === 'secondary') {
    return (
      <ClickAwayListener
        onClickAway={() => {
          setOpenDatepicker(false)
          onFieldBlur && onFieldBlur()
        }}
      >
        <Box>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={label}
              inputFormat={dateFormat || 'DD MMM'}
              value={date}
              open={openDatepicker}
              onChange={handleChange}
              views={['year', 'month', 'day']}
              maxDate={maximumDate}
              minDate={minimumDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ background: 'white', borderRadius: 10, width: '100%' }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Add Date',
                  }}
                  onKeyDown={(e) => rest.isDisabled && e.preventDefault()}
                  error={!!isError}
                  helperText={!!isError && errorMessage}
                  onClick={() => setOpenDatepicker((prev) => !prev)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </ClickAwayListener>
    )
  }
  return (
    <ClickAwayListener onClickAway={() => setOpenDatepicker(false)}>
      <Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            label={label}
            inputFormat='DD MMM'
            value={date}
            open={openDatepicker}
            InputAdornmentProps={{ style: { display: 'none' } }}
            onChange={handleChange}
            components={{
              OpenPickerIcon: null,
            }}
            minDate={minimumDate}
            renderInput={(params) => (
              <StyledDatePickerTextField
                {...params}
                style={{ background: 'white', borderRadius: 10, width: '100%' }}
                inputProps={{
                  ...params.inputProps,
                  placeholder: 'dd/mm/yyyy',
                }}
                onClick={() => setOpenDatepicker((prev) => !prev)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  )
}

export default MuiSingleDatePicker
