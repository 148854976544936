import { CloseOutlined } from '@mui/icons-material';
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { BannerContainer, Tag } from './Style';

const Banner = ({ hideBanner, showBanner }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down(900));

  return (
    <Grid
      container
      alignItems={'center'}
      position={'fixed'}
      height={'57px'}
      justifyContent={'center'}
      zIndex={1000}
      width={mobileView ? '100%' : 'fit-content'}
      opacity={showBanner ? 0 : 1}
      style={{
        visibility: showBanner ? 'visible' : 'hidden',
      }}
    >
      <Grid
        style={{ display: 'flex' }}
        width={mobileView ? '100%' : undefined}
        justifyContent={'center'}
        marginTop={mobileView ? '57px' : 0}
        bgcolor={mobileView ? 'white' : 'inherit'}
      >
        <BannerContainer
          width={'100%'}
          justifyContent={'center'}
          color={'white'}
          display={'flex'}
          alignItems={'center'}
          gap={'10px'}
        >
          <Tag style={{ fontSize: mobileView ? 12 : 16 }}>NEW PRODUCT</Tag>
          <NavLink to='/stays' className='nav-link'>
            It's here: <u>stays worldwide</u>
          </NavLink>
          <IconButton onClick={hideBanner} style={{ marginRight: 5 }}>
            <CloseOutlined style={{ color: 'black' }} />
          </IconButton>
        </BannerContainer>
      </Grid>
    </Grid>
  );
};

export default Banner;
