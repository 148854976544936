import { combineReducers } from 'redux';
import auth from './modules/auth';
import search from './modules/search';
import detailHotel from './modules/detailHotel';
import paymentPlan from './modules/paymentPlan';
import referrals from './modules/referrals';
import loading from './modules/loading';
import passengers from './modules/passengers';
import detailFlight from './modules/detailFlight';
import bookings from './modules/bookings';
import cards from './modules/cards';

export default combineReducers({
  search,
  loading,
  passengers,
  detailFlight,
  referrals,
  detailHotel,
  paymentPlan,
  auth,
  bookings,
  cards
});
