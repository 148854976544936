import Radio from 'antd/es/radio'
import { Col } from 'reactstrap'
import { _formatMoney } from '../../../config/utils'
import { IS_USA_SITE } from '../../../constants'

export const PaymentPlanOptions = ({ reviewData, paymentPlan, setReviewData }) => (
  <Col sm={12}>
    <div className='payment-plan'>
      <div
        onClick={() => {
          setReviewData({ ...reviewData, plan: 1 })
        }}
        className={`method-payment ${reviewData.plan === 1 ? 'selected-plan' : ''}`}
      >
        <Radio
          checked={reviewData.plan === 1}
          onClick={() => {
            setReviewData({ ...reviewData, plan: 1 })
          }}
        />
        <span className='bold'>${_formatMoney(paymentPlan.pricePerWeek / 100)}</span>
        {IS_USA_SITE ? (
          <span>
            <span> weekly </span>
            <span className='bold'>x{paymentPlan.weeks}</span>
          </span>
        ) : (
          <span>
            <span> /wk for </span>
            <span className='bold'>{paymentPlan.weeks} week(s)</span>
          </span>
        )}
      </div>
      <div
        onClick={() => {
          setReviewData({ ...reviewData, plan: 2 })
        }}
        className={`method-payment ${reviewData.plan === 2 ? 'selected-plan' : ''}`}
      >
        <Radio checked={reviewData.plan === 2} onClick={() => this.onChangePaymentPlan(2)} />
        <span className='bold'>${_formatMoney(paymentPlan.pricePerFortnight / 100)}</span>
        {IS_USA_SITE ? (
          <span>
            <span> bi-weekly </span>
            <span className='bold'>x{paymentPlan.fortnights}</span>
          </span>
        ) : (
          <span>
            <span> /fn for </span>
            <span className='bold'>{paymentPlan.fortnights} fortnight(s)</span>
          </span>
        )}
      </div>
    </div>
  </Col>
)
