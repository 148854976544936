import internalApi from '../../config/internalApi'
import { createAction } from '../utils'
import { updateLoading } from './loading'

export const ADD_CARDS = 'plt-web/cards/ADD_CARDS'
export const REMOVE_CARDS = 'plt-web/cards/REMOVE_CARDS'

const initialState = []

export default function reducer(state = initialState, { type, ...action }) {
  switch (type) {
    case ADD_CARDS: {
      return action.payload
    }
    case REMOVE_CARDS: {
      return initialState
    }
    default:
      return state
  }
}

export const addCards = (cards) => (dispatch) => {
  dispatch(createAction(ADD_CARDS, cards))
}

export const removeCards = () => (dispatch) => {
  dispatch(createAction(REMOVE_CARDS))
}

export const loadCards = (bookingReference) => (dispatch, getState) => {
  const { auth } = getState()
  updateLoading(true)
  return internalApi
    .get(
      'v2/customer/me/cards',
      { bookingReference },
      {
        'x-access-token': auth.token,
      },
    )
    .then((res) => {
      updateLoading(false)
      return dispatch(createAction(ADD_CARDS, res.data))
    })
    .catch((err) => {
      updateLoading(false)
    })
}
