import classNames from 'classnames';
import React from 'react';
import { FlightDetails } from '../../../components';
import { Button, Col, Row } from 'reactstrap';
import ShowChangedFlightPrice from '../../../components/search/form/ShowChangedFlightPrice';
import { _url } from '../../../config/utils';
import { renderFlightSegments } from '../../../components/search/results/SearchResultHooks';
import { ChangesAndCancellation } from '../components/ChangesAndCancellation';
import { CurrentFlightPrice } from '../components/CurrentFlightPrice';
import { MobileChangesAndCancellation } from '../components/MobileChangesAndCancellation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {faAngleDown} from '@fortawesome/fontawesome-free-solid';

export const FlightSearchResultBox = ({ searchResult, noSelect, currentFlightPrice, type, onSelect }) => {
    const [detailOpen, setDetailOpen] = React.useState(false);
    const toggleDetailOpen = () => setDetailOpen(!detailOpen);

    return (
        <div
            key={`result-box-${searchResult.guid}-${Math.random()}`}
            className={classNames('search-result-box', { 'no-select': noSelect })}
        >
            <div className={classNames('search-result', { 'detail-open': detailOpen })}>
                <Row>
                    <Col className="left-content">
                        <Row>
                            {renderFlightSegments(searchResult)}
                            <Col xs={12} className="search-result-box__controls">
                                <div className="flight-details-toggle">
                                    <div className="content-icon" onClick={toggleDetailOpen}>
                                        Flight and baggage info
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </div>
                                </div>
                                {!noSelect && <ChangesAndCancellation searchResult={searchResult} />}
                            </Col>
                        </Row>
                    </Col>
                    {!noSelect && (
                        <Col md="auto" className="right-content">
                            {currentFlightPrice ? (
                                <ShowChangedFlightPrice
                                    searchResult={searchResult}
                                    currentFlightPrice={currentFlightPrice}
                                />
                            ) : (
                                <CurrentFlightPrice searchResult={searchResult} />
                            )}
                            <div>
                                <Button onClick={() => onSelect(searchResult.id)} className="btn-select">
                                    Select
                                    <img alt="left-arrow" src={_url('assets/images/left-arrow.png')} />
                                </Button>

                                <MobileChangesAndCancellation searchResult={searchResult} />
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
            {detailOpen && <FlightDetails itinerary={searchResult} flights={searchResult.itineraries} type={type} />}
        </div>
    );
};
