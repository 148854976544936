import React from 'react';
import { Col } from 'reactstrap';
import { IS_USA_SITE } from '../../../constants';

export const PaymentPlanChooseText = () => (
    <Col sm={12}>
        <h4 className="payment-list-head">2. Choose your payment plan</h4>
        <p className="payment-list-sub-title">
            Select from weekly or {IS_USA_SITE ? 'bi-weekly' : 'fortnightly'} payments.
        </p>
    </Col>
);
