import React, { useEffect } from "react";
import { Footer } from "../../components";
import HomeFeature from "../../components/homepage/HomeFeature";
import HomeFlight from "../../components/homepage/HomeFlight";
import HomepageHeader from "../../components/homepage/HomepageHeader";
import HomePopularDestination from "../../components/homepage/HomePopularDestination";
import HomeStays from "../../components/homepage/HomeStays";
import HomeTestimonial from "../../components/homepage/HomeTestimonial";
import { IS_USA_SITE } from "../../constants";
import { emptySearchResults } from "../../redux/modules/search";
import { useHome } from "./Hooks";
import "react-multi-carousel/lib/styles.css";

const Home = () => {
    const { selectedLocation, selectedTab, setSelectedLocation, setSelectedTab, dispatch, location } = useHome();

    useEffect(() => {
        location.pathname.includes("/flights") && setSelectedTab(1);
        location.pathname.includes("/stays") && setSelectedTab(2);
        dispatch(emptySearchResults());
    }, [dispatch, location.pathname, setSelectedTab]);

    return (
        <div className="Home main-container" id="home">
            <HomepageHeader
                selectedLocation={selectedLocation}
                selectedTab={selectedTab}
                setSelectedLocation={setSelectedLocation}
                setSelectedTab={setSelectedTab}
            />
            <div className="Home__features IconBoxGroup">
                <HomeFeature />
            </div>
            <hr />
            <HomeTestimonial showUSASite />
            <div className="HomeFlights Home__section">
                <HomeFlight setSelectedTab={setSelectedTab} />
            </div>
            {!IS_USA_SITE && (
                <div className="HomeStays Home__section">
                    <HomeStays setSelectedTab={setSelectedTab} />
                </div>
            )}
            <HomeTestimonial setSelectedTab={setSelectedTab} />
            <hr />
            {!IS_USA_SITE && <HomePopularDestination setSelectedTab={setSelectedTab} />}
            <Footer />
        </div>
    );
};

export default Home;
