import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import message from 'antd/es/message'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IS_USA_SITE } from '../constants'
import BillingAddressForm from './BillingAddressForm'
import Button from './Button'
import CardDropdown from './CardDropdown'
import CardDetail from './common/carddetail/CardDetail'

const StripeForm = (props) => {
  const cards = useSelector((state) => state.cards)
  const passengers = useSelector((state) => state.passengers)
  const auth = useSelector((state) => state.auth)
  const [cardId, setCardId] = useState()
  const [loading, setLoading] = useState(false)
  const [addCardOpen, setAddCardOpen] = useState(cards.length === 0 || props.showCardForm)
  const [addressDetails, setAddressDetails] = useState()
  const [cardHolderName, setCardHolderName] = useState(null)
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  })

  useEffect(() => {
    const defaultCardId = cards.length > 0 ? cards[0].id : null
    setCardId(defaultCardId)
    setAddCardOpen(cards.length === 0 || props.showCardForm)
  }, [cards])

  const stripe = useStripe()
  const elements = useElements()

  const { addressLine1, addressCity, addressPostcode, addressState, addressCountry } =
    addressDetails || {}

  const handleSubmit = async () => {
    if (props.variant === 'secondary') {
      return props.handleSubmit({
        billing_details: {
          address: {
            line1: addressLine1,
            city: addressCity,
            country: addressCountry,
            state: addressState,
            postal_code: addressPostcode,
          },
          name: cardHolderName,
          email: auth.user ? auth.user.email : passengers.email,
          phone: auth.user ? auth.user.phone : passengers.phone_number,
        },
        cardDetails: {
          cardNumber: cardDetails.cardNumber,
          expDate: cardDetails.expiryDate,
          cvv: cardDetails.cvv,
        },
      })
    }
    if (props.noPaymentRequired) return props.handleSubmit()
    // if (props.noLoading) dispatch(updateLoading(true));
    setLoading(true)
    if (!addCardOpen) {
      props.handleSubmit(cardId)
    } else {
      if (elements == null) return

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            line1: addressLine1,
            city: addressCity,
            country: addressCountry,
            state: addressState,
            postal_code: addressPostcode,
          },
          name: cardHolderName,
          email: auth.user ? auth.user.email : passengers.email,
          phone: auth.user ? auth.user.phone : passengers.phone_number,
        },
      })
      console.log(error)
      if (error) {
        message.error(error.message || 'Could not complete payment.')
      } else {
        props.handleSubmit(paymentMethod)
      }
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit} className='StripeForm'>
      {!props.noPaymentRequired && (
        <div>
          {!props.showCardForm && (
            <CardDropdown
              cards={cards}
              selectedCardId={cardId}
              onSelectCard={(cardId) => {
                setCardId(cardId)
                setAddCardOpen(false)
              }}
              onSelectAddNewCard={() => {
                setCardId(null)
                setAddCardOpen(true)
              }}
            />
          )}
          {addCardOpen && (
            <div className='AddCard'>
              <input
                style={{ width: '100%' }}
                type='text'
                name='cardHolderNumber'
                placeholder='Name on card'
                onChange={(e) => setCardHolderName(e.target.value)}
              />
              {props.variant === 'secondary' ? (
                <CardDetail cardDetails={cardDetails} setCardDetails={setCardDetails} />
              ) : (
                <CardElement
                  id='card-element'
                  className='StripeCardElement'
                  options={{
                    hidePostalCode: true,
                    style: {
                      base: {
                        border: '1px solid rgba(0, 0, 0, 0.2)',
                        color: 'black',
                        fontFamily: 'Inter, --apple-system, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                          color: '#747474',
                        },
                      },
                      invalid: {
                        color: '#fa755a',
                        iconColor: '#fa755a',
                      },
                    },
                  }}
                />
              )}
              <div className='BillingAddressForm'>
                <BillingAddressForm onChange={(result) => setAddressDetails({ ...result })} />
              </div>
            </div>
          )}
        </div>
      )}
      {props.disclaimerAboveButton}
      <Button
        className='StripeForm__bookNow'
        block
        loading={!props.noloading && (props.buttonLoading || loading)}
        icon={props.buttonIcon}
        color={props.buttonColor || 'primary'}
        disabled={
          (!props.noloading && (props.buttonLoading || loading)) ||
          props.disabled ||
          (!props.noPaymentRequired &&
            addCardOpen &&
            (!cardHolderName ||
              !addressLine1 ||
              !addressCity ||
              !addressState ||
              !addressPostcode ||
              !addressCountry ||
              (props.variant === 'secondary' &&
                (cardDetails.cardNumber === '' ||
                  cardDetails.cvv === '' ||
                  cardDetails.expiryDate === ''))))
        }
        onClick={handleSubmit}
      >
        {props.buttonText || 'Submit'}
      </Button>
    </form>
  )
}

const stripePromiseAU = loadStripe(process.env.REACT_APP_STRIPE_PK_AU)
const stripePromiseUS = loadStripe(process.env.REACT_APP_STRIPE_PK_US)

const StripeFormWrapper = (props) => {
  // set default stripe promise
  let stripePromise = IS_USA_SITE ? stripePromiseUS : stripePromiseAU

  if (props.booking) {
    stripePromise =
      props.booking.paymentProvider.paymentProviderAccountId === 'acct_1Hqu59JdUHdbtU6D'
        ? stripePromiseUS
        : stripePromiseAU
  }
  // override if coming from booking detail page
  return (
    <Elements stripe={stripePromise}>
      <StripeForm {...props} />
    </Elements>
  )
}

export default StripeFormWrapper
