import { Grid, Typography } from '@mui/material';
import React from 'react';
import CardContainerView from '../../common/CardContainer';
import { StyledList } from './Style';

const PropertyImportantInfoSection = ({ hotel }) => (
  <CardContainerView>
    <div>
      <div style={{ marginTop: 30 }} className='card-details-wrapper payment'>
        <div className='card-detail'>
          <div className='content'>
            <Grid container>
              <Grid mb={['20px', undefined]} item md={3} xs={12}>
                <Typography variant={'h5'} fontWeight={'bold'}>
                  Important information
                </Typography>
              </Grid>
              <Grid item md={9} xs={12}>
                <h2 style={{ fontSize: 15 }}>Fees</h2>
                {hotel.fees.map((fee) => (
                  <Grid key={`fee-${Math.random().toString()}`}>
                    <h2 style={{ fontSize: 15 }}>{fee.label}</h2>
                    <StyledList dangerouslySetInnerHTML={{ __html: fee.value }} />
                  </Grid>
                ))}
                <Grid>
                  <h2 style={{ fontSize: 15 }}>Policies</h2>
                  <StyledList dangerouslySetInnerHTML={{ __html: hotel.policies }} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  </CardContainerView>
);

export default PropertyImportantInfoSection;
