import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { _url } from '../../config/utils';
import { IS_USA_SITE } from '../../constants';
import { scrollToHome } from '../../containers/homepage/Util';

const HomeFlight = ({ setSelectedTab }) => (
    <Container>
        <Row className="align-items-center">
            <Col md={{ size: 5, offset: 0 }}>
                {!IS_USA_SITE ? <h2>Pay Later Flights</h2> : <h2>Secure flight prices, before they go up</h2>}
                <p>
                    Lock in flights with a {IS_USA_SITE ? '10%-15%' : 'small'} deposit and protect yourself from price
                    increases. Spread the remaining amount over weekly or {IS_USA_SITE ? 'bi-weekly' : 'fortnightly'}{' '}
                    payments before you take-off.
                </p>
                <div className="IconList__box">
                    <div className="IconList__icon">
                        <img alt={'check'} src={_url('assets/icons/check.png')} />
                    </div>
                    <p>Available for all airlines and destinations.</p>
                </div>
                <div className="IconList__box">
                    <div className="IconList__icon">
                        <img alt={'check'} src={_url('assets/icons/check.png')} />
                    </div>
                    <p>Pay in up to 26 weekly payments.</p>
                </div>
                <div className="IconList__box">
                    <div className="IconList__icon">
                        <img alt={'check'} src={_url('assets/icons/check.png')} />
                    </div>
                    <p>Interest free and no credit checks.</p>
                </div>
                <div className="Home__section__button">
                    <Button
                        color="primary"
                        onClick={() => {
                            setSelectedTab(1);
                            scrollToHome();
                        }}
                    >
                        Search Flights
                    </Button>
                    <Link className="Home__section__learnMore" to="/how-it-works#flights">
                        Learn more
                    </Link>
                </div>
            </Col>
            <Col md={{ size: 6, offset: 0 }}>
                <img
                    alt={'mock up'}
                    className="HomeFlights__mockup"
                    style={{ maxWidth: '160%' }}
                    src={_url(
                        IS_USA_SITE ? 'assets/images/paylaterflightswebusa.png' : 'assets/images/paylaterflightsweb.png'
                    )}
                />
            </Col>
        </Row>
    </Container>
);

export default HomeFlight;