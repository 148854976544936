import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Alert, Col, Row } from "reactstrap";
import { _formatMoney } from "../../../config/utils";
import { usePaymentAmountDisplay } from "./PaymentAmountHooks";

export const PaymentAmountDisplay = ({ reviewData, paymentPlan }) => {
    const { startDate, frequency, moneyDisplay } = usePaymentAmountDisplay(reviewData, paymentPlan);
    const balance = useSelector((state) => state.referrals.balance);
    return (
        <>
            <Row>
                <Col>
                    <span className="title">
                        <span className="title">Deposit (due now)</span>
                    </span>
                </Col>
                <Col xs="auto" className="price">
                    <span />
                    <span>${_formatMoney(paymentPlan && paymentPlan.deposit ? paymentPlan.deposit / 100 : 0)}</span>
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className="title">{frequency} payments</span>
                    <p className="starting">Starting {startDate}</p>
                </Col>
                <Col xs="auto" className="price repayments">
                    <span>{reviewData.plan === 1 ? paymentPlan.weeks : paymentPlan.fortnights} X </span>
                    <span>$ {moneyDisplay}</span>
                </Col>
            </Row>
            {paymentPlan.discount && paymentPlan.discount.amount && (
                <Row className="discount">
                    <Col>
                        <span className="title">
                            {paymentPlan.discount.couponCode === ""
                                ? "Credit applied"
                                : `Discount Applied: '${paymentPlan.discount.couponCode}'`}
                            {reviewData.daysTillDeparture >= 42 && (
                                // TODO - check this
                                <FontAwesomeIcon
                                    icon="times"
                                    // onClick={() =>
                                    //     this.setState(
                                    //         {
                                    //             showCoupon: false,
                                    //             couponCode: "",
                                    //             credit: null
                                    //         },
                                    //         () => this.getPaymentPlan()
                                    //     )
                                    // }
                                />
                            )}
                        </span>
                    </Col>
                    <Col xs="auto" className="price">
                        <span>
                            - ${paymentPlan.discount && parseFloat(paymentPlan.discount.amount / 100).toFixed(2)}
                        </span>
                    </Col>
                </Row>
            )}
            <Row className="total-row">
                <Col>
                    <span className="title">{paymentPlan.discount ? "New Total" : "Total"}</span>
                </Col>
                <Col xs="auto" className="price">
                    {paymentPlan.discount && paymentPlan.discount.amount && (
                        <span className="price-before-discount">
                            ${parseFloat((paymentPlan.total + paymentPlan.discount.amount) / 100).toFixed(2)}
                        </span>
                    )}
                    <span>${paymentPlan && parseFloat(paymentPlan.total / 100).toFixed(2)}</span>
                </Col>
            </Row>
            {paymentPlan.discount && paymentPlan.discount.amount && (
                <div className="title">
                    <Alert color="info">
                        ${parseFloat((balance - paymentPlan.discount.amount) / 100).toFixed(2)} credit remaining after
                        this booking.
                    </Alert>
                </div>
            )}
        </>
    );
};
