import { createAction } from '../utils';
import internalApi from '../../config/internalApi';

export const ADD_BOOKING = 'plt-web/bookings/ADD_BOOKING';
export const ADD_RECENTLY_COMPLETED_BOOKING = 'plt-web/bookings/ADD_RECENTLY_COMPLETED_BOOKING';
export const EMPTY_BOOKING = 'plt-web/bookings/EMPTY_BOOKING';
export const GET_BOOKINGS = 'plt-web/bookings/GET_BOOKINGS';
export const DETAIL_BOOKING = 'plt-web/bookings/DETAIL_BOOKING';
export const LOADING_BOOKING = 'plt-web/bookings/LOADING_BOOKING';
export const GET_SUBSCRIPTIONS = 'plt-web/bookings/GET_SUBSCRIPTIONS';

const initialState = {
  data: [],
  recentlyCompletedBooking: null,
  loading: false,
  total: 0,
  detail: {},
  subscriptions: []
};

export default function reducer(state = initialState, { type, ...action }) {
  switch (type) {
    case LOADING_BOOKING: {
      return {
        ...state,
        data: [],
        detail: {},
        loading: true
      };
    }
    case ADD_RECENTLY_COMPLETED_BOOKING: {
      return {
        ...state,
        recentlyCompletedBooking: action.data
      };
    }
    case EMPTY_BOOKING: {
      return {
        ...state,
        recentlyCompletedBooking: null
      };
    }
    case GET_BOOKINGS: {
      return {
        ...state,
        data: action.payload,
        detail: {},
        loading: false
      };
    }
    case DETAIL_BOOKING: {
      return {
        ...state,
        detail: action.payload,
        loading: false
      };
    }
    case GET_SUBSCRIPTIONS: {
      return { ...state, subscriptions: action.payload, loading: false };
    }
    default: return state;
  }
}

export const getBookings = () => (dispatch, getState) => {
  const { auth } = getState();
  dispatch(createAction(LOADING_BOOKING));
  // internalApi.get('v2/bookings', {}, {'Authorization': `Bearer ${auth.token}`}).then((response) => {
  // internalApi.get('v2/bookings', {access_token: auth.token}).then((response) => {
  internalApi.get('v2/customer/me/booking', {}, { 'x-access-token': auth.token }).then((response) => {
    // console.log(response);
    dispatch(createAction(GET_BOOKINGS, response));
  }).catch((err) => {
    console.log(err);
    // dispatch(createAction(GET_BOOKINGS, {result: [], length: 0}));
  });
};

export const getBookingByReference = (reference) => (dispatch, getState) => {
  const { auth } = getState();
  dispatch(createAction(LOADING_BOOKING));
  // internalApi.get('v2/bookings', {}, {'Authorization': `Bearer ${auth.token}`}).then((response) => {
  // internalApi.get('v2/bookings', {access_token: auth.token}).then((response) => {
  internalApi.get(`v2/customer/me/booking/${reference}`, {}, { 'x-access-token': auth.token }).then((response) => {
    // console.log(response);
    dispatch(createAction(DETAIL_BOOKING, response));
  }).catch((err) => {
    console.log(err);
    // dispatch(createAction(GET_BOOKINGS, {result: [], length: 0}));
  });
};

export const detailBooking = (data = {}) => (dispatch) => {
  dispatch(createAction(DETAIL_BOOKING, data));
};

export const getSubscriptions = (params = {}) => (dispatch, getState) => {
  const { auth } = getState();
  dispatch(createAction(LOADING_BOOKING));
  internalApi.get('v1/customer-subscriptions', { ...params }, { Authorization: `Bearer ${auth.token}` }).then((response) => {
    dispatch(createAction(GET_SUBSCRIPTIONS, response));
  }).catch((err) => {
    console.log(err);
    dispatch(createAction(GET_SUBSCRIPTIONS, []));
  });
};
