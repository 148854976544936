import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap'
import { Emoji } from '../../components'
import { PaymentAmountDisplay } from './paymentplan/PaymentAmountDisplay'
import { PaymentDepositeSlider } from './paymentplan/PaymentDepositeSlider'
import { PaymentPlanChooseText } from './paymentplan/PaymentPlanChooseText'
import { PaymentPlanHeader } from './paymentplan/PaymentPlanHeader'
import { PaymentPlanOptions } from './paymentplan/PaymentPlanOptions'
import { PaymentStartDate } from './paymentplan/PaymentStartDate'

// eslint-disable-next-line arrow-body-style
export const ReviewPaymentPlan = ({
  paymentPlan,
  onUpdatePaymentPlan,
  reviewData,
  setReviewData,
  minimumDepositValue
}) => {
  const [showCoupon, setShowCoupon] = useState(false)
  const detailFlight = useSelector((state) => state.detailFlight)
  const balance = useSelector((state) => state.referrals.balance)
  const searchQuery = useSelector((state) => state.search.query.flights)
  const departureDate =
    searchQuery.type === 'multi-city'
      ? searchQuery.multiCityLegs[0].departureDate
      : searchQuery.departureDate

  const applyCoupon = (e) => {
    e.preventDefault()
    setReviewData({
      ...reviewData,
      couponCode: e.target.couponCode.value,
    })
    onUpdatePaymentPlan(
      {
        ...reviewData,
        couponCode: e.target.couponCode.value,
      },
      false,
    )
  }
  return (
    <div
      className={classNames('payment-wrap', {
        'no-payment-required': paymentPlan.total === 0 || !paymentPlan.weeks,
      })}
    >
      <Row>
        <PaymentPlanHeader />
        <PaymentStartDate
          reviewData={reviewData}
          setReviewData={setReviewData}
          onUpdatePaymentPlan={onUpdatePaymentPlan}
        />
      </Row>
      <hr />
      <Row>
        <PaymentPlanChooseText />
        <PaymentPlanOptions
          paymentPlan={paymentPlan}
          reviewData={reviewData}
          setReviewData={setReviewData}
        />
      </Row>
      <hr />
      <Row>
        <PaymentDepositeSlider
          reviewData={reviewData}
          detailFlight={detailFlight}
          departureDate={departureDate}
          setReviewData={setReviewData}
          onUpdatePaymentPlan={onUpdatePaymentPlan}
          totalPrice={paymentPlan.total}
          minimumDepositValue={minimumDepositValue}
        />
      </Row>
      <hr />
      <hr />
      {paymentPlan.total > 0 && (
        <PaymentAmountDisplay
          departureDate={departureDate}
          paymentPlan={paymentPlan}
          reviewData={reviewData}
          detailFlight={detailFlight}
          totalPrice={paymentPlan.total}
        />
      )}
      {(!paymentPlan.discount || (paymentPlan.discount && !paymentPlan.discount.amount)) && (
        <Row>
          <Col>
            {!showCoupon ? (
              <div className='coupon-cta' onClick={() => setShowCoupon(true)}>
                Apply Promo Code <FontAwesomeIcon icon='angle-right' />
              </div>
            ) : (
              <div className='coupon'>
                <Label>Promo Code</Label>
                <Form className='coupon-form' onSubmit={applyCoupon} inline>
                  <FormGroup>
                    <Input
                      id='coupon'
                      type='text'
                      name='couponCode'
                      autoComplete='off'
                      invalid={paymentPlan.discount && paymentPlan.discount.errors}
                    />
                    <FormFeedback>
                      {paymentPlan.discount &&
                        paymentPlan.discount.errors &&
                        paymentPlan.discount.errors.message}
                    </FormFeedback>
                  </FormGroup>
                  <Button>Apply</Button>
                </Form>
              </div>
            )}
          </Col>
        </Row>
      )}

      {balance > 0 &&
        (!paymentPlan.discount || (paymentPlan.discount && !paymentPlan.discount.amount)) && (
          <div
            className='coupon-cta'
            onClick={() => {
              setReviewData({
                ...reviewData,
                credit: balance,
              })
              onUpdatePaymentPlan(
                {
                  ...reviewData,
                  credit: balance,
                },
                false,
              )
            }}
          >
            Hooray! You have ${balance && parseFloat(balance / 100).toFixed(2)} PLT Credit to spend{' '}
            <Emoji symbol='😀' label='happy' /> Apply credit <FontAwesomeIcon icon='angle-right' />
          </div>
        )}
    </div>
  )
}
