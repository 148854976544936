/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux'
import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { KEY_PERSIST_STORE } from '../config'
import rootReducer from './rootReducer'

const migrations = {
  0: (state) => ({
    ...state,
    search: {
      query: {
        flights: {
          origin: null,
          destination: null,
          type: 'return', // return or one-way
          departureDate: null,
          returnDate: null,
          adults: 1,
          children: 0,
          infants: 0,
          multiCityLegs: [
            {
              origin: null,
              destination: null,
              departureDate: '',
            },
            {
              origin: null,
              destination: null,
              departureDate: '',
            },
          ],
        },
        hotels: {
          destination: null,
          checkInDate: null,
          checkOutDate: null,
          adults: 2,
          childAges: [],
          rooms: 1,
        },
      },
      results: {
        data: [],
      },
      loading: false,
    },
  }),
}

const persistConfig = {
  key: KEY_PERSIST_STORE,
  storage,
  stateReconciler: autoMergeLevel2,
  version: 0,
  whitelist: [
    'auth',
    'search',
    'detailFlight',
    'detailHotel',
    'passengers',
    'referrals',
    'paymentPlan',
    'cards',
    'bookings',
  ],
  migrate: createMigrate(migrations, { debug: false }),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// const composeEnhancer =
//     (process.env.NODE_ENV === "development"
//         ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//               trace: true
//           })
//         : null) || compose;

// const composeEnhancer = compose;

export default () => {
  const store = createStore(persistedReducer, compose(applyMiddleware(thunk)))
  const persistor = persistStore(store)
  return { store, persistor }
}
