import React, { Component } from 'react';
import * as moment from 'moment';
import { Row, Col, Button } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IS_USA_SITE } from '../constants';
import FlightDetails from './FlightDetails';
import { _url, _formatMoney } from '../config/utils';
import { renderDetailFlight, renderFlightSegments } from './search/results/SearchResultHooks';
import ShowChangedFlightPrice from './search/form/ShowChangedFlightPrice';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BlockIcon from '@mui/icons-material/Block';

class SearchResultBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailOpen: false
        };
    }

    toggleDetail = () => {
        this.setState({
            detailOpen: !this.state.detailOpen
        });
    };

    render() {
        const { searchResult } = this.props;
        const daysTillDeparture = searchResult
            ? moment(searchResult.itineraries[0].segments[0].departureAt.split('T')[0]).diff(moment(), 'days') + 1
            : 0;
        // return (
        //     <div key={uuidv4()} className={classnames("search-result-box", {
        //             "no-select": this.props.noSelect,
        //             "current-flight": this.props.isCurrentFlight,
        //             "selected": this.props.selected
        //         })}>
        //         <div className={classnames("search-result", {"detail-open": this.state.detailOpen})}>
        //             {this.props.isCurrentFlight && <div className="search-result-current-flight">Your current flight</div>}
        //             <Row>
        //                 <Col className={`left-content`}>
        //                     <Row>
        //                         {this.renderFlightSegments(searchResult)}
        //                         <Col xs={12} className="search-result-box__controls">
        //                             <div className="flight-details-toggle">
        //                                 <div className="content-icon" onClick={this.toggleDetail}>
        //                                     Flight and baggage info
        //                                     <FontAwesomeIcon icon="angle-down"/>
        //                                 </div>
        //                             </div>
        //                             {!this.props.noSelect && !this.props.changeFlights && <div className="search-result-box__highlights">
        //                                 <span className="no-change-fees d-none d-md-block"><FontAwesomeIcon icon="check"/> 30-day no change fees</span>
        //                                 {searchResult.includedCheckedBagsOnly && <span className="includes-checked-baggage d-none d-md-block" onClick={this.toggleDetail}><FontAwesomeIcon icon="check"/> Includes checked baggage</span>}
        //                             </div>}
        //                         </Col>
        //                     </Row>
        //                 </Col>
        //                 {!this.props.noSelect && <Col md="auto" className={`right-content`}>
        //                     <div>
        //                         {this.renderDetailFlight(searchResult.itineraries)}
        //                         {!this.props.isCurrentFlight && !this.props.currentFlight && <p className="title">from</p>}
        //                         {!this.props.isCurrentFlight && !this.props.currentFlight && <p className="pw">${_formatMoney(searchResult.pricePerWeek / 100)}<sup>AU</sup>/wk</p>}
        //                         {!this.props.isCurrentFlight && !this.props.complex && this.props.currentFlight && <p className="pw">+${(searchResult.totalCompletePrice - this.props.currentFlight.salePriceInc <= 0) ? 0 : parseFloat((searchResult.totalCompletePrice - this.props.currentFlight.salePriceInc )/ 100).toFixed(2)}</p>}
        //                         {!this.props.isCurrentFlight && !this.props.currentFlight && <p className="total">${_formatMoney(searchResult.totalCompletePrice / 100)} total <span>AU</span></p>}
        //                     </div>
        //                     {!this.props.isCurrentFlight && <div>
        //                         <Button
        //                             onClick={() => this.props.onSelect(searchResult.id)}
        //                             className={classnames("btn-select", {
        //                                 'selected': this.props.selected,
        //                                 'Button__loading': this.props.selectedLoading
        //                             })}>
        //                                 <span className="btn-select-default">
        //                                     {this.props.selectedLoading && <div className="LoadingDots"></div>}
        //                                     {!this.props.selectedLoading && this.props.selected && 'Selected'}
        //                                     {!this.props.selectedLoading && !this.props.selected && 'Select'}
        //                                     {!this.props.selectedLoading && !this.props.selected && <img alt="left-arrow" src={_url('assets/images/left-arrow.png')} />}
        //                                 </span>
        //                         </Button>
        //                         {/* {// remove 30 day no change fees for change flights //} */}
        //                         <span className="no-change-fees d-block d-md-none"><FontAwesomeIcon icon="check"/> 30-day no change fees</span>
        //                         {searchResult.includedCheckedBagsOnly && <span className="no-change-fees d-block d-md-none"><FontAwesomeIcon icon="check"/> Includes checked baggage</span>}
        //                     </div>}
        //                 </Col>}
        //             </Row>
        return (
            <div key={uuidv4()} className={classNames('search-result-box', { 'no-select': this.props.noSelect })}>
                <div className={classNames('search-result', { 'detail-open': this.state.detailOpen })}>
                    <Row>
                        <Col className="left-content">
                            <Row>
                                {renderFlightSegments(searchResult)}
                                <Col xs={12} className="search-result-box__controls">
                                    <div className="flight-details-toggle">
                                        <div className="content-icon" onClick={this.toggleDetail}>
                                            Flight and baggage info
                                            <FontAwesomeIcon icon="angle-down" />
                                        </div>
                                    </div>
                                    {!this.props.noSelect && (
                                        <div className="search-result-box__highlights">
                                            {/* <span className="no-change-fees d-none d-md-block">
                        <FontAwesomeIcon icon="check" /> 30-day no change fees
                      </span> */}
                                            <span className="no-change-fees d-none d-md-block">
                                                {searchResult.itineraries[0].changesPermitted ? (
                                                    <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                                ) : (
                                                    <BlockIcon
                                                        style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }}
                                                    />
                                                )}
                                                Changes
                                            </span>
                                            <span className="no-change-fees d-none d-md-block">
                                                {searchResult.itineraries[0].cancellationsPermitted ? (
                                                    <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                                ) : (
                                                    <BlockIcon
                                                        style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }}
                                                    />
                                                )}
                                                {IS_USA_SITE ? 'Cancelation' : 'Cancellation'}
                                            </span>
                                            {/* {searchResult.includedCheckedBagsOnly && (
                        <span className="includes-checked-baggage d-none d-md-block" onClick={this.toggleDetail}>
                          <FontAwesomeIcon icon="check" />
                          {' '}
                          Includes checked baggage
                        </span>
                      )} */}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        {!this.props.noSelect && (
                            <Col md="auto" className="right-content">
                                {this.props.currentFlightPrice ? (
                                    <ShowChangedFlightPrice
                                        searchResult={searchResult}
                                        currentFlightPrice={this.props.currentFlightPrice}
                                    />
                                ) : (
                                    <div>
                                        {renderDetailFlight(searchResult)}
                                        <p className="title">from</p>
                                        {daysTillDeparture < 42 ? (
                                            <p className="pw">
                                                ${_formatMoney(searchResult.totalCompletePrice / 100)}
                                                <sup>{searchResult.currency.slice(0, -1)}</sup>
                                            </p>
                                        ) : (
                                            <div>
                                                <p className="pw">
                                                    ${_formatMoney(searchResult.pricePerWeek / 100)}
                                                    <sup>{searchResult.currency.slice(0, -1)}</sup>
                                                    /wk
                                                </p>
                                                <p className="total">
                                                    ${_formatMoney(searchResult.totalCompletePrice / 100)} total
                                                    <sup>{searchResult.currency.slice(0, -1)}</sup>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div>
                                    <Button onClick={() => this.props.onSelect(searchResult.id)} className="btn-select">
                                        Select
                                        <img alt="left-arrow" src={_url('assets/images/left-arrow.png')} />
                                    </Button>

                                    <span className="no-change-fees d-block d-md-none">
                                        {searchResult.itineraries[0].changesPermitted ? (
                                            <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                        ) : (
                                            <BlockIcon style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }} />
                                        )}
                                        Changes
                                    </span>
                                    <span className="no-change-fees d-block d-md-none">
                                        {searchResult.itineraries[0].cancellationsPermitted ? (
                                            <AttachMoneyIcon style={{ fontSize: 16, marginBottom: 2 }} />
                                        ) : (
                                            <BlockIcon style={{ fontSize: 14, marginBottom: 2, marginRight: 2 }} />
                                        )}
                                        {IS_USA_SITE ? 'Cancelation' : 'Cancellation'}
                                    </span>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
                {this.state.detailOpen && (
                    <FlightDetails itinerary={searchResult} flights={searchResult.itineraries} type={this.props.type} />
                )}
            </div>
        );
    }
}

export default SearchResultBox;
