/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import { message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { FlightDetails, Footer, SearchInfo } from '../../components';
import ReviewTermsAndConditionPrompt from '../../components/review/ReviewTermsAndConditionPrompt';
import { ADD_RECENTLY_COMPLETED_BOOKING } from '../../redux/modules/bookings';
import { updateLoading } from '../../redux/modules/loading';
import { getPaymentPlan } from '../../redux/modules/paymentPlan';
import { ReviewContactCard } from './ReviewContactCard';
import { ReviewPaymentCard } from './ReviewPaymentCard';
import { ReviewPaymentPlan } from './ReviewPaymentPlan';
import { ReviewSupport } from './support/ReviewSupport';
import {
  getInitialDepositPercent,
  paymentSubmit,
  transformPaymentPlanPayload,
  transformPaymentPlanPayloadForTraveller,
  updateDataLayer,
  validateReviewData,
} from './Utils';

const ReviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchQuery = useSelector((state) => state.search.query.flights);
  const passengers = useSelector((state) => state.passengers);
  const detailFlight = useSelector((state) => state.detailFlight);
  const paymentPlan = useSelector((state) => state.paymentPlan);
  // console.log("paymentPlan selector", paymentPlan);
  const auth = useSelector((state) => state.auth);
  const departureDate =
    searchQuery.type === 'multi-city'
      ? searchQuery.multiCityLegs[0].departureDate
      : searchQuery.departureDate;
  const [reviewData, setReviewData] = useState({
    startPayment: '',
    error: {
      startPayment: false,
    },
    showCheckTermAndConditionPrompt: false,
    plan: 1,
    isSubmmit: false,
    depositPercent: getInitialDepositPercent(
      departureDate,
      detailFlight.carriers,
      paymentPlan.data.total,
    ),
    couponCode: paymentPlan.discount?.couponCode || '',
    credit: null,
  });

  const [minimumDepositValue, setMinimumDepositValue] = useState(null);
  
  useEffect(() => {
    onUpdatePaymentPlan(
      { ...reviewData, paymentStartDate: moment().unix() },
      true,
      paymentPlan.data.total,
    );
  }, []);

  const onUpdatePaymentPlan = (values, resetDepositPercent, paymentPlanTotal) => {
    dispatch(
      getPaymentPlan(transformPaymentPlanPayload(values, auth, detailFlight, departureDate), auth),
    ).then((res) => {
      if (!resetDepositPercent) {
        return;
      }
      setMinimumDepositValue({
        maxDepositAmount: res.maxDepositAmount,
        maxDepositPercentage: res.maxDepositPercentage,
        minDepositAmount: res.minDepositAmount,
        minDepositPercentage: res.minDepositPercentage
      });
      setReviewData({
        ...reviewData,
        couponCode: res.discount?.couponCode || '',
        depositPercent: getInitialDepositPercent(departureDate, detailFlight.carriers, res.total),
      });
      if (res.total !== paymentPlanTotal) {
        onUpdatePaymentPlan(
          {
            ...values,
            depositPercent: getInitialDepositPercent(
              departureDate,
              detailFlight.carriers,
              res.total,
            ),
          },
          resetDepositPercent,
          res.total,
        );
      }
    });
  };

  const addBooking = (data) => {
    dispatch({
      type: ADD_RECENTLY_COMPLETED_BOOKING,
      data,
    });
  };

  const onPaymentSubmit = (paymentMethod) => {
    if (!validateReviewData(reviewData, setReviewData)) {
      return;
    }

    dispatch(updateLoading(true));
    const data = transformPaymentPlanPayloadForTraveller(
      passengers,
      detailFlight,
      reviewData,
      paymentPlan,
      searchQuery,
      paymentMethod,
    );
    paymentSubmit(data, auth)
      .then((res) => {
        // store the booking in our redux state
        addBooking({
          deposit: data.deposit,
          instalmentType: data.instalmentType,
          pricePerInstalment: data.pricePerInstalment / 100,
          numberOfInstalments: data.numberOfInstalments,
          reference: res.reference,
          authProviderId: res.auth_provider_id,
        });
        updateDataLayer(data, reviewData.couponCode, res.reference);
        dispatch(updateLoading(false));
        navigate('/confirm', { state: { type: reviewData.plan } });
      })
      .catch((err) => {
        dispatch(updateLoading(false));
        message.error(err.response ? err.response.data.message : 'Error');
      });
  };

  return (
    <div>
      <Helmet>
        <title>Review</title>
      </Helmet>
      <div className='page-content'>
        <div className='content-search container-fluid content-review review'>
          <Row>
            <Col sm='auto' xs={12}>
              <SearchInfo searchQuery={searchQuery} />
            </Col>
            <Col className='content'>
              <div className='container'>
                <h3>Review</h3>
                <FlightDetails flights={detailFlight.itineraries} type={searchQuery.type} />
                <div className='total max-w-640' style={{ marginBottom: 30 }}>
                  <ReviewContactCard phone={passengers.phone_number} email={passengers.email} />
                </div>

                <div id={'single-date-col'} className='payment-plan-wrapper total max-w-640'>
                  <h3>Payment plan</h3>

                  <div className='content'>
                    <ReviewPaymentPlan
                      paymentPlan={paymentPlan.data}
                      onUpdatePaymentPlan={onUpdatePaymentPlan}
                      reviewData={reviewData}
                      setReviewData={setReviewData}
                      minimumDepositValue={minimumDepositValue}
                    />
                  </div>
                  <hr />
                  <hr />
                  <ReviewPaymentCard
                    paymentPlan={paymentPlan}
                    reviewData={reviewData}
                    setReviewData={setReviewData}
                    onPaymentSubmit={onPaymentSubmit}
                  />
                </div>
                <ReviewSupport />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {reviewData.showCheckTermAndConditionPrompt && (
        <ReviewTermsAndConditionPrompt
          handleClose={() =>
            setReviewData({
              ...reviewData,
              showCheckTermAndConditionPrompt: false,
            })
          }
        />
      )}
      <Footer />
    </div>
  );
};
export default ReviewPage;
