/* eslint-disable arrow-body-style */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { _url } from '../../config/utils'
import { formatPhoneNumber } from '../../utils/SiteUtils'

export const ReviewContactCard = ({ email, phone }) => {
  return (
    <div className='content'>
      <Row style={{ padding: 0 }}>
        <Col>
          <div style={{ marginBottom: 10 }}>
            <span style={{ fontWeight: 600, color: 'black', marginRight: 10 }}>Contact Email:</span>
            {email}
          </div>
          <div>
            <span style={{ fontWeight: 600, color: 'black', marginRight: 10 }}>Phone Number:</span>
            {formatPhoneNumber(phone)}
          </div>
        </Col>
        <Col style={{ flexGrow: 0 }}>
          <NavLink className='edit' to='/flightssearch/passenger'>
            <img alt='edit' className='ico-search' src={_url('assets/images/pen.svg')} />
          </NavLink>
        </Col>
      </Row>
    </div>
  )
}
