import React, { Component } from 'react';
import * as moment from 'moment';
import Tippy from '@tippyjs/react';
import { _url } from '../config/utils';

class FlightDetails extends Component {
    formatDuration = (duration) => duration.replace('PT', '').replace('D', 'd ').replace('H', 'h ').replace('M', 'm');

    formatStopoverDuration = (arrivalAt, departureAt) => {
        const arrive = moment(arrivalAt);
        const depart = moment(departureAt);
        const diff = arrive.diff(depart);
        return this.formatDuration(moment.duration(diff).toISOString());
    };

    renderSegments = (flight, itinerary) =>
        flight.segments.map((subFlight, index) => (
            <div className="leg__subflight" key={index}>
                <div className="leg__subflight__heading">
                    <img
                        className="logo-flight"
                        alt="logo"
                        src={_url(`assets/images/logos/${subFlight.carrierName.trim()}.png`)}
                    />
                    <span>
                        {`${subFlight.carrierName} ${subFlight.carrierCode}${subFlight.carrierNumber}`}
                        {/* <span>{itinerary && `• ${itinerary.travelerPricings[0].fareDetailsBySegment.find((x) => x.id === subFlight.segmentId).brandedFare}`}</span> */}
                    </span>
                </div>
                <div className="leg__subflight__timeline">
                    <div className="leg__subflight__duration">{this.formatDuration(subFlight.duration)}</div>
                    <div className="leg__subflight__segments">
                        <div className="leg__subflight__circles">
                            <div className="circles-track" />
                        </div>
                        <div className="leg__subflight__segments-routes">
                            <div>
                                <Tippy
                                    content={moment(subFlight.departureAt).format('h:mma on ddd, D MMM YYYY')}
                                    animation="shift-away"
                                    duration={[100, 80]}
                                    className="light"
                                >
                                    <span>
                                        {moment(subFlight.departureAt).format('h:mm')}
                                        <span className="am-pm">{moment(subFlight.departureAt).format('a')}</span>
                                    </span>
                                </Tippy>
                                {subFlight.departureCode} {subFlight.departureLocation}
                            </div>
                            <div>
                                <Tippy
                                    content={moment(subFlight.arrivalAt).format('h:mma on ddd, D MMM YYYY')}
                                    animation="shift-away"
                                    duration={[100, 80]}
                                    className="light"
                                >
                                    <span>
                                        {moment(subFlight.arrivalAt).format('h:mm')}
                                        <span className="am-pm">{moment(subFlight.arrivalAt).format('a')}</span>
                                    </span>
                                </Tippy>
                                {subFlight.arrivalCode} {subFlight.arrivalLocation}
                            </div>
                        </div>
                    </div>
                </div>
                {flight.stops > 0 && index < flight.segments.length - 1 && (
                    <div className="leg__stopover">
                        <div className="leg__subflight__timeline">
                            <div className="leg__subflight__duration">
                                {this.formatStopoverDuration(
                                    flight.segments[index + 1].departureAt,
                                    subFlight.arrivalAt
                                )}
                            </div>
                            <div className="leg__stopover__message">
                                <div>Connect in airport</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        ));

    render() {
        const numItineraries = this.props.flights && this.props.flights.length;

        return (
            <div className="search-result-box__flight-details">
                {this.props.flights &&
                    this.props.flights.map((flight, index) => (
                        <div className="leg" key={flight.id}>
                            <div className="leg__heading">
                                <span className="leg__heading__title">
                                    {this.props.type === 'multi-city' && `Flight ${index + 1}`}
                                    {this.props.type !== 'multi-city' && numItineraries <= 2 && index === 0 && 'Depart'}
                                    {this.props.type !== 'multi-city' && numItineraries === 2 && index > 0 && 'Return'}
                                </span>
                                <span className="leg__heading__departure">
                                    {moment(flight.segments[0].departureAt).format('dddd, DD MMMM')}
                                </span>
                            </div>
                            <div className="leg__details">
                                {this.renderSegments(flight, this.props.itinerary)}
                                <ul className="leg__meta-info">
                                    <li>
                                        <strong>Arrives: </strong>
                                        {moment(flight.segments[flight.segments.length - 1].arrivalAt).format(
                                            'dddd, DD MMMM'
                                        )}
                                    </li>
                                    <li>
                                        <strong>Duration: </strong>
                                        {this.formatDuration(flight.duration)}
                                    </li>
                                    {flight.segments[0].includedCheckedBags && (
                                        <li className="search-result-box__baggage">
                                            <strong>Baggage: </strong>
                                            {Object.prototype.hasOwnProperty.call(
                                                flight.segments[0].includedCheckedBags,
                                                'weight'
                                            )
                                                ? flight.segments[0].includedCheckedBags.weight
                                                : ''}
                                            {Object.prototype.hasOwnProperty.call(
                                                flight.segments[0].includedCheckedBags,
                                                'weightUnit'
                                            )
                                                ? `${flight.segments[0].includedCheckedBags.weightUnit} of checked baggage`
                                                : ''}
                                            {Object.prototype.hasOwnProperty.call(
                                                flight.segments[0].includedCheckedBags,
                                                'quantity'
                                            )
                                                ? `${flight.segments[0].includedCheckedBags.quantity} piece(s) of checked baggage`
                                                : ''}
                                        </li>
                                    )}
                                    {flight.segments[0].travellerDetails &&
                                        (flight.segments[0].travellerDetails[0].checkedBaggageQuantity ||
                                            flight.segments[0].travellerDetails[0].checkedBaggageWeight ||
                                            flight.segments[0].travellerDetails[0].checkedBaggageWeightUnit) && (
                                            <li className="search-result-box__baggage">
                                                <strong>Baggage: </strong>
                                                {flight.segments[0].travellerDetails[0].checkedBaggageWeight
                                                    ? flight.segments[0].travellerDetails[0].checkedBaggageWeight
                                                    : ''}
                                                {flight.segments[0].travellerDetails[0].checkedBaggageWeightUnit
                                                    ? `${flight.segments[0].travellerDetails[0].checkedBaggageWeightUnit} of checked baggage`
                                                    : ''}{' '}
                                                {flight.segments[0].travellerDetails[0].checkedBaggageQuantity
                                                    ? `${flight.segments[0].travellerDetails[0].checkedBaggageQuantity} piece(s) of checked baggage`
                                                    : ''}
                                            </li>
                                        )}
                                    <li>
                                        <strong>Class of service: </strong>
                                        {flight.brandedFare}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}

export default FlightDetails;
