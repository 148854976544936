// Buttons
export const selectButtonBackgroundColor = '#58a946';

// Texts
export const subHeaderTextColor = '#000000d9';

export const whiteColor = 'white';
export const blackColor = 'black';
export const greyColor = 'grey';
export const searchFormInputBorderColor = '#d1d1d1';
export const searchFormInpuColor = '#1e1e1e';
export const searchFormFocusedColor = '#19b4d1';
export const searchFormDropdownColor = '#191a1b';
export const searchFormActiveColor = 'rgb(234, 234, 234)';
export const searchFormDropdownActiveColor = 'rgb(218, 218, 218)';
export const searchFormDropdownFocusColor = '#58a946';
export const searchFormNumberColor = '#191b37';
export const searchFormTitleColor = '#353d74';
export const searchFormBorderColor = '#e1e1e1';
export const changeFlightGradientColor = '#31aec6';
export const dangerTextColor = '#e83e3e';
export const successTextColor = '#28a745';
export const buttonPrimaryColor = '#58A947';
export const linkColor = '#4484CD';
