import { InfoOutlined } from '@mui/icons-material';
import { Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { formatCurrency } from '../../../../utils/SiteUtils';
import { PrimaryButton } from '../../../common/CommonStyle';
import TaxAndEventPopup from '../../checkout/TaxAndEventPopup';

const PriceDetailPopup = ({ rates, handleRoomSelect }) => {
    const [taxesPopup, setTaxesPopup] = useState(null);

    return (
        <Grid container p={'10px'}>
            <Typography fontSize={'24px'} fontWeight={'bold'}>
                Price details
            </Typography>
            <Grid item mt={'30px'} xs={12}>
                <Grid spacing={'10px'} container justifyContent={'center'}>
                    <Grid item xs={6}>
                        <Typography fontSize={'18px'} color={'grey'}>
                            Base fare:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color={'grey'} fontSize={'18px'}>
                            {rates.fees.localCurrency} {formatCurrency(parseFloat(rates.fees.baseFare || '0.00'))}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontSize={'18px'} color={'grey'}>
                            Extra person fees
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color={'grey'} fontSize={'18px'}>
                            {rates.fees.localCurrency}{' '}
                            {formatCurrency(parseFloat(rates.fees.extraPersonFees || '0.00'))}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontSize={'18px'} color={'grey'}>
                            Sales taxes
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color={'grey'} fontSize={'18px'}>
                            {rates.fees.localCurrency} {formatCurrency(parseFloat(rates.fees.totalSalesTax || '0.00'))}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontSize={'18px'} color={'grey'}>
                            Taxes and fees{' '}
                            <InfoOutlined
                                style={{ fontSize: 18, cursor: 'pointer' }}
                                onClick={(event) => setTaxesPopup(event.target)}
                            />{' '}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color={'grey'} fontSize={'18px'}>
                            {rates.fees.localCurrency} {formatCurrency(parseFloat(rates.fees.taxes || '0.00'))}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontWeight={'bold'} fontSize={'18px'} color={'grey'}>
                            Total
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontWeight={'bold'} color={'grey'} fontSize={'18px'}>
                            {rates.fees.total.currency}{' '}
                            {formatCurrency(parseFloat(rates.fees.total.inclusive || '0.00'))}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {rates.refundable && (
                        <Grid item xs={12}>
                            {rates?.cancellationPolicy[0]?.end && (
                                <Typography fontSize={'18px'} color={'grey'}>
                                    Full refundable before{' '}
                                    {moment(rates.cancellationPolicy[0].start).format('ddd, MMM, DD hh:mm a')}
                                </Typography>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid container justifyContent={'flex-start'}>
                            <Grid item xs={6}>
                                <PrimaryButton variant={'contained'} onClick={() => handleRoomSelect()}>
                                    Reserve
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TaxAndEventPopup
                open={Boolean(taxesPopup)}
                id={taxesPopup ? 'simple-popover' : undefined}
                anchorEl={taxesPopup}
                handleClose={() => setTaxesPopup(null)}
            />
        </Grid>
    );
};

export default PriceDetailPopup;
