/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { debounce } from 'throttle-debounce'
import internalApi from '../config/internalApi'
import 'react-bootstrap-typeahead/css/Typeahead.css';

function titleCase(str) {
  // Step 1. Lowercase the string
  str = str.toLowerCase()
  // str = "I'm a little tea pot".toLowerCase();
  // str = "i'm a little tea pot";

  // Step 2. Split the string into an array of strings
  str = str.split(' ')
  // str = "i'm a little tea pot".split(' ');
  // str = ["i'm", "a", "little", "tea", "pot"];

  // Step 3. Create the FOR loop
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }

  // Step 4. Return the output
  return str.join(' ') // ["I'm", "A", "Little", "Tea", "Pot"].join(' ') => "I'm A Little Tea Pot"
}
/**
 * props
 * - "id": unique indentifier
 * - "updateLocation": callback to do something with the selected location (returns an object {id, name})
 * - "selected": pass down to the Typeahead component
 * - "placeholder"
 * - "isInvalid": TODO validations
 */
class AirportCityTypeahead extends Component {
  constructor() {
    super()
    this.state = {
      places: [],
    }
    this.ref = React.createRef()
  }

  search = (keyword) => {
    if (keyword === '') {
      this.setState({ places: [] })
      return false
    }

    internalApi
      .get('/typeahead', {
        keyword,
        subType: 'AIRPORT',
        max: 10,
      })
      .then((res) => {
        const places = []
        if (res.data.length > 0) {
          res.data.forEach((p) => {
            const city = titleCase(p.address_city_name)
            const name = titleCase(p.name)
            const country = titleCase(p.address_country_name)

            places.push({
              id: p.iata_code,
              // name: `${airportName}, ${cityName} (${p.iata_code})`,
              name,
              city,
              country,
            })
          })
        }
        this.setState({ places })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  render() {
    return (
      <div className='SearchForm__input' onClick={() => this.ref.current.focus()}>
        <div className='SearchForm__label'>{this.props.label}</div>
        {/* <div className="SearchForm__fieldValue"></div> */}
        <Typeahead
          // labelKey="airport"
          // labelKey={option => `${option.name}, ${option.city}, ${option.country} (${option.id})`}
          labelKey={(option) =>
            option.id
              ? option.city
                ? `${option.name}, ${option.city} (${option.id})`
                : option.name
                ? `${option.name} (${option.id})`
                : option.id
              : option
          }
          id={this.props.id}
          clearButton
          options={this.state.places.filter((p) => p.id !== 'KMO')}
          maxHeight='300px'
          placeholder={this.props.placeholder}
          onInputChange={debounce(200, this.search)}
          onChange={(selectedLocation) =>
            this.props.updateLocation({
              field: this.props.fieldName,
              location: selectedLocation[0],
              index: this.props.index,
            })
          }
          ref={this.ref}
          // filterBy={(option, props) => {
          //     return option.name.trim().toLocaleLowerCase().indexOf(props.text.trim().toLocaleLowerCase()) > -1;
          // }}
          filterBy={['id', 'airport', 'city', 'country']}
          selected={this.props.selected || []}
          className={`SearchForm__typeahead ${this.props.id}-field autoComplete`}
        />
      </div>
    )
  }
}

export default AirportCityTypeahead
