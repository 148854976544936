export const UPDATE_PASSENGERS = 'plt-web/passengers/UPDATE_PASSENGERS';

const initialState = {
  adults: [],
  children: [],
  infants: [],
  email: '',
  phone_number: '',
  password: ''
};

export default function reducer(state = initialState, { type, ...action }) {
  switch (type) {
    case UPDATE_PASSENGERS: {
      return action.data;
    }
    default: return state;
  }
}

export const updatePassengers = (data = { adults: [], children: [], infants: [] }) => ({
  type: UPDATE_PASSENGERS,
  data
});
