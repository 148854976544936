import React from "react";
import { Col } from "reactstrap";
import { IS_USA_SITE } from "../../../constants";
import InputSlider from "../../../components/common/InputSlider";

export const PaymentDepositeSlider = ({
    onUpdatePaymentPlan,
    departureDate,
    detailFlight,
    reviewData,
    setReviewData,
    totalPrice,
    minimumDepositValue
}) => (
    <>
        <Col sm={12}>
            <h4 className="payment-list-head">3. Select your deposit amount</h4>
            <p className="payment-list-sub-title">
                Pro tip: a greater deposit can significantly lower your {IS_USA_SITE ? "installments" : "instalments"}!
            </p>
            {!!minimumDepositValue && (
                <InputSlider
                    name="depositPercent"
                    max={minimumDepositValue.maxDepositPercentage}
                    value={reviewData.depositPercent}
                    min={minimumDepositValue.minDepositPercentage}
                    onInput={({ depositSliderValue }) => {
                        if (reviewData.depositPercent !== depositSliderValue) {
                            setReviewData({ ...reviewData, depositPercent: depositSliderValue });
                        }
                        onUpdatePaymentPlan({ ...reviewData, depositPercent: depositSliderValue });
                    }}
                />
            )}
        </Col>
        <Col xs={6} className="deposit-slider-labels">
            <span className="ml-2">Min: {minimumDepositValue?.minDepositPercentage || ""}%</span>
        </Col>
        <Col xs={6} style={{ display: "flex", justifyContent: "flex-end" }} className="deposit-slider-labels">
            <span className="float-right mr-4">Max: {minimumDepositValue?.maxDepositPercentage || ""}%</span>
        </Col>
    </>
);
