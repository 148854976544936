import Styled from '@emotion/styled';
import { Col } from 'reactstrap';
import { _url } from '../../config/utils';

export const HomeHeroImageContainer = Styled.div`
    background-image: ${(props) => `url(${_url(props.bgimage)})`} ;
`;

export const HomeTestimonialCard = Styled.div`
    display: flex;
    align-items: center;
    justify-Content: center;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: #19B4D1;
`;

export const FacebookWrapper = Styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HomeTestimonialCol = Styled(Col)`
background-image: 'url(${_url('/assets/images/dots-80-opacity.png')})';
`;