import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import InternalApi from '../../config/internalApi';
// import { getUserInfo } from '../../constants/APIConstants';

const AdminView = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    // fetchUserInfo();
    if (auth.isAuthenticated) {
      navigate('/admin/flighttnc');
    } else {
      navigate('/');
    }
  }, []);
  // TODO - Remove comment when api starts working.

  // const fetchUserInfo = () => {
  //   InternalApi.get(getUserInfo()).then((res) => {
  //     console.log('res', res);
  //   });
  // };
  return <Grid></Grid>;
};

export default AdminView;
