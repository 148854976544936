import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const Map = ({ latitude, longitude, mapWidth, height }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'API_KEY'
    });

    const getPosition = () => ({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude)
    });

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{
                height: height,
                width: '100%'
            }}
            center={getPosition()}
            zoom={10}
        >
            <Marker position={getPosition()} />
        </GoogleMap>
    ) : (
        <></>
    );
};

export default Map;
