import React from "react";
import { Col, Container, Row } from "reactstrap";
import { _url } from "../../config/utils";
import { IS_USA_SITE, WISTIA_EMBED_ID } from "../../constants";
import { whilistEmbedColor } from "../../styles/color";
import WistiaEmbed from "../WistiaEmbed";

const HomeFeature = () => {
    if (IS_USA_SITE) {
        return (
            <Container>
                {/* <Grid mt={"20px"} container justifyContent={"center"}>
                    <div
                        class="trustpilot-widget"
                        data-locale="en-US"
                        data-template-id="56278e9abfbbba0bdcd568bc"
                        data-businessunit-id="6023a4aac0f73e00011dcb3e"
                        data-style-height="52px"
                        data-style-width="100%"
                    >
                        <a
                            href={IS_USA_SITE ? "https://trstp.lt/K3u51cP7p" : "https://trstp.lt/K3u51cP7p"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Trustpilot
                        </a>
                    </div>
                </Grid> */}
                <Row style={{ marginBottom: 80 }}>
                    <Col md={{ size: 8, offset: 2 }}>
                        <h2 className="text-center" style={{ marginBottom: 40 }}>
                            How it works
                        </h2>
                        <WistiaEmbed
                            hashedId={WISTIA_EMBED_ID}
                            controlsVisibleOnLoad={false}
                            settingsControl={false}
                            playerColor={whilistEmbedColor}
                            plugin={null}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4} xs={12} className="IconBox text-center">
                        <img alt="search" src={_url("assets/icons/phone.png")} />
                        <h3>Find great deals</h3>
                        <p>Search from thousands of destinations. Book with a 10%-15% deposit.</p>
                    </Col>
                    <Col md={4} xs={12} className="IconBox text-center">
                        <img alt="yoga" src={_url("assets/icons/calendar.png")} />
                        <h3>Set a payment plan</h3>
                        <p>Pay weekly or bi-weekly for up to 26 weeks. No interest. No credit checks.</p>
                    </Col>
                    <Col md={4} xs={12} className="IconBox text-center">
                        <img alt="hero" src={_url("assets/icons/sunset.png")} />
                        <h3>Book now, pay later</h3>
                        <p>Once all payments are made you&apos;ll receive your e-tickets within 24 hours.</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container>
            {/* <Grid mb={"30px"} container justifyContent={"center"}>
                <Review />
            </Grid> */}
            <Row>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="search" src={_url("assets/icons/phone.png")} />
                    <h3>Book your getaway</h3>
                    <p> Search from thousands of flights and hotels. Book with a fraction of the upfront cost.</p>
                </Col>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="yoga" src={_url("assets/icons/yoga.png")} />
                    <h3>Enjoy flexible payments</h3>
                    <p> Pay weekly or fortnightly before you travel. No interest. No credit checks.</p>
                </Col>
                <Col md={4} xs={12} className="IconBox text-center">
                    <img alt="hero" src={_url("assets/icons/sunset.png")} />
                    <h3>Travel with confidence</h3>
                    <p> Enjoy your getaway knowing that your flights are fully paid off. </p>
                </Col>
            </Row>
        </Container>
    );
};

export default HomeFeature;
