/* eslint-disable arrow-body-style */
import React from "react";
import { useSelector } from "react-redux";
import StripeForm from "../../components/StripeForm";
import { ReviewTermsAndConditions } from "./termsandcondition/ReviewTermsAndConditions";

export const ReviewPaymentCard = ({ paymentPlan, reviewData, setReviewData, onPaymentSubmit }) => {
    const detailFlight = useSelector((state) => state.detailFlight);

    return (
        <div className="card-details-wrapper payment max-w-640">
            <div className="card-detail max-w-640">
                {!(paymentPlan.total === 0) && <h3>Card details</h3>}
                <div className="content">
                    <div className="CheckoutForm">
                        <StripeForm
                            buttonColor="success"
                            buttonText="Pay Deposit & Book now"
                            noLoading
                            disclaimerAboveButton={
                                <ReviewTermsAndConditions
                                    detailFlight={detailFlight}
                                    reviewData={reviewData}
                                    setReviewData={setReviewData}
                                />
                            }
                            noPaymentRequired={paymentPlan.total === 0}
                            handleSubmit={onPaymentSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
