import moment from 'moment';
import React from 'react';
import { renderDetailFlight } from '../../../components/search/results/SearchResultHooks';
import { _formatMoney } from '../../../config/utils';

export const CurrentFlightPrice = ({ searchResult }) => {
    const daysTillDeparture = searchResult
        ? moment(searchResult.itineraries[0].segments[0].departureAt.split('T')[0]).diff(moment(), 'days') + 1
        : 0;
    return (
        <div>
            {renderDetailFlight(searchResult)}
            <p className="title">from</p>
            {daysTillDeparture < 42 ? (
                <p className="pw">
                    ${_formatMoney(searchResult.totalCompletePrice / 100)}
                    <sup>{searchResult.currency.slice(0, -1)}</sup>
                </p>
            ) : (
                <div>
                    <p className="pw">
                        ${_formatMoney(searchResult.pricePerWeek / 100)}
                        <sup>{searchResult.currency.slice(0, -1)}</sup>
                        /wk
                    </p>
                    <p className="total">
                        ${_formatMoney(searchResult.totalCompletePrice / 100)} total
                        <sup>{searchResult.currency.slice(0, -1)}</sup>
                    </p>
                </div>
            )}
        </div>
    );
};
