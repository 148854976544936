import { Divider, Grid, Slider, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../../hooks/CommonHooks';
import TextFieldWrapper from '../../common/TextFieldWrapper';
import { FilterContainer } from './Style';

const valuetext = (value) => `$${value}`;

const HotelSearchFilter = ({ selectedFilters, setSelectedFilters }) => {
    const [initialPrice] = useState(selectedFilters.priceFilter);
    const [searchText, setSearchText] = useState(selectedFilters.searchText);
    const [rating, setRating] = useState(selectedFilters.rating);
    const [priceRange, setPriceRange] = useState([selectedFilters.priceFilter[0], selectedFilters.priceFilter[1]]);

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down(900));

    const handleChange = (_, newValue) => {
        setSelectedFilters({ ...selectedFilters, filterSelected: true });
        setPriceRange(newValue);
    };

    const searchTextDebounce = useDebounce(searchText, 500);
    const ratingDebounce = useDebounce(rating, 500);
    const priceDebounce = useDebounce(priceRange, 500);

    useEffect(() => {
        setSelectedFilters({ ...selectedFilters, searchText: searchText, rating: rating, priceFilter: priceRange });
    }, [searchTextDebounce, priceDebounce]);

    const handleRatingChange = (value) => {
        setSelectedFilters({ ...selectedFilters, filterSelected: true });
        if (selectedFilters.rating.includes(value)) {
            setRating((prev) => prev.filter((item) => item !== value));
            return;
        }
        setRating((prev) => [...prev, value]);
    };

    const clearFilters = () => {
        setSelectedFilters({ ...selectedFilters, filterSelected: false });
        setPriceRange(initialPrice);
        setSearchText('');
        setRating([]);
        setSelectedFilters({
            searchText: '',
            priceFilter: initialPrice,
            rating: []
        });
    };
    return (
        <FilterContainer ismobile={mobileView ? 1 : 0}>
            <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item>
                    <Typography style={{ marginTop: 10 }} variant="h6" gutterBottom>
                        Filters
                    </Typography>
                </Grid>
                {selectedFilters.filterSelected && (
                    <Grid item>
                        <Button variant={'text'} onClick={clearFilters}>
                            Clear all
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider style={{ margin: '10px 0px' }} />
            <TextFieldWrapper
                value={searchText}
                label="Find a hotel"
                onChange={({ target }) => {
                    setSelectedFilters({ ...selectedFilters, filterSelected: true });
                    setSearchText(target.value);
                }}
            />
            <Typography style={{ marginTop: 10 }} variant="h6" gutterBottom>
                Hotel Price
            </Typography>
            <Divider style={{ margin: '10px 0px' }} />
            <Slider
                getAriaLabel={() => 'Temperature range'}
                value={priceRange}
                onChange={handleChange}
                min={initialPrice[0]}
                max={initialPrice[1]}
                valueLabelDisplay="auto"
                getAriaValueText={valuetext}
            />
            <Typography variant="body1" gutterBottom>
                ${parseInt(selectedFilters?.priceFilter?.[0] || '0')} - $
                {parseInt(selectedFilters.priceFilter?.[1] || '0')}
            </Typography>
            {/* <Typography style={{ marginTop: 10 }} variant="h6" gutterBottom>
                Rating
            </Typography>
            <Divider style={{ margin: "10px 0px" }} />
            {RatingOptions.map(({ value }) => (
                <Grid container alignItems={"center"} key={value}>
                    <Grid item>
                        <Checkbox
                            key={selectedFilters.rating.length}
                            onClick={() => handleRatingChange(value)}
                            checked={rating.includes(value)}
                        />
                    </Grid>
                    <Grid item>
                        <Rating value={value} readOnly />
                    </Grid>
                </Grid>
            ))} */}
        </FilterContainer>
    );
};

export default HotelSearchFilter;
