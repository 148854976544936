import internalApi from '../../config/internalApi'
import { createAction } from '../utils'
import { updateSearch } from './search'

const UPDATE_HOTEL_OFFERS = 'plt-web/detailHotel/UPDATE_HOTEL_OFFERS'
const EMPTY_HOTEL_DETAIL = 'plt-web/detailHotel/EMPTY_HOTEL_DETAIL'
const SELECT_HOTEL_OFFER = 'plt-web/detailHotel/SELECT_HOTEL_OFFER'
const LOADING_HOTEL = 'plt-web/detailHotel/LOADING_HOTEL'

const initialState = {
  offers: [],
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOADING_HOTEL: {
      return {
        ...state,
        selectedOfferId: undefined,
        loading: true,
      }
    }
    case UPDATE_HOTEL_OFFERS: {
      const { type, ...everythingElse } = action.payload
      return {
        ...state,
        ...everythingElse,
        offers: action.payload.offers,
        selectedOfferId: undefined,
        loading: false,
      }
    }
    case SELECT_HOTEL_OFFER: {
      return {
        ...state,
        selectedOfferId: action.data,
        loading: false,
      }
    }
    case EMPTY_HOTEL_DETAIL: {
      return initialState
    }
    default:
      return state
  }
}

export const selectHotelOffer = (data = {}) => ({
  type: SELECT_HOTEL_OFFER,
  data,
})

export const emptyHotelDetail = () => ({
  type: EMPTY_HOTEL_DETAIL,
})

export const getHotelOffers =
  ({ hotelId, rooms, adults, checkInDate, checkOutDate, childAges }) =>
  (dispatch) => {
    dispatch(createAction(LOADING_HOTEL))

    // build params
    const params = {
      hotelId,
      roomQuantity: rooms,
      adults,
      checkInDate,
      checkOutDate,
      childAges: childAges.length > 0 ? childAges.toString() : undefined,
      // paymentPolicy: 'GUARANTEE'
    }

    // update search
    dispatch(
      updateSearch(
        {
          adults,
          checkInDate,
          checkOutDate,
          rooms,
          childAges: !params.childAges ? [] : params.childAges.split(',').map(Number),
        },
        'hotel',
      ),
    )

    internalApi
      .get('/v2/hotel-offers/by-hotel', params)
      .then(async (response) => {
        if (response.errors) {
          // dispatch(createAction(UPDATE_HOTEL_OFFERS, []));
          console.log(response.errors)
        } else {
          console.log(response)

          // loop through each offer and fetch plt description from info.txt files
          for (let i = 0; i < response.offers.length; i++) {
            const offer = response.offers[i]

            if (offer.room.descriptionURI) {
              const cdnUrl = 'https://hotelscdn.paylatertravel.com.au/'
              try {
                const description = await internalApi.get(`${cdnUrl}${offer.room.descriptionURI}`)
                // console.log(response);
                response.offers[i] = {
                  ...offer,
                  room: {
                    ...offer.room,
                    formattedDescription: description,
                  },
                }
              } catch (err) {
                console.log(err)
              }
            } else {
              // console.log("skipping");
              response.offers[i] = {
                ...offer,
              }
            }
          }

          dispatch(createAction(UPDATE_HOTEL_OFFERS, response))
        }
      })
      .catch((err) => {
        // console.log(err);
        // message.error(err.response.data.message);
        dispatch(createAction(UPDATE_HOTEL_OFFERS, []))
      })
  }
