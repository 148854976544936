import React, { useRef } from "react";
import { SearchFormInputContainer, SearchFormLabel, SearchFormTypeHead } from "../search/form/Styled";
import { debounce } from "throttle-debounce";

const SearchTextfieldWrapper = (props) => {
    const typeHeadRef = useRef();

    const { onInputChange, onOptionSelect, options } = props;

    const valueChange = (text) => {
        onInputChange(text);
    };
    return (
        <SearchFormInputContainer onClick={() => typeHeadRef.current.focus()}>
            <SearchFormLabel>{props.label || ""}</SearchFormLabel>
            <SearchFormTypeHead
                labelKey={(option) =>
                    option.id
                        ? option.city
                            ? `${option.name}, ${option.city}`
                            : option.name
                            ? `${option.name}`
                            : option.id
                        : option
                }
                id={props.id || "id"}
                clearButton
                options={options || []}
                maxHeight="300px"
                placeholder={props.placeholder || ""}
                onInputChange={debounce(200, valueChange)}
                onChange={(selectedLocation) => {
                    selectedLocation.length > 0 && onOptionSelect(selectedLocation[0].name, selectedLocation[0].id);
                }}
                defaultInputValue={props.value}
                ref={typeHeadRef}
            />
        </SearchFormInputContainer>
    );
};

export default SearchTextfieldWrapper;
