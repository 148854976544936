import Styled from '@emotion/styled';
import { Navbar, Dropdown, Modal } from 'reactstrap';

export const NavbarContainer = Styled(Navbar)`
& .container-fluid {
    align-items: ${(props) => (props.isopen ? 'baseline' : 'center')}!important;
}
`;

export const DropdownContainer = Styled(Dropdown)`
    & .dropdown-menu {
        right: 20px !important;
    }
`;

export const StyledModal = Styled(Modal)`
    & .btn-close {
        position: absolute;
        left: 5px;
        top: 5px;
        margin-top: 0;
        padding: 10px;
        font-size: 14px;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
    }
`;