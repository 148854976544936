import message from 'antd/es/message'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { Button, Form, FormFeedback, InputGroup } from 'reactstrap'
import internalApi from '../config/internalApi'
import { withRouter } from '../hooks/CommonHooks'
import { loginUser } from '../redux/modules/auth'

function UpdatePasswordForm(props) {
  const { handleSubmit, register, errors, watch } = useForm()

  const onSubmit = (values) => {
    internalApi
      .put('v2/auth/password', {
        authProviderId: props.authProviderId,
        password: values.password,
      })
      .then((res) => {
        props.loginUser({
          email: props.email,
          password: values.password,
        })

        console.log(res)
      })
      .catch((error) => {
        // console.log(error.response);
        message.error(error.response.data.error.message)
      })
  }

  return (
    <div>
      <Form className='update-password-form' onSubmit={() => handleSubmit(onSubmit)}>
        <InputGroup>
          <input
            type='password'
            name='password'
            id='password'
            className={classnames('form-control', { 'is-invalid': errors.password })}
            placeholder='Enter Password'
            ref={register({
              required: 'Password required',
              maxLength: 20,
            })}
            invalid={errors}
          />
          {errors.password && <div className='form-error'>{errors.password.message}</div>}
        </InputGroup>
        <InputGroup>
          <input
            type='password'
            name='confirmPassword'
            id='confirm-password'
            className={classnames('form-control', { 'is-invalid': errors.confirmPassword })}
            placeholder='Confirm password'
            ref={register({
              validate: (value) =>
                value !== watch('password') ? 'Passwords don\'t match' : undefined,
            })}
            invalid
          />
          <FormFeedback>{errors.confirmPassword && errors.confirmPassword.message}</FormFeedback>
        </InputGroup>
        <Button block>Go to dashboard</Button>
      </Form>
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  loginUser: (params) => {
    dispatch(loginUser(params))
      .then((res) => {
        ownProps.router.navigate(`/bookings/${ownProps.reference}`)
      })
      .catch((err) => {
        console.log(err)
        message.error('Something wen\'t wrong logging in.')
      })
  },
})

export default withRouter(connect(null, mapDispatchToProps)(UpdatePasswordForm))
