import React from 'react';
import { Col } from 'reactstrap';
import { IS_USA_SITE } from '../../../constants';

export const PaymentPlanHeader = () => (
    <Col sm={12}>
        <h4 className="payment-list-head">1. Pick your first {IS_USA_SITE ? 'installment' : 'instalment'} date</h4>
        <p className="payment-list-sub-title">
            After today&apos;s deposit, your next payment will be processed on this date.
        </p>
    </Col>
);
