import { Box } from '@mui/material';
import moment from 'moment/moment';
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from '../../constants';
import AirportCityTypeahead from '../AirportCityTypeahead';
import BasicDateRangePicker from '../common/datepicker/MuiDateRangePicker';
import MuiSingleDatePicker from '../common/datepicker/MuiSingleDatePicker';
import DropdownPassengers from '../DropdownPassengers';

const SingleDestinationControls = ({
  auth,
  searchQuery,
  balance,
  updateLocation,
  setSearchQuery,
  onFlightSearch,
  setForm,
}) => {
  const flightsQuery = searchQuery.flights;
  const { isAuthenticated } = auth;

  const renderFlightPassengerForm = (query) => (
    <Box mt={['10px', '0px']}>
      <DropdownPassengers
        adults={query.adults}
        child={query.children}
        infants={query.infants}
        onChangeAdults={(e) => {
          setSearchQuery((prev) => ({
            ...prev,
            flights: {
              ...query,
              adults: e,
            },
          }));
          setForm((prev) => ({
            ...prev,
            adults: {
              isTouched: true,
            },
          }));
        }}
        onChangeChildren={(e) => {
          setSearchQuery((prev) => ({
            ...prev,
            flights: {
              ...query,
              children: e,
            },
          }));
        }}
        onChangeInfants={(e) => {
          setSearchQuery((prev) => ({
            ...prev,
            flights: {
              ...query,
              infants: e,
            },
          }));
        }}
      />
    </Box>
  );

  return (
    <div className='SearchForm__flights'>
      <Row>
        <Col>
          <div className='SearchForm__flightsSingleDestination'>
            <AirportCityTypeahead
              id='origin'
              placeholder='Add location'
              fieldName='origin'
              updateLocation={updateLocation}
              selected={flightsQuery.origin ? [flightsQuery.origin] : null}
              label='Leaving from'
            />
            <AirportCityTypeahead
              id='destination'
              fieldName='destination'
              placeholder='Add location'
              updateLocation={updateLocation}
              label='Going to'
              selected={flightsQuery.destination ? [flightsQuery.destination] : null}
            />
            {flightsQuery.type === 'return' && (
              <BasicDateRangePicker
                startDateLabel='Depart'
                endDateLabel='Return'
                startDate={flightsQuery.departureDate ? moment(flightsQuery.departureDate) : null}
                endDate={flightsQuery.returnDate ? moment(flightsQuery.returnDate) : null}
                handleDateChange={({ startDate, endDate }) => {
                  setSearchQuery((prev) => ({
                    ...prev,
                    flights: {
                      ...prev.flights,
                      departureDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                      returnDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
                    },
                  }));
                }}
                minimumDate={
                  isAuthenticated && balance > 0
                    ? moment().add('3', 'days')
                    : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, 'days')
                }
              />
            )}
            {flightsQuery.type === 'one-way' && (
              <MuiSingleDatePicker
                date={flightsQuery.departureDate ? moment(flightsQuery.departureDate) : null}
                label='Depart'
                handleDateChange={(startDate) => {
                  setSearchQuery((prev) => ({
                    ...prev,
                    flights: {
                      ...prev.flights,
                      departureDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                      returnDate: null,
                    },
                  }));
                }}
                minimumDate={
                  isAuthenticated && balance > 0
                    ? moment().add('3', 'days')
                    : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, 'days')
                }
              />
            )}
            {renderFlightPassengerForm(flightsQuery)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button onClick={onFlightSearch} type='button' className='SearchForm__submit btn-search'>
            Search flights
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SingleDestinationControls;
