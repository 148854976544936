import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { HotelContainer } from './Style';
import {
  CaptionTextSecondary,
  CurrencyText,
  HeadingTypography,
  PrimaryButton,
} from '../../common/CommonStyle';
import SlideCarouselWrapper from '../../common/SlideCarouselWrapper';
import DoneIcon from '@mui/icons-material/Done';
import { successTextColor } from '../../../constants/ColorConstants';
import { getCurrency } from '../../../utils/SiteUtils';
import { getRatingText } from './Util';
import Amount from '../../common/Amount';

const HotelCard = ({ propertyData, moveToStayDescription }) => {
  const interval = useRef(null);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('900'));

  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    interval.current = setTimeout(() => {
      if (imageIndex < propertyData.images.length - 1) {
        setImageIndex((prev) => prev + 1);
      } else {
        setImageIndex(0);
      }
    }, 2000);
    return () => clearTimeout(interval.current);
  }, [imageIndex]);

  return (
    <HotelContainer height={mobileView ? 'auto' : 190} container style={{ background: 'white' }}>
      <Grid
        style={{ background: '#efefef' }}
        display={'flex'}
        alignItems={'center'}
        item
        md={4}
        xs={12}
      >
        <SlideCarouselWrapper
          images={propertyData.images}
          additionalImgStyle={{
            height: 190,
          }}
        />
      </Grid>
      <Grid
        onClick={() => moveToStayDescription(propertyData.hotelId)}
        item
        md={8}
        xs={12}
        height={mobileView ? 'auto' : 190}
        style={{ padding: 20, cursor: 'pointer' }}
      >
        <Grid container justifyContent={'space-between'} spacing={'10px'}>
          <Grid item xs={12} md={8}>
            <HeadingTypography variant='h6' sx={{ fontWeight: { md: 'bold' } }}>
              {propertyData.hotelName}
            </HeadingTypography>
            <Typography mb={'10px'} fontWeight={'bold'} variant={'body2'}>
              {propertyData.region}
            </Typography>
            {propertyData.keyPolicies.map((item) => (
              <Typography color={successTextColor} key={item}>
                {item}
              </Typography>
            ))}
            <Grid marginTop={'10px'}>
              {propertyData.paymentPlanAvailable && (
                <CaptionTextSecondary
                  style={{ textAlign: 'justify', fontWeight: 600, color: successTextColor }}
                  mb={1}
                  variant='caption'
                  fontWeight={'bold'}
                >
                  <DoneIcon style={{ stroke: successTextColor, strokeWidth: 2 }} /> Reserve now, pay
                  later
                </CaptionTextSecondary>
              )}
            </Grid>
            <Grid marginTop={'10px'}>
              <Typography variant='body2'>
                {propertyData?.ratings?.guest?.overall && (
                  <b>
                    {propertyData.ratings.guest.overall}/5{' '}
                    {getRatingText(propertyData.ratings.guest.overall)}
                  </b>
                )}
                {' '}
                {propertyData?.ratings?.guest?.count &&
                  ` (${propertyData.ratings.guest.count} reviews)`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              style={{ paddingTop: 0 }}
            >
              <CaptionTextSecondary variant='caption'>Total</CaptionTextSecondary>
              <CurrencyText
                style={{ display: 'flex', alignItems: 'baseline', gap: 5, marginBottom: 0 }}
              >
                <Amount
                  price={propertyData?.chepestRoom?.total || 0}
                  currency={`${getCurrency()}D`}
                />
              </CurrencyText>
              {propertyData?.chepestRoom?.totalRooms > 1 && (
                <Typography mb={'5px'}>
                  for {`${propertyData.chepestRoom.totalRooms} rooms`}
                </Typography>
              )}
              <br />
              <PrimaryButton
                color={'primary'}
                onClick={(e) => {
                  moveToStayDescription(propertyData.hotelId);
                  e.stopPropagation();
                }}
              >
                View Details
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HotelContainer>
  );
};
export default HotelCard;
