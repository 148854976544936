import { createAction } from '../utils';
import internalApi from '../../config/internalApi';
import { repaymentApiUrl } from '../../constants/APIConstants';

const GET_PAYMENT_PLAN = 'plt-web/paymentPlan/GET_PAYMENT_PLAN';
const LOADING_PAYMENT_PLAN = 'plt-web/paymentPlan/LOADING_PAYMENT_PLAN';
const EMPTY_PAYMENT_PLAN = 'plt-web/paymentPlan/EMPTY_PAYMENT_PLAN';

const initialState = {
    data: {},
    loading: false
};

export default function reducer(state = initialState, { type, ...action }) {
    switch (type) {
        case LOADING_PAYMENT_PLAN: {
            return { data: state.data, loading: true };
        }
        case GET_PAYMENT_PLAN: {
            return { data: action.payload, loading: false };
        }
        case EMPTY_PAYMENT_PLAN: {
            return initialState;
        }
        default:
            return state;
    }
}

export const emptyPaymentPlan = () => ({
    type: EMPTY_PAYMENT_PLAN
});

export const getPaymentPlan = (params, auth) => (dispatch) => {
    // console.log(auth);
    dispatch(createAction(LOADING_PAYMENT_PLAN));
    return internalApi
        .post(repaymentApiUrl, params, { 'x-access-token': auth ? auth.token : undefined })
        .then((response) => {
            dispatch(createAction(GET_PAYMENT_PLAN, response));
            return response;
        })
        .catch((err) => {
            console.log(err);
            dispatch(createAction(GET_PAYMENT_PLAN, {}));
        });
};
