import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const RoomInformationModal = ({ notes, handleClose }) => (
    <Dialog maxWidth={'sm'} fullWidth open onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Room information
            {
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            }
        </DialogTitle>
        <DialogContent>
            {notes.length === 0 ? (
                <Typography
                    variant={'body2'}
                    dangerouslySetInnerHTML={{
                        __html: 'No Details found'
                    }}
                />
            ) : (
                notes.map((note, index) => (
                    <Typography
                        key={index}
                        variant={'body2'}
                        dangerouslySetInnerHTML={{
                            __html: note
                        }}
                    />
                ))
            )}
        </DialogContent>
    </Dialog>
);

export default RoomInformationModal;
