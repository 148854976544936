import { Popover, Typography, Grid, Button } from '@mui/material';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useBooleanState } from '../../../hooks/CommonHooks';
import { OptionTextColor, PrimaryButton } from '../../common/CommonStyle';
import RoomDetailCard from './RoomDetailCard';
import { checkRoomFieldValidation, getTotalTravelers } from './Util';

const SelectGuests = ({ roomDetails, setRoomDetails }) => {
    const openGuestPopOver = useBooleanState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleValueChange = (id, type, value) => {
        const copy = roomDetails;
        const filteredRoomIndex = roomDetails.findIndex((item) => item.id === id);
        const filteredRoom = roomDetails.find((item) => item.id === id);

        if (type === 'adults') {
            copy[filteredRoomIndex] = {
                ...filteredRoom,
                adult: value
            };
        }
        if (type === 'children') {
            copy[filteredRoomIndex] = {
                ...filteredRoom,
                children: value
            };
        }
        setRoomDetails(copy);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        openGuestPopOver.toggle();
    };

    const handleRoomRemove = (id) => {
        setRoomDetails(roomDetails.filter((item) => item.id !== id));
    };

    return (
        <>
            <OptionTextColor
                style={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: '54px' }}
                onClick={handleClick}
            >
                {roomDetails.length} Room{roomDetails.length > 1 && 's'}, {getTotalTravelers(roomDetails)} Guest
                {getTotalTravelers(roomDetails) > 1 && 's'}
                {!openGuestPopOver.value ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
            </OptionTextColor>
            <Popover
                open={openGuestPopOver.value}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                PaperProps={{
                    style: {
                        padding: 20,
                        width: 376
                    }
                }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        {getTotalTravelers(roomDetails) > 13 && (
                            <Typography textAlign={'center'} variant="body1">
                                For larger bookings, we recommend making multiple individual bookings.
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h6'} fontWeight={'bold'}>
                            Guests
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {roomDetails.map((item, index) => (
                            <Grid container key={JSON.stringify(item)} mt={'20px'}>
                                <Grid item xs={12}>
                                    <RoomDetailCard
                                        handleValueChange={handleValueChange}
                                        index={index}
                                        key={item.id}
                                        roomDetails={roomDetails}
                                        roomInfo={item}
                                    />
                                </Grid>
                                {index !== 0 && (
                                    <Grid container justifyContent={'flex-end'}>
                                        <Button
                                            style={{
                                                fontWeight: 'bold',
                                                textTransform: 'none'
                                            }}
                                            variant="text"
                                            onClick={() => handleRoomRemove(item.id)}
                                        >
                                            Remove room
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        ))}
                        {getTotalTravelers(roomDetails) < 14 && (
                            <Grid container justifyContent={'flex-end'}>
                                <Button
                                    variant="text"
                                    style={{
                                        fontWeight: 'bold',
                                        textTransform: 'none'
                                    }}
                                    onClick={() => {
                                        getTotalTravelers(roomDetails) < 14 &&
                                            setRoomDetails([
                                                ...roomDetails,
                                                {
                                                    id: Math.random(),
                                                    adult: 1,
                                                    children: []
                                                }
                                            ]);
                                    }}
                                >
                                    Add another room
                                </Button>
                            </Grid>
                        )}
                        <Grid container mt={'10px'} justifyContent={'flex-end'}>
                            <Grid item xs={12}>
                                <PrimaryButton
                                    style={{
                                        width: '100%'
                                    }}
                                    disabled={checkRoomFieldValidation(roomDetails)}
                                    onClick={() => openGuestPopOver.setFalse()}
                                >
                                    Done
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </>
    );
};

export default SelectGuests;
