import React, { useState } from "react";
import { getTrackBackground, Range } from "react-range";
import "../../styles/components/_input_range.scss";

const InputSlider = ({ min, max, value, step, onInput, onMouseDown, onTouchStart }) => {
    const [values, setValue] = useState([Math.max(min, value)]);
    const MIN = min;
    const STEP = step ?? 10;
    const MAX = max ?? MIN + 40;
    const thumbValue = `${values[0]}%`;

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                padding: "0 20px"
            }}
        >
            <Range
                values={values}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(values) => {
                    setValue(values);
                    onInput({ depositSliderValue: values[0] });
                }}
                onFinalChange={(values) => {
                    setValue(values);
                    onInput({ depositSliderValue: values[0] });
                }}
                renderTrack={({ props, children }) => (
                    <div
                        onMouseDown={onMouseDown}
                        onTouchStart={onTouchStart}
                        style={{
                            ...props.style,
                            height: "36px",
                            display: "flex",
                            width: "100%"
                        }}
                    >
                        <div
                            ref={props.ref}
                            style={{
                                height: "8px",
                                width: "100%",
                                borderRadius: "4px",
                                background: getTrackBackground({
                                    values: values,
                                    colors: ["#19b4d1", "#ccc"],
                                    min: MIN,
                                    max: MAX
                                }),
                                alignSelf: "center"
                            }}
                        >
                            {children}
                            <ul className="range-labels">
                                <li className="waterMark" />
                                <li className="waterMark" />
                                <li className="waterMark" />
                            </ul>
                        </div>
                    </div>
                )}
                renderThumb={({ props, isDragged }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: "44px",
                            width: "44px",
                            borderRadius: "100%",
                            backgroundColor: "#58A946",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 2px 6px #AAA",
                            zIndex: "1"
                        }}
                    >
                        <div className="thumb-container">{thumbValue}</div>
                    </div>
                )}
            />
        </div>
    );
};

export default InputSlider;
