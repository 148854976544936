import utils from './utils';

const KEY_PERSIST_STORE = 'fight-booking-app';
const API_URL = process.env.REACT_APP_API_URL;
const BASE_PATH = process.env.REACT_APP_BASE_PATH;
const RECORD_PER_PAGE = process.env.RECORD_PER_PAGE || 10;

export default {
  KEY_PERSIST_STORE,
  API_URL,
  BASE_PATH,
  RECORD_PER_PAGE,
  utils
};

export {
  KEY_PERSIST_STORE,
  API_URL,
  BASE_PATH,
  RECORD_PER_PAGE,
  utils
};
