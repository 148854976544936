import moment from 'moment';
import { useMemo } from 'react';

export const SearchInitialValues = (searchDetail) =>
    useMemo(
        () => ({
            location: searchDetail?.location || '',
            locationCode: searchDetail?.locationCode || '',
            departureDate: searchDetail ? moment(searchDetail.checkindate) : moment(new Date()),
            returnDate: searchDetail ? moment(searchDetail.checkoutdate) : moment(new Date()).add(1, 'days'),
            roomDetails: searchDetail?.roomDetails || [
                {
                    id: Math.random(),
                    adult: 2,
                    children: []
                }
            ]
        }),
        [searchDetail]
    );
