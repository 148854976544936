import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Button } from 'reactstrap';
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from '../../constants';
import AirportCityTypeahead from '../AirportCityTypeahead';
import MuiSingleDatePicker from '../common/datepicker/MuiSingleDatePicker';
import DropdownPassengers from '../DropdownPassengers';

const MultiCityControls = ({
  windowWidth,
  searchQuery,
  updateMultiCityLocation,
  removeMultiCityLeg,
  setSearchQuery,
  setForm,
  onFlightSearch,
  updateMultiCityDepartureDate,
  addMultiCityLeg,
}) => {
  const flightsQuery = searchQuery.flights;

  const renderFlightPassengerForm = (query) => (
    <DropdownPassengers
      adults={query.adults}
      child={query.children}
      infants={query.infants}
      onChangeAdults={(e) => {
        setSearchQuery((prev) => ({
          ...prev,
          flights: {
            ...query,
            adults: e,
          },
        }));
        setForm((prev) => ({
          ...prev,
          adults: {
            isTouched: true,
          },
        }));
      }}
      onChangeChildren={(e) => {
        setSearchQuery((prev) => ({
          ...prev,
          flights: {
            ...query,
            children: e,
          },
        }));
      }}
      onChangeInfants={(e) => {
        setSearchQuery((prev) => ({
          ...prev,
          flights: {
            ...query,
            infants: e,
          },
        }));
      }}
    />
  );

  return (
    <div className='SearchForm__multiCity'>
      {flightsQuery.multiCityLegs.map((leg, index) => {
        return (
          <div className='SearchForm__multiCity__leg' key={Math.random()}>
            <AirportCityTypeahead
              index={index}
              id={`multiCityOrigin_${index}`}
              fieldName='origin'
              placeholder={windowWidth < 768 ? 'Add' : 'Add location'}
              label={windowWidth < 768 ? 'From' : 'Leaving from'}
              updateLocation={updateMultiCityLocation}
              selected={leg.origin ? (windowWidth > 768 ? [leg.origin] : [leg.origin.id]) : null}
            />
            <AirportCityTypeahead
              index={index}
              id={`multiCityDestination_${index}`}
              fieldName='destination'
              placeholder={windowWidth < 768 ? 'Add' : 'Add location'}
              label={windowWidth < 768 ? 'To' : 'Going to'}
              updateLocation={updateMultiCityLocation}
              selected={
                leg.destination
                  ? windowWidth > 768
                    ? [leg.destination]
                    : [leg.destination.id]
                  : null
              }
            />
            <div style={{ flex: window.innerWidth < 768 ? 3 : 1 }}>
              <MuiSingleDatePicker
                date={leg.departureDate ? moment(leg.departureDate) : null}
                id={`multiCityDate_${index}`}
                label='Depart'
                handleDateChange={(startDate) => {
                  updateMultiCityDepartureDate(index, startDate);
                }}
                minimumDate={
                  index > 0 && flightsQuery.multiCityLegs[index - 1].departureDate
                    ? moment(flightsQuery.multiCityLegs[index - 1].departureDate)
                    : moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, 'days')
                }
              />
            </div>
            <div
              style={{
                flex: window.innerWidth < 768 ? 1 : 0,
                justifyContent: 'end',
                display: 'flex',
              }}
            >
              <FontAwesomeIcon
                icon='times'
                size='lg'
                className={flightsQuery.multiCityLegs.length < 3 ? 'disabled' : ''}
                onClick={() => removeMultiCityLeg(index)}
              />
            </div>
          </div>
        );
      })}

      {flightsQuery.multiCityLegs.length !== 5 && (
        <div>
          <a onClick={addMultiCityLeg} className='SearchForm__multiCityAddFlight'>
            + Add another flight
          </a>
        </div>
      )}
      <div className='SearchForm__multiCity__footer'>
        {renderFlightPassengerForm(flightsQuery)}
        <Button onClick={onFlightSearch} type='button' className='SearchForm__submit btn-search'>
          Search flights
        </Button>
      </div>
    </div>
  );
};

export default MultiCityControls;
