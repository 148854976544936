import { Popover, Typography } from '@mui/material';
import React from 'react';

const TaxAndEventPopup = ({ id, open, anchorEl, handleClose }) => (
    <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        PaperProps={{
            style: {
                padding: '20px',
                width: '300px'
            }
        }}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
        }}
    >
        <Typography variant={'body2'} mb={'10px'}>
            This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for
            their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to
            sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort
            fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on,
            retain as part of the compensation for our and/or their services, which varies based on factors such as
            location, the amount, and how you booked. For more details, please see the Terms and Conditions.
        </Typography>
    </Popover>
);

export default TaxAndEventPopup;
