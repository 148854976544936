import React from "react";
import { Grid } from "@mui/material";
import SearchLocationView from "./SearchLocationView";
import { useFormik } from "formik";
import moment from "moment";
import SelectGuests from "./SelectGuests";
import { StayValidation } from "./ValidationSchema";
import { useNavigate } from "react-router-dom";
import { prepareHotelNavigationData } from "./Util";
import { SearchInitialValues } from "./Hooks";
import { FormContainer } from "./Style";
import { message } from "antd";
import { PrimaryButton } from "../../common/CommonStyle";
import BasicDateRangePicker from "../../common/datepicker/MuiDateRangePicker";

const StaySearchForm = ({ search }) => {
    const navigate = useNavigate();

    const { setFieldValue, values, handleSubmit } = useFormik({
        initialValues: SearchInitialValues(search),
        validationSchema: StayValidation,
        onSubmit: (value) => {
            if (prepareHotelNavigationData(value)) {
                navigate(prepareHotelNavigationData(value));
                return;
            }
            message.info("Please give ages to all of your children");
        }
    });

    return (
        <FormContainer container>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <SearchLocationView
                        fromLocation={values.location}
                        setFromLocation={(locationText, locationCode) => {
                            setFieldValue("location", locationText);
                            setFieldValue("locationCode", locationCode);
                        }}
                    />
                    <Grid item xs={12} md={5}>
                        <BasicDateRangePicker
                            startDateLabel="Check-in"
                            endDateLabel="Check-out"
                            startDate={moment(values.departureDate)}
                            endDate={moment(values.returnDate)}
                            handleDateChange={({ startDate, endDate }) => {
                                setFieldValue("departureDate", startDate);
                                setFieldValue("returnDate", endDate);
                            }}
                            minimumDate={moment().add(0, "days")}
                        />
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                        <SelectGuests
                            roomDetails={values.roomDetails}
                            setRoomDetails={(roomInfo) => setFieldValue("roomDetails", roomInfo)}
                        />
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                        <PrimaryButton
                            onClick={() => handleSubmit()}
                            variant={"contained"}
                            color={"primary"}
                            fullWidth
                            style={{
                                height: 53,
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            Search
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
};

export default StaySearchForm;
