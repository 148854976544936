export const IS_USA_SITE = [
    'www.paylatertravel.com', // production
    'www.paylatertravel.dev', // development / test
    'www.staging.paylatertravel.dev', // staging
    'www.preprod.paylatertravel.dev', // preprod
    'localhost1'
].includes(window.location.hostname); // removed .replace("www.", "")
export const FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY = 42;
export const TRAVEL_INSURANCE_LINK_US =
    'https://www.agentmaxonline.com/agentmaxweb/storefront/index.html#/home/?emaillinkcode=ABIYU4TLWGBGTNHC6ZWLRSKAR7AIBWE33AAW7OYIPBPWYZZAHMNFAPHPMCRSOIHOT2A76BONGXKQL73NZ75RJQPPH5HHTT7WBY42JRA%3d';
export const TRAVEL_INSURANCE_LINK_AU =
    'https://www.agentmaxonline.com/agentmaxweb/storefront/index.html#/home/?emaillinkcode=ABIYU4TLWGBGTNHC6ZWLRSKAR7AIBWE33AAW7OYIPBPWYZZAHMNFAPHPMCRSOIHOT2A76BONGXKQL73NZ75RJQPPH5HHTT7WBY42JRA%3d';
export const HOTEL_LINK_US = 'https://www.booking.com/index.html?aid=1826066';
export const HOTEL_LINK_AU = 'https://www.booking.com/index.html?aid=1826066';
export const CAR_RENTAL_LINK_AU = 'https://www.driveaway.com.au/affiliates/1014025';
export const CAR_RENTAL_LINK_US = 'https://www.gotrentalcars.com/us-label/?agence_id=5890';
export const CHANGES_ELIGIBILITY_DATE = '2021-11-12';
export const CANCELLATIONS_ELIGIBILITY_DATE = '2021-12-03';
export const MAX_HOTEL_LIST = 30;
export const LOGIN_URL = 'plt-web/auth/LOGIN';
export const STAYS_2_0 = true;
export const STAYS_2_0_API = false;
export const EDIT_TRAVELLERS = false;
export const WISTIA_EMBED_ID = '96qxzhtajy';
export const STAYS_NEW_STICKER = true;
export const BOOKING_RECOMMENDED_STAYS_LIST = true;
export const MUI_LICENSE_KEY = '922af3b2e61bd63dbac6098f405590c3Tz02MTM0NyxFPTE3MDk3MDQ4MTcxNDUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
