import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { IS_USA_SITE } from '../../constants';
import { HomeHeroImageContainer } from '../../containers/homepage/Style';
import SearchForm from '../searchform/index';

const HomepageHeader = ({ selectedTab, selectedLocation, setSelectedLocation, setSelectedTab }) => (
  <>
    <Helmet>
      <title>Pay Later Travel | Book Now, Pay Later</title>
    </Helmet>
    <HomeHeroImageContainer
      bgimage={'assets/images/hero-images/palmtrees.jpg'}
      className='Home__hero'
    >
      <h1>{IS_USA_SITE ? 'Payment Plans For Flights' : 'Book Now, Pay Later '}</h1>
      {IS_USA_SITE ? (
        <p>Spread the cost over weekly or bi-weekly payments before you travel</p>
      ) : (
        <p>Spread the cost of travel over weekly or fortnightly payments.</p>
      )}
      <Container style={{ maxWidth: 1140 }}>
        <SearchForm
          selectedTab={selectedTab}
          selectedLocation={selectedLocation}
          resetSelectedTab={() => setSelectedTab(null)}
          resetSelectedLocation={() => setSelectedLocation(null)}
        />
      </Container>
    </HomeHeroImageContainer>
  </>
);

export default HomepageHeader;
