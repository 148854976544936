import { getHotelDetailApiUrl } from '../../../constants/APIConstants';
import { transformOccupanies } from '../result/Util';
import InternalApi from '../../../config/internalApi';
import { capitalizeFirstLetter } from '../../../utils/SiteUtils';
import { _url } from '../../../config/utils';

const flatten = (ary) => ary.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

const transformAttributes = (attributes) => {
  const attrArray = attributes.map((item) =>
    Object.entries(item).map(([key, value]) => ({
      label: capitalizeFirstLetter(key),
      value: value,
    })),
  );
  return flatten(attrArray);
};

const getPaymentTypeImageUrl = (image) => {
  if (image === 'American Express') {
    return _url('assets/icons/americanexpress.svg');
  }
  if (image === 'Diners Club') {
    return _url('assets/icons/dinnerclub.svg');
  }
  if (image === 'JCB International') {
    return _url('assets/icons/jcbpayment.svg');
  }
  if (image === 'Visa') {
    return _url('assets/icons/visa.svg');
  }
  if (image === 'Debit cards') {
    return _url('assets/icons/debitcard.svg');
  }
  return '';
};

const transformPaymentType = (paymentType) =>
  Object.values(paymentType).map((item) => ({
    id: item.id,
    icon: getPaymentTypeImageUrl(item.name),
    name: item.name,
  }));

const transformFees = (fees) => {
  const feesArray = Object.entries(fees).map(([key, value]) => ({
    label: capitalizeFirstLetter(key),
    value: value,
  }));
  return feesArray;
};

const transformData = (data) => ({
  images: data.images.map((item, index) => ({
    id: index,
    imageType: item.type,
    imageUrl:
      item?.links['1000px']?.href || item?.links['350px']?.href || item?.links['70px']?.href || '',
  })),
  hotelName: data.name,
  direction: {
    latitude: data.address.latitude,
    longitude: data.address.longitude,
  },
  ratings: data.ratings,
  fullAddress: {
    city: data.address.city,
    state: data.address.state_province_name,
    streetAddress: data.address.line_1,
    zipCode: data.address.postal_code,
    country: data.address.country_code,
  },
  onSitePayments: {
    currency: data.onsite_payments.currency,
    types: transformPaymentType(data.onsite_payments.types),
  },
  checkin: {
    beginTime: data.checkin.begin_time,
    endTime: data.checkin.end_time,
    instructions: data.checkin.instructions,
    minAge: data.checkin.min_age,
    specialInstructions: data.checkin.special_instructions,
  },
  attributes: transformAttributes(data.attributes),
  checkout: {
    time: data.checkout.time,
  },
  amenities: data.amenities,
  fees: transformFees(data.fees),
  policies: data.policies.know_before_you_go,
  rooms: data.rooms.map((item) => ({
    amenities: item.amenities,
    name: item.name,
    freeWifi: item.free_wifi,
    propertyCollect: !!item.rates.find((rate) => rate.property_collect),
    sleepCount: item.sleep_count,
    bedDescription: item.bed_description,
    rates_extras: item.rates_extras.map((rate) => ({
      name: rate.name,
      rate_id: rate.rate_id,
    })),
    rates: item.rates.map((rate) => ({
      rateId: rate.id,
      rateToken: rate.bed_groups[0].token,
      refundable: rate.refundable,
      propertyCollect: rate.property_collect,
      cancellationPolicy: rate.cancellation_policy,
      deposits: rate.deposits,
      fees: {
        baseFare: rate.fees.base_fare,
        taxes: rate.fees.taxes,
        propertyFees: rate.fees.property_fees,
        extraPersonFees: rate.fees.extra_person_fee,
        totalSalesTax: rate.fees.total_sales_tax,
        localCurrency: rate.fees.local_currency,
        total: {
          exclusive: rate.fees.total.exclusive,
          inclusive: rate.fees.total.inclusive,
          currency: rate.fees.total.currency,
          roomPrices: rate.fees.room_prices.map((price) => ({
            baseFare: price.base_fare,
            taxes: price.taxes,
            total: price.total.inclusive,
          })),
        },
      },
    })),
    area: {
      sqFeet: item.area.square_feet,
      sqMeters: item.area.square_meters,
    },
    roomId: item.room_id,
    images: item.images.map((image, index) => ({
      id: index,
      imageType: image.type,
      url:
        image?.links['350px']?.href ||
        image?.links['200px']?.href ||
        image?.links['70px']?.href ||
        image?.links['1000px']?.href ||
        '',
    })),
  })),
});

export const getHotelDetails = (value, successCallBack, errorCallBack) =>
  InternalApi.post(getHotelDetailApiUrl(value.hotelId), {
    checkin: value.checkInDate,
    checkout: value.checkOutDate,
    occupanies: transformOccupanies(JSON.parse(value.roomInfo)),
  })
    .then((res) => successCallBack(transformData(res)))
    .catch((err) => errorCallBack(err));
