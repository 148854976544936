export const GuestAgeOptions = [
  {
    singleTitle: 'Adult',
    multiTitle: 'Adults',
    zeroTitle: 'Adult',
    value: 'adults',
    age: '18+',
  },
  {
    singleTitle: 'Child',
    multiTitle: 'Children',
    zeroTitle: 'Children',
    age: '0-17',
    value: 'children',
  },
];

export const TitleOptions = [
  {
    value: 'Mr.',
    text: 'Mr',
  },
  {
    value: 'Ms.',
    text: 'Ms',
  },
];

export const FlightTitleOptions = [
  {
    value: 'Mr',
    text: 'Mr.',
  },
  {
    value: 'Mrs',
    text: 'Mrs.',
  },
  {
    value: 'Miss',
    text: 'Miss.',
  },
  {
    value: 'Ms',
    text: 'Ms.',
  },
  {
    value: 'Master',
    text: 'Master.',
  },
];

export const GenderOptions = [
  {
    value: 'm',
    text: 'Male',
  },
  {
    value: 'f',
    text: 'Female',
  },
];

export const RatingOptions = [
  {
    value: 5,
  },
  {
    value: 4,
  },
  {
    value: 3,
  },
  {
    value: 2,
  },
  {
    value: 1,
  },
];

export const StaySortOptions = [
  {
    value: 'RECOMMENDED',
    label: 'Recommended',
  },
  {
    value: 'PRICE_LOW_TO_HIGH',
    label: 'Price Low to High',
  },
  {
    value: 'PRICE_HIGH_TO_LOW',
    label: 'Price High to Low',
  },
  // {
  //     value: "RATING_HIGH_TO_LOW",
  //     label: "Rating High to Low"
  // },
  // {
  //     value: "RATING_LOW_TO_HIGH",
  //     label: "Rating Low to High"
  // }
];

export const AgeOptions = [
  {
    value: 1,
    label: 'Below 2',
  },
  {
    value: 2,
    label: '2 years old',
  },
  {
    value: 3,
    label: '3 years old',
  },
  {
    value: 4,
    label: '4 years old',
  },
  {
    value: 5,
    label: '5 years old',
  },
  {
    value: 6,
    label: '6 years old',
  },
  {
    value: 7,
    label: '7 years old',
  },
  {
    value: 8,
    label: '8 years old',
  },
  {
    value: 9,
    label: '9 years old',
  },
  {
    value: 10,
    label: '10 years old',
  },
  {
    value: 11,
    label: '11 years old',
  },
  {
    value: 12,
    label: '12 years old',
  },
  {
    value: 13,
    label: '13 years old',
  },
  {
    value: 14,
    label: '14 years old',
  },
  {
    value: 15,
    label: '15 years old',
  },
  {
    value: 16,
    label: '16 years old',
  },
  {
    value: 17,
    label: '17 years old',
  },
];

export const FlightTnCOptions = [
  {
    value: 'au_term',
    label: 'AU Terms',
  },
  {
    value: 'us_term',
    label: 'US Terms',
  },
  {
    value: 'au_policy',
    label: 'AU Policy',
  },
  {
    value: 'us_policy',
    label: 'US Policy',
  },
];

export const SiteOptions = [
  {
    name: 'AU',
    value: 'AU',
  },
  {
    name: 'US',
    value: 'US',
  },
];

export const RefundableOption = [
  {
    value: 'refundable',
    name: 'Refundable',
  },
  {
    value: 'nonRefundable',
    name: 'Non-Refundable',
  },
];
