import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'reactstrap';
import message from 'antd/es/message';
import { loginUser } from '../redux/modules/auth';
import internalApi from '../config/internalApi';
import { getReferrals } from '../redux/modules/referrals';
import { withRouter } from '../hooks/CommonHooks';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      showForgotPasswordForm: false
    };
  }

    handleChange = (event) => {
      this.setState({
        [event.target.name]: event.target.value
      });
    }

    handleSubmit = (event) => {
      event.preventDefault();
      this.props.loginUser({
        email: this.state.email,
        password: this.state.password,
      });
    }

    sendPasswordReset = (event) => {
      event.preventDefault();
      if (this.state.email !== '') {
        internalApi.post('common/forgot-password', { email: this.state.email }).then((res) => {
          message.success('Password reset link has been sent to your email. Please check your junk mail and promotions.');
          this.setState({
            showForgotPasswordForm: false
          });
        }).catch((err) => {
          // console.log(err)
        });
      }
    }

    render() {
      return (
        <div>
          {!this.state.showForgotPasswordForm && (
          <Form className="login-form" onSubmit={this.handleSubmit}>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <Button block>Continue</Button>
            <div className="login-form__action-links">
              <div>
                <a onClick={() => this.setState({
                  showForgotPasswordForm: true
                })}
                >
                  Forgot password?
                </a>
              </div>
              <div>
                Don&apos;t have an account? Book your first trip!
              </div>
            </div>
          </Form>
          )}
          {this.state.showForgotPasswordForm && (
          <Form className="login-form login-form__forgot-password" onSubmit={this.sendPasswordReset}>
            <p>Enter the email address associated with your account and we’ll email you a link to reset your password.</p>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="Email address"
              value={this.state.email}
              onChange={this.handleChange}
            />
            <Button block>Send reset link</Button>
            <div className="login-form__action-links">
              <div>
                <a onClick={() => this.setState({
                  showForgotPasswordForm: false
                })}
                >
                  Back to login
                </a>
              </div>
            </div>
          </Form>
          )}

          {/* <p>Don't have an account? Book your first trip!</p> */}
        </div>
      );
    }
}

// const mapStateToprops = state => {
//     return {
//         searchData: state.search,
//         searchResult: state.searchFlight
//     }
// };

const mapDispatchToProps = (dispatch, ownProps) => ({
  loginUser: (params) => {
    dispatch(loginUser(params)).then((res) => {
      console.log(ownProps.router.location);
      if (window.location.pathname === '/login') {
        ownProps.router.navigate('/bookings');
      }
      // get referrals balance
      dispatch(getReferrals('flight'));
    }).catch((err) => {
      // console.log("error: " + err);
      message.error('Wrong email or password');
    });
  }
});

export default withRouter(connect(null, mapDispatchToProps)(LoginForm));
