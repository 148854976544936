import React, { useState } from 'react';
import { ReadMoreLink, ReadMoreText } from './CommonStyle';

const ToggleTextWrapper = ({ children, showReadMoreLess, boxHeight, readLessText, readMoreText }) => {
    const [showMoreLess, setShowMoreLess] = useState(false);

    return (
        <ReadMoreText boxHeight={boxHeight} isexpand={showMoreLess}>
            {children}
            {showReadMoreLess && (
                <ReadMoreLink onClick={() => setShowMoreLess(!showMoreLess)}>
                    {showMoreLess ? readLessText || 'Read Less' : readMoreText || 'Read More'}
                </ReadMoreLink>
            )}
        </ReadMoreText>
    );
};

export default ToggleTextWrapper;
