import React from 'react';
import { CardContainer, CardTitle } from './CommonStyle';

const CardContainerView = ({ title, children }) => (
    <CardContainer>
        {title && <CardTitle>{title}</CardTitle>}
        {children}
    </CardContainer>
);

export default CardContainerView;
