import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { _url } from "../../config/utils";
import { IS_USA_SITE } from "../../constants";
import { FacebookWrapper, HomeTestimonialCard, HomeTestimonialCol } from "../../containers/homepage/Style";
import { scrollToHome } from "../../containers/homepage/Util";
import Emoji from "../Emoji";
import Carousel from "react-multi-carousel";

const HomeTestimonial = ({ showUSASite, setSelectedTab }) => {
    if (IS_USA_SITE && showUSASite) {
        return (
            <div className="HomeTestimonials__us">
                <Container>
                    <h2 className="text-center" style={{ marginBottom: 40 }}>
                        Our happy customers
                    </h2>
                    <Carousel
                        className="HomeTestimonials__carousel"
                        responsive={{
                            mobile: {
                                breakpoint: { max: 464, min: 0 },
                                items: 1
                            },
                            tablet: {
                                breakpoint: { max: 1024, min: 464 },
                                items: 1
                            },
                            desktop: {
                                breakpoint: { max: 3000, min: 1024 },
                                items: 2
                            }
                        }}
                    >
                        <div className="HomeTestimonials__card">
                            <p>Kate S. - June 2022</p>
                            <p className="HomeTestimonials__location">Long Island, New York State</p>
                            <p className="HomeTestimonials__cardContent">
                                &quot;What isn’t there to say about this wonderful company! I booked my Christmas
                                flights and paid them off over a 6 month period. So much easier than having the stress
                                of paying for it all upfront.&quot;
                            </p>
                            <img
                                alt="5 stars"
                                src={`${_url("assets/images/5stars.png")}`}
                                style={{ width: 100, marginTop: 20 }}
                            />
                        </div>
                        <div className="HomeTestimonials__card">
                            <p>Don K. - April 2022</p>
                            <p className="HomeTestimonials__location">Los Angeles, California </p>
                            <p className="HomeTestimonials__cardContent">
                                &quot;At first, I was skeptical about PayLater as the service seemed too good to be true
                                but I decided to give it a go. This company made it very easy for me to pay for my
                                flights and I’ll definitely be coming back!!&quot;
                            </p>
                            <img
                                alt="5 stars"
                                src={`${_url("assets/images/5stars.png")}`}
                                style={{ width: 100, marginTop: 20 }}
                            />
                        </div>
                        <div className="HomeTestimonials__card">
                            <p>Angela M. - May 2022</p>
                            <p className="HomeTestimonials__location">Boston, Massachusetts</p>
                            <p className="HomeTestimonials__cardContent">
                                &quot;This has enabled me to pay my airfares off for my vacation without stressing if I
                                was going to have the money saved in time. The repayments are barely noticeable from my
                                wage each week.&quot;
                            </p>
                            <img
                                alt="5 stars"
                                src={`${_url("assets/images/5stars.png")}`}
                                style={{ width: 100, marginTop: 20 }}
                            />
                        </div>
                        <div className="HomeTestimonials__card">
                            <p>Kyra B. - July 2022</p>
                            <p className="HomeTestimonials__location">San Diego, California </p>
                            <p className="HomeTestimonials__cardContent">
                                &quot;PayLater is super helpful for the big families who want to go on vacation but find
                                it really hard financially. They make it super affordable with easy installments and no
                                interest like most other companies.&quot;
                            </p>
                            <img
                                alt="5 stars"
                                src={`${_url("assets/images/5stars.png")}`}
                                style={{ width: 100, marginTop: 20 }}
                            />
                        </div>
                        <div className="HomeTestimonials__card">
                            <p>Leanne T. - June 2022</p>
                            <p className="HomeTestimonials__location">Detroit, Michigan</p>
                            <p className="HomeTestimonials__cardContent">
                                &quot;I’m a bit of a goal driven person, so having the ticket booked and paying off each
                                week keeps me inspired to save money! Very happy customer
                                <Emoji symbol="😀" label="happy" />
                                &quot;
                            </p>
                            <img
                                alt="5 stars"
                                src={`${_url("assets/images/5stars.png")}`}
                                style={{ width: 100, marginTop: 20 }}
                            />
                        </div>
                        <div className="HomeTestimonials__card">
                            <p>Steph B. - October 2022</p>
                            <p className="HomeTestimonials__location">Seattle, Washington State</p>
                            <p className="HomeTestimonials__cardContent">
                                &quot;So easy to navigate. The team is very helpful and responded in a timely manner. I
                                would book my flights through them again.&quot;
                            </p>
                            <img
                                alt="5 stars"
                                src={`${_url("assets/images/5stars.png")}`}
                                style={{ width: 100, marginTop: 20 }}
                            />
                        </div>
                        <HomeTestimonialCard className="HomeTestimonials__card">
                            <a href="https://www.facebook.com/paylatertravel/reviews/" target="_blank" rel="noreferrer">
                                Read more reviews on Facebook
                            </a>
                        </HomeTestimonialCard>
                    </Carousel>
                    <FacebookWrapper className="find-us-on-facebook-wrapper text-center">
                        <a href="https://www.facebook.com/paylatertravel/reviews/" target="_blank" rel="noreferrer">
                            <img
                                className="find-us-on-facebook"
                                alt="Facebook Review - 4.8 out of 5"
                                src={`${_url("assets/images/findusonfb.png")}`}
                                style={{ width: 180, marginRight: 20 }}
                            />
                            <img
                                alt="Facebook Review - 4.8 out of 5"
                                src={`${_url("assets/images/4point8outof5.png")}`}
                                style={{ width: 140 }}
                            />
                        </a>
                    </FacebookWrapper>
                </Container>
            </div>
        );
    }
    if (!IS_USA_SITE && !showUSASite) {
        return (
            <div className="HomeTestimonials">
                <Container>
                    <Row className="align-items-center">
                        <HomeTestimonialCol md={{ size: 8 }}>
                            <div className="HomeTestimonials__card">
                                <p>David - June 2022</p>
                                <p className="HomeTestimonials__cardContent">
                                    &quot;I don&apos;t have a lot of free cash, so Pay Later opened the entire world up
                                    for us to visit without just dreaming about it.&quot;
                                </p>
                            </div>
                            <div className="HomeTestimonials__card">
                                <p>Angela - May 2022</p>
                                <p className="HomeTestimonials__cardContent">
                                    &quot;This has enabled me to pay my airfares off for my holiday without stressing if
                                    I was going to have the money saved in time. The repayments are barely noticeable
                                    from my wage each week.&quot;
                                </p>
                            </div>
                            <div className="HomeTestimonials__card">
                                <p>Leanne - June 2022</p>
                                <p className="HomeTestimonials__cardContent">
                                    &quot;I’m a bit of a goal driven person, so having the ticket booked and paying off
                                    each week keeps me inspired to save money! Very happy customer
                                    <Emoji symbol="😀" label="happy" />
                                    &quot;
                                </p>
                            </div>
                        </HomeTestimonialCol>
                        <Col md={{ size: 3, offset: 1 }}>
                            <h4>Testimonials</h4>
                            <h2>Our happy customers</h2>
                            <p>Powered by Delighted.com (over 900 independent, verified reviews)</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button
                                color="primary"
                                onClick={() => {
                                    setSelectedTab(1);
                                    scrollToHome();
                                }}
                            >
                                Search flights
                            </Button>
                            <p style={{ marginTop: "20px" }}>Your getaway is right around the corner!</p>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    return null;
};
export default HomeTestimonial;
