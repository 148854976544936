import React, { Component } from 'react';
import { connect } from 'react-redux';
import message from 'antd/es/message';
import { Button } from '.';
import internalApi from '../config/internalApi';
import { Alert, ModalHeader, ModalBody } from 'reactstrap';
import { withRouter } from '../hooks/CommonHooks';

class CancelBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitLoading: false,
            cardId: null,
            loading: false,
            cancellationFees: {
                totalRefund: null,
                lines: []
            }
        };
    }

    componentDidMount() {
        this.setState({
            loading: true,
            cardId: this.props.booking.paymentSchedule.card.id
        });

        // get cancellation fees
        internalApi
            .get(
                `v2/customer/me/booking/${this.props.reference}/cancellation-fees`,
                {},
                {
                    'x-access-token': this.props.auth.token
                }
            )
            .then((res) => {
                this.setState({
                    cancellationFees: res,
                    loading: false
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false
                });
            });
    }

    onSubmit = () => {
        this.setState({ submitLoading: true });
        internalApi
            .post(
                `v2/customer/me/booking/${this.props.reference}/cancel`,
                {
                    cardId: this.state.cardId
                },
                { 'x-access-token': this.props.auth.token }
            )
            .then((res) => {
                const text = res.booking
                    ? `Booking no. ${this.props.reference} was successfully cancelled`
                    : 'Your cancellation request has been sent';
                message.success(text);
                this.setState({ submitLoading: false });
                this.props.router.navigate('/bookings');
            })
            .catch((err) => {
                message.error(err.message || 'An error occured');
                this.setState({ submitLoading: false });
            });
    };

    render() {
        const { loading, cancellationFees } = this.state;
        let totalCancellationFees = 0;
        if (cancellationFees && cancellationFees.lineItems && cancellationFees.lineItems.length > 0) {
            totalCancellationFees = cancellationFees.lineItems.reduce(
                (total, el) => (el.name.includes('Cancellation Fee') ? total + el.amount : total),
                0
            );
        }

        const nonRefundableOptions = this.state.cancellationFees.totalUnderlyingRefund < 0;

        return (
            <>
                {cancellationFees.coolingOff ? (
                    <ModalHeader toggle={this.props.onToggle}>
                        Cancel booking
                        <div className="CancelBooking_secondary_success">
                            Cooling off period: Free cancelation for 24 hours
                        </div>
                    </ModalHeader>
                ) : (
                    <ModalHeader toggle={this.props.onToggle}>Cancel booking</ModalHeader>
                )}
                <ModalBody>
                    <div className="CancelBooking">
                        {loading && <div className="LoadingDots LoadingDots--dark" />}
                        {!loading &&
                            (nonRefundableOptions ? (
                                <div className="CancelBooking__nonRefundableOptions">
                                    <Alert color="danger" fade={false}>
                                        Unfortunately as per the airline’s fare rules, cancelation is not permitted and
                                        fares are non-refundable.{' '}
                                    </Alert>
                                    <h5>Option 1: Change flights</h5>
                                    <p>
                                        If you have other travel dates in mind, the easiest option is to change flights
                                        via our portal.
                                    </p>
                                    <h5>
                                        Option 2 (recommended): Convert your ticket to Airline Credit once you complete
                                        your payment schedule.
                                    </h5>
                                    <p>
                                        Airline Credit Vouchers retain the full value of your booking and can be used in
                                        the future. If you decide to proceed with this option, please contact us upon
                                        completing your payment schedule.
                                    </p>
                                    <p>If you have any questions about your options, please contact us via chat.</p>
                                </div>
                            ) : (
                                <div>
                                    <div>
                                        <p>
                                            Depending on the airline fare rules of your booking, cancelation fees may
                                            apply.
                                        </p>
                                        {this.state.cancellationFees && (
                                            <div>
                                                <table className="FeeTable">
                                                    <tbody>
                                                        <tr>
                                                            <td>Total Paid</td>
                                                            <td>
                                                                $
                                                                {parseFloat(
                                                                    this.state.cancellationFees.totalPaid / 100
                                                                ).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            style={
                                                                totalCancellationFees
                                                                    ? { color: 'rgba (0, 0, 0, 0.65);' }
                                                                    : { color: '#58a946' }
                                                            }
                                                        >
                                                            <td>Cancelation Fee</td>
                                                            <td>
                                                                ${parseFloat(totalCancellationFees / 100).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        <tr className="table-footer" style={{ color: '#58a946' }}>
                                                            <td>Total Refund (AUD)</td>
                                                            <td>
                                                                $
                                                                {parseFloat(cancellationFees.totalRefund / 100).toFixed(
                                                                    2
                                                                )}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        <p className="CancelBooking__confirmButtonTerms">
                                            Once you&apos;ve chosen to cancel your booking, it will be finalised and
                                            cannot be reversed. Your refund will be issued back to the original form of
                                            payment once we have received funds from the airline. Please note, this may
                                            take up to 12 weeks.
                                        </p>
                                    </div>

                                    <Button
                                        className="CancelBooking__confirmButton"
                                        color="primary"
                                        onConfirm={this.onSubmit}
                                        loading={this.state.submitLoading}
                                        icon="lock"
                                        block
                                        confirm
                                    >
                                        {this.state.cancellationFees.lines &&
                                        this.state.cancellationFees.lines.length > 0
                                            ? 'Confirm cancelation'
                                            : 'Request cancelation'}
                                    </Button>
                                </div>
                            ))}
                    </div>
                </ModalBody>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    booking: state.bookings.detail,
    auth: state.auth,
    cards: state.cards
});

export default withRouter(connect(mapStateToProps, null)(CancelBooking));
