import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropertyDetailOverview from './PropertyDetailOverview';
import PropertyImageList from './PropertyImage';
import PropertyRoomView from './PropertyRoomView';
import { getHotelDetails } from './Util';
import { FilterContainer } from '../result/Style';
import StaySearchForm from '../searchform/StaySearchForm';
import { useNavigate } from 'react-router-dom';
import ShimmerRows from '../../common/ShimmerRows';
import { getDiffDays } from '../../../utils/SiteUtils';
import PropertyDetailNavigationButton from './PropertyDetailNavigationButton';
import PropertyPolicySection from './PropertyPolicySection';
import PropertyImportantInfoSection from './PropertyImportantInfoSection';

const PropertyDetailView = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const [hotels, setHotels] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const [stayInterval, setStayInterval] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setStayInterval(getDiffDays(searchParams.get('checkindate'), searchParams.get('checkoutdate')));
        getHotelDetails(
            {
                hotelId: searchParams.get('hotelId'),
                locationCode: searchParams.get('locationCode'),
                checkInDate: searchParams.get('checkindate'),
                checkOutDate: searchParams.get('checkoutdate'),
                roomInfo: searchParams.get('roominfo')
            },
            (res) => {
                setHotels(res);
                setIsLoading(false);
            },
            () => {
                setHotels(null);
                setIsLoading(false);
                setIsError(true);
            }
        );
    }, []);

    if (isLoading) {
        return (
            <Grid container justifyContent="center">
                <Grid item xs={8}>
                    <ShimmerRows count={4} />
                </Grid>
            </Grid>
        );
    }
    if (isError) {
        return (
            <Grid container justifyContent="center">
                <Grid item>
                    <Typography variant="h5">Something Went wrong please search again</Typography>
                </Grid>
            </Grid>
        );
    }

    const handleRoomSelect = (roomTypeCode) => {
        console.log('handleRoomSelect', roomTypeCode);
        navigate(
            `/stays/checkout${window.location.search}&roomid=${roomTypeCode.roomId}&rateid=${roomTypeCode.rateId}&reserveType=${roomTypeCode.reserveType}&ratetoken=${roomTypeCode.rateToken}&propertycollect=${roomTypeCode.propertycollect}`
        );
    };
    return (
        <div>
            <FilterContainer>
                <Grid container maxWidth={'1355px'} justifyContent={'center'}>
                    <Grid className={'SearchForm'} item xs={12} md={10}>
                        <StaySearchForm
                            search={{
                                roomDetails: JSON.parse(searchParams.get('roominfo')),
                                location: searchParams.get('location'),
                                locationCode: searchParams.get('locationCode'),
                                checkindate: searchParams.get('checkindate'),
                                checkoutdate: searchParams.get('checkoutdate')
                            }}
                        />
                    </Grid>
                </Grid>
            </FilterContainer>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} style={{ maxWidth: '75rem' }}>
                    <Grid container spacing={3} style={{ padding: 20 }}>
                        <Grid item xs={12}>
                            <PropertyImageList propertyImage={hotels.images} />
                        </Grid>
                        <Grid item xs={12}>
                            <PropertyDetailNavigationButton />
                        </Grid>
                        <Grid item xs={12} id={'overview'}>
                            <PropertyDetailOverview propertyDetail={hotels} />
                        </Grid>
                        <Grid item xs={12} id={'room'}>
                            <PropertyRoomView handleRoomSelect={handleRoomSelect} rooms={hotels.rooms} />
                        </Grid>
                        <Grid item xs={12} id={'policies'}>
                            <PropertyPolicySection policy={hotels} />
                        </Grid>
                        <Grid item xs={12}>
                            <PropertyImportantInfoSection hotel={hotels} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default PropertyDetailView;
