import CreditCardIcon from '@mui/icons-material/CreditCard'
import { Grid, InputAdornment } from '@mui/material'
import CCExpDate from './CCExpDate'
import CreditCardNumberFormat from './CreditCardNumberFormat'
import CVVFormat from './CVVFormat'
import { CardElement, CardTextfIeld } from './Style'

const CardDetail = ({ cardDetails, setCardDetails }) => (
  <div>
    <CardElement container justifyContent={'space-between'}>
      <Grid item xs={6} md={9}>
        <CardTextfIeld
          value={cardDetails.cardNumber}
          variant={'outlined'}
          placeholder={'Card number'}
          fullWidth
          onChange={({ target }) => {
            setCardDetails((prev) => ({
              ...prev,
              cardNumber: target.value,
            }))
          }}
          InputProps={{
            inputComponent: CreditCardNumberFormat,
            startAdornment: (
              <InputAdornment position='start'>
                <CreditCardIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Grid container>
          <Grid item xs={6}>
            <CardTextfIeld
              value={cardDetails.expiryDate}
              variant={'outlined'}
              onChange={({ target }) => {
                setCardDetails((prev) => ({
                  ...prev,
                  expiryDate: target.value,
                }))
              }}
              InputProps={{
                inputComponent: CCExpDate,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CardTextfIeld
              value={cardDetails.cvv}
              variant={'outlined'}
              placeholder={'CVV'}
              onChange={({ target }) =>
                setCardDetails((prev) => ({
                  ...prev,
                  cvv: target.value,
                }))
              }
              InputProps={{
                inputComponent: CVVFormat,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </CardElement>
  </div>
)

export default CardDetail
