import React from 'react';
import { ModalBody, ModalHeader } from 'reactstrap';
import LoginForm from '../LoginForm';
import { StyledModal } from './Style';

const LoginModal = ({ toggleLoginModal }) => {
  return (
    <StyledModal isOpen toggle={toggleLoginModal} centered className='login-modal'>
      <ModalHeader toggle={toggleLoginModal}>Welcome back!</ModalHeader>
      <ModalBody>
        <LoginForm />
      </ModalBody>
    </StyledModal>
  );
};

export default LoginModal;
