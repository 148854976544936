import Styled from "styled-components";
import { Grid } from "@mui/material";

export const FormContainer = Styled(Grid)`
    @media only screen and (max-width: 900px) and (min-width: 768px) {
        padding-bottom: 20px !important;
    }
`;

export const SearchLocationContainer = Styled(Grid)`
    & .MuiInputBase-root {
        background: #fff !important;
    }
    & .MuiInputAdornment-root {
        position: absolute;
        right: 0;
        background: white;
    }
    & .MuiButtonBase-root {
        &:hover {
            box-shadow: none !important;
        }
    }
    
    & .rbt-close-content {
        font-size: 25px;
        font-weight: bold;
    }
`;
