import InternalApi from '../config/internalApi';
import { getFlightChangePriceUrl } from '../constants/APIConstants';

const getDestinationCityNameFromProduct = (product = {}) => {
  if (!product.itineraries) return '';
  
  const firstItinerary = product.itineraries[0];
  const firstItineraryLastSegment =
  firstItinerary.segments[firstItinerary.segments.length - 1];
  return firstItineraryLastSegment.arrivalLocation || '';
};

export default getDestinationCityNameFromProduct;

export const fetchChangeFlight = (reference, selectedFlight, token) =>
    InternalApi.get(
        getFlightChangePriceUrl(reference),
        {
            searchGuid: selectedFlight.searchGuid,
            resultGuid: selectedFlight.guid
        },
        { 'x-access-token': token }
    ).then((res) => ({
        confirmFlyOverOpen: true,
        changeFees: {
            ...res,
            loading: false
        }
    }));

export const onSubmitChangeFlight = (match, { searchGuid, guid }, paymentMethodId, authToken) =>
    InternalApi.post(
        `v2/customer/me/booking/${match.params.reference}/flight-change`,
        {
            searchGuid,
            resultGuid: guid,
            paymentMethodId
        },
        { 'x-access-token': authToken }
    ).then((res) => {
        const text = res.insertedProductFlight
            ? 'Hooray! Your flight changes have been confirmed. Please check your email.'
            : 'Your change request has been sent';
        return text;
    });

    
export const thirdPartyAgreements = {
    DL: 'https://www.delta.com/us/en/legal/terms-of-use',
    AA: 'https://www.americanairlines.com.au/i18n/customer-service/support/conditions-of-carriage.jsp',
    UA: 'https://www.united.com/en/us/fly/contract-of-carriage.html',
    B6: 'https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf',
    F9: 'https://www.flyfrontier.com/legal/',
    NK: 'https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf',
    HA: 'https://www.hawaiianairlines.com.au/legal/international-contract-of-carriage',
    AC: 'https://www.aircanada.com/ca/en/aco/home/legal/conditions-carriage-tariffs.html',
    AS: 'https://www.alaskaair.com/content/legal/contract-of-carriage?lid=footer:contractCarriage',
    QR: 'https://www.qatarairways.com/en-us/legal/conditions-of-carriage.html',
    AM: 'https://aeromexico.com/en-us/legal-information',
    TK: 'https://www.turkishairlines.com/en-int/legal-notice/general-conditions-of-carriage/',
    EK: 'https://www.emirates.com/us/english/information/terms-and-conditions/',
    SY: 'https://suncountry.com/terms-and-conditions',
    LH: 'https://www.lufthansa.com/xx/en/business-terms-and-conditions-1',
    CM: 'https://www.copaair.com/en/web/us/contract-of-carriage',
    TP: 'https://www.flytap.com/en-us/transport-conditions',
    BA: 'https://www.britishairways.com/en-us/information/legal/conditions-of-carriage',
    AF: 'https://wwws.airfrance.fr/en/information/legal/edito-cg-airfrance',
    ET: 'https://www.ethiopianairlines.com/aa/information/essential-information/conditions-of-carriage',
    SN: 'https://www.brusselsairlines.com/com/misc/conditions.aspx',
    PR: 'https://www.philippineairlines.com/en/aboutus/legalnotices/generalconditionsofcarriage',
    EY: 'https://www.etihad.com/en-us/legal/conditions-of-carriage',
    LX: 'https://www.swissworldcargo.com/en/general-terms-and-conditions-of-carriage',
    LA: 'https://www.latam.com/en_us/transparency/airport-transport-agreement-conditions/',
    KL: 'https://www.klm.com/travel/cl_en/images/General_Conditions_of_Carriage_Chile_English_tcm832-430603.pdf',
    KE: 'https://www.koreanair.com/us/en/footer/terms-and-policies/terms-of-carriage',
    AY: 'https://www.finnair.com/us-en/info/conditions-of-carriage-and-notices/general-conditions-of-carriage-for-passengers-and-baggage',
    CX: 'https://www.cathaypacific.com/cx/en_US/legal-and-privacy/customer-privacy-policy.html',
    AT: 'https://www.royalairmaroc.com/gh-en/general-terms-and-conditions',
    QF: 'https://www.qantas.com/au/en/book-a-trip/flights/conditions-of-carriage.html',
    JQ: 'https://www.jetstar.com/au/en/conditions-of-carriage-jq',
    VA: 'https://www.virginaustralia.com/au/en/about-us/legal-policies/conditions-of-carriage/',
    NZ: 'https://www.airnewzealand.com.au/legal-terms-and-conditions',
    MH: 'https://www.malaysiaairlines.com/tw/en/footer/terms-and-conditions.html',
    SQ: 'https://www.singaporeair.com/en_UK/global_footer/conditions-carriage/',
    TG: 'https://www.thaiairways.com/en/roh/terms_conditions.page',
    FJ: 'https://www.fijiairways.com/en-us/conditions-of-carriage',
    ZL: 'https://www.rex.com.au/specials/fare_rules.aspx',
    UL: 'https://www.srilankan.com/en_uk/coporate/conditions-of-carriage-for-passengers-and-baggage',
    GA: 'https://www.garuda-indonesia.com/sg/en/contact/term-condition/condition-of-carriage/index',
    OD: 'https://www.malindoair.com/terms-and-conditions',
    JL: 'https://www.jal.co.jp/jp/en/inter/carriage/'
} 