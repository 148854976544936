import React from 'react';
import { Col, Row } from 'reactstrap';
import { _url } from '../../config/utils';

const ShimmerRows = ({ count }) => {
    const loadingArray = [];
    for (let i = 0; i < count; i++) {
        loadingArray.push(
            <div key={i} className="search-result-box dummy-box">
                <div className="search-result">
                    <Row>
                        <Col className="left-content">
                            <Row>
                                <div className="loading-shimmer" />
                                <div className="loading-shimmer" />
                            </Row>
                        </Col>
                        <Col xs="auto" className="right-content">
                            <div className="loading-shimmer shimmer-price" />
                            <div className="loading-shimmer shimmer-cta" />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
    return (
        <div>
            <div className="content-search-result dummy">
                <div className="content-loading">
                    <img alt="loading" src={_url('assets/images/loading-search-flight.gif')} />
                </div>
                {loadingArray}
            </div>
        </div>
    );
};

export default ShimmerRows;
