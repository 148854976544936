import React from 'react';
import {PatternFormat} from 'react-number-format';

const CVVFormat = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <PatternFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            format="###"
            placeholder="CVV"
        />
    );
};

export default CVVFormat;
