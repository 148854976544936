import { Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import { CaptionText, CurrencyText, PrimaryButton } from '../../common/CommonStyle';
import Amount from '../../common/Amount';
import { dangerTextColor, successTextColor } from '../../../constants/ColorConstants';

const PropertyReserveModal = ({ handleClose, rateDetail, handleRoomSelect }) => {
    const payLater = rateDetail.find((item) => item.propertyCollect);
    const payNow = rateDetail.find((item) => !item.propertyCollect);
    const searchParams = new URLSearchParams(window.location.search);

    const nights = Math.ceil(
        Math.abs(new Date(searchParams.get('checkoutdate')) - new Date(searchParams.get('checkindate'))) /
            (1000 * 60 * 60 * 24)
    );
    return (
        <Dialog open onClose={handleClose} maxWidth={'md'} fullWidth>
            <DialogTitle>Your payment options</DialogTitle>
            <DialogContent>
                {payNow && (
                    <Grid container mt={'20px'}>
                        <Grid item xs={12} md={8}>
                            <Typography variant={'body1'} fontWeight={700}>
                                Pay the total now
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant={'body1'}>
                                        We will process your payment in local currency
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant={'body1'}>More ways to pay: use debit/credit card</Typography>
                                </li>
                                <li>
                                    <Typography variant={'body1'}>You can use a valid coupon</Typography>
                                </li>
                                {payNow.refundable ? (
                                    <li>
                                        {payNow?.cancellationPolicy[0]?.start && (
                                            <>
                                                <Typography variant={'body1'} color={successTextColor}>
                                                    Full refundable before{' '}
                                                    {moment(payNow.cancellationPolicy[0].start).format(
                                                        'ddd, MMM, DD hh:mm a'
                                                    )}
                                                </Typography>
                                                <CaptionText fontSize={'12px'}>
                                                    You can change or cancel this stay if plans change. Because
                                                    flexibility maters.
                                                </CaptionText>
                                            </>
                                        )}
                                    </li>
                                ) : (
                                    <li>
                                        <Typography variant={'body1'} color={dangerTextColor}>
                                            Non Refundable
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </Grid>
                        <Grid textAlign={'end'} item xs={12} md={4}>
                            <CurrencyText
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    gap: 5,
                                    marginBottom: 0,
                                    justifyContent: 'end'
                                }}
                            >
                                <Amount
                                    price={payNow?.fees.total.inclusive || 0}
                                    currency={payNow?.fees.total.currency}
                                />
                            </CurrencyText>
                            <CaptionText>for {nights} night</CaptionText>
                            <CaptionText mb={'10px'}>including taxes and fees</CaptionText>
                            <PrimaryButton
                                variant={'contained'}
                                style={{ width: '100%' }}
                                onClick={() =>
                                    handleRoomSelect({
                                        rateId: payNow.rateId,
                                        rateToken: payNow.rateToken,
                                        reserveType: 'upfront',
                                        propertycollect: 0
                                    })
                                }
                            >
                                Pay now
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                )}

                {payLater && (
                    <Grid container mt={'30px'}>
                        <Grid item md={8} xs={12}>
                            <Typography variant={'body1'} fontWeight={700}>
                                {payLater?.deposits?.length > 0 && payLater.propertyCollect
                                    ? 'Payment schedule'
                                    : 'Pay when you stay'}
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant={'body1'}>
                                        {payLater?.deposits?.length > 0 && payLater.propertyCollect
                                            ? 'Pay deposit now, remainder later'
                                            : 'You will not be charged until your stay'}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant={'body1'}>
                                        Pay the property directly in thier local currency
                                    </Typography>
                                </li>
                                {payLater.refundable ? (
                                    <li>
                                        {payLater?.cancellationPolicy[0]?.start && (
                                            <>
                                                <Typography variant={'body1'} color={successTextColor}>
                                                    Full refundable before{' '}
                                                    {moment(payLater.cancellationPolicy[0].start).format(
                                                        'ddd, MMM, DD hh:mm a'
                                                    )}
                                                </Typography>
                                                <CaptionText fontSize={'12px'}>
                                                    You can change or cancel this stay if plans change. Because
                                                    flexibility maters.
                                                </CaptionText>
                                            </>
                                        )}
                                    </li>
                                ) : (
                                    <li>
                                        <Typography variant={'body1'} color={dangerTextColor}>
                                            Non Refundable
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                        </Grid>
                        <Grid item textAlign={'end'} md={4} xs={12}>
                            <CurrencyText
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    gap: 5,
                                    marginBottom: 0,
                                    justifyContent: 'end'
                                }}
                            >
                                <Amount
                                    price={payLater?.fees.total.inclusive || 0}
                                    currency={payLater?.fees.localCurrency}
                                />
                            </CurrencyText>
                            <CaptionText>for {nights} night</CaptionText>
                            <CaptionText mb={'10px'}>including taxes and fees</CaptionText>
                            <PrimaryButton
                                variant={'contained'}
                                style={{ width: '100%' }}
                                onClick={() =>
                                    handleRoomSelect({
                                        rateId: payLater.rateId,
                                        rateToken: payLater.rateToken,
                                        reserveType: 'payLater',
                                        propertycollect: 1
                                    })
                                }
                            >
                                {payLater?.deposits?.length > 0 && payLater.propertyCollect
                                    ? 'Payment schedule'
                                    : 'Pay at property'}
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PropertyReserveModal;
