import React from "react";
import { _url } from "../../../config/utils";
import { IS_USA_SITE } from "../../../constants";

export const ReviewSupport = () => (
    <div className="end max-w-640">
        <div className="checkout-feature-wrapper">
            {!IS_USA_SITE && (
                <div>
                    <div className="checkout-feature">
                        <img alt="support" src={_url("assets/images/support.png")} />
                        <div className="checkout-feature-content">
                            <h5>24/7 Support team</h5>
                            <p>
                                Call{" "}
                                <u>
                                    <a href="tel:1300 399 902">1300 399 902</a>
                                </u>{" "}
                                or contact us via chat or email.
                            </p>
                        </div>
                    </div>
                    <div className="checkout-feature">
                        <img alt="yoga" src={_url("assets/icons/yoga.png")} />
                        <div className="checkout-feature-content">
                            <h5>Flexible Interest-Free Payments</h5>
                            <p>
                                Interest-free weekly or fortnightly instalments before you travel. Pick a payment plan
                                that suits your lifestyle and budget.
                            </p>
                        </div>
                    </div>
                </div>
            )}
            {IS_USA_SITE && (
                <div>
                    <p className="text-center">
                        Have questions? Contact us via chat or call us on
                        <a href="tel:+1 888 974 0329">+1 (888) 974 0329.</a>
                    </p>
                </div>
            )}
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {/* <Review /> */}
                <div>
                    <img className="checkout-tids" alt="iata-tids" src={_url("assets/images/iata-tids.png")} />
                </div>
            </div>
        </div>
    </div>
);
