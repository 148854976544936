import styled from 'styled-components';
import CardContainerView from '../../common/CardContainer';
import { Button } from '@mui/material';
import { brokenImage } from '../../../constants/AppConstants';
import { Carousel } from 'react-responsive-carousel';
import { buttonPrimaryColor } from '../../../constants/ColorConstants';

export const OverviewCard = styled(CardContainerView)`
    padding: 10px;
`;

export const SelectRoomButton = styled(Button)`
    min-width: 200px !important;
    height: 45px !important;
    background-color: ${buttonPrimaryColor} !important;
`;

export const ShowAllImagesButton = styled(Button)`
    background-color: white !important;
    color: black !important;
    position: absolute !important;
    right: 10px;
    bottom: 10px;
`;

export const ImageGridCOntainer = styled.div`
    background-image: url("${(props) => props.url}"), url("${brokenImage}");
    background-size: cover;
    background-position: center center;
    height: 100%;
    display: flex;
    width: 100%;

    &:hover {
        opacity: 0.9;
        background-color: Black;
        cursor: pointer;
    }
`;

export const GallerCarousel = styled(Carousel)`
    & .slide {
        img {
            height: calc(100vh - 125px);
            object-fit: contain;
            background-color: grey;
        }
    }
`;

export const StyledList = styled.div`
    li, p {
        font-size: 13px;
        color: #616161;
        padding-bottom: 6px;
    }
`;