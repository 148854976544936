import moment from 'moment';
import React from 'react';
import { Col } from 'reactstrap';
import MuiSingleDatePicker from '../../../components/common/datepicker/MuiSingleDatePicker';

export const PaymentStartDate = ({ reviewData, setReviewData, onUpdatePaymentPlan }) => {
  const onChange = (startDate) => {
    const now = new Date();
    const formattedStartDate = moment(startDate).set({
      hour: now.getHours(),
      minute: now.getMinutes(),
      second: now.getSeconds(),
      millisecond: now.getMilliseconds(),
    });
    setReviewData({
      ...reviewData,
      startPayment: moment(formattedStartDate),
      error: {
        startPayment: false,
      },
    });
    onUpdatePaymentPlan(
      {
        ...reviewData,
        startPayment: moment(formattedStartDate),
        error: {
          startPayment: false,
        },
      },
      false,
    );
  };
  const onBlur = () => {
    if (reviewData.startPayment === '') {
      setReviewData({
        ...reviewData,
        error: {
          startPayment: true,
        },
      });
    }
  };
  return (
    <Col sm={12}>
      <MuiSingleDatePicker
        id='single-date'
        isError={reviewData.error.startPayment}
        onFieldBlur={onBlur}
        label='Start date:'
        dateFormat='D MMM YYYY'
        date={reviewData.startPayment ? reviewData.startPayment : ''}
        numberOfMonths={1}
        type={'secondary'}
        errorMessage={'You must select a start date'}
        handleDateChange={onChange}
        minimumDate={moment()}
        isDisabled
        maximumDate={moment().add('14', 'days')}
      />
    </Col>
  );
};
