import { Grid } from '@mui/material';
import { _url } from '../config/utils';
import Loadable from 'react-loadable';
import { Navigate } from 'react-router-dom';

const Loading = (props) =>
  props.pastDelay ? (
    <Grid container height={'100vh'} justifyContent={'center'} alignItems={'center'}>
      <Grid>
        <img
          style={{ height: 100 }}
          alt='loading'
          src={_url('assets/images/loading-search-flight.gif')}
        />
      </Grid>
    </Grid>
  ) : null;

export const Homepage = Loadable({ loader: () => import('./homepage'), loading: Loading });
export const FlightSearchPage = Loadable({
  loader: () => import('./flightsearch'),
  loading: Loading,
});
export const Faq = Loadable({ loader: () => import('./Faq'), loading: Loading });
export const Term = Loadable({ loader: () => import('../components/Term'), loading: Loading });
export const PrivacyPolicy = Loadable({
  loader: () => import('../components/PrivacyPolicy'),
  loading: Loading,
});
export const PassengerDetail = Loadable({
  loader: () => import('./flightpassenger'),
  loading: Loading,
});
export const ConfirmBooking = Loadable({
  loader: () => import('./confirmbooking'),
  loading: Loading,
});
export const ReviewPage = Loadable({
  loader: () => import('./review/ReviewPage'),
  loading: Loading,
});
export const Refer = Loadable({ loader: () => import('./refer/index'), loading: Loading });
export const HowItWorks = Loadable({ loader: () => import('./HowItWorks'), loading: Loading });
export const FourZeroFour = Loadable({ loader: () => import('./emptypage'), loading: Loading });
export const Bookings = Loadable({ loader: () => import('./booking/index'), loading: Loading });
export const BookingDetail = Loadable({
  loader: () => import('./bookingdetail/index'),
  loading: Loading,
});
export const ChangeFlightPage = Loadable({
  loader: () => import('./ChangeFlightPage'),
  loading: Loading,
});
export const Login = Loadable({ loader: () => import('../components/Login'), loading: Loading });
export const BookingContract = Loadable({
  loader: () => import('../components/stays/checkout/StaysCheckoutView'),
  loading: Loading,
});
export const BookingComplete = Loadable({
  loader: () => import('../components/stays/bookingcomplete/BookingComplete'),
  loading: Loading,
});
export const AdminFlightDeposit = Loadable({
  loader: () => import('./admin/components/flight'),
  loading: Loading,
});
export const AdminFlightTermCondition = Loadable({
  loader: () => import('./admin/components/flightt&c'),
  loading: Loading,
});
export const AdminStaysTermCondition = Loadable({
  loader: () => import('./admin/components/staystnc'),
  loading: Loading,
});

export const RestrictedRoute = ({ auth, children }) => {
  if (auth.isAuthenticated) {
    return <Navigate to='/bookings' replace />;
  }

  return children;
};

export const PrivateRoute = ({ auth, children }) => {
  if (!auth.isAuthenticated) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/login' replace />;
  }

  return children;
};
