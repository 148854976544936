import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { countrySites } from './util';

const CountryModal = ({
    handleClose,
    countryName,
    countryCode,
    search
}) => {
  return (
    <Modal
      isOpen={true}
      toggle={handleClose}
      centered
      className='country-modal text-center'
    >
      <ModalBody>
        <h3>Hello!</h3>
        <p>Seems like you are visiting from {countryName}.</p>
        <a
          className='btn btn-primary'
          href={`https://${countrySites[countryCode]}${
            window.location.pathname
          }${search}`}
        >
          Go to the{' '}
          <b>
            {countryName} ({countryCode})
          </b>{' '}
          site
        </a>
      </ModalBody>
    </Modal>
  );
};

export default CountryModal;
