import moment from 'moment';
import { useMemo } from 'react';
import { _formatMoney } from '../../../config/utils';
import { IS_USA_SITE } from '../../../constants';

export const usePaymentAmountDisplay = (reviewData, paymentPlan) =>
    useMemo(
        () => ({
            startDate: getStartDate(reviewData),
            frequency: getPaymentFrequency(reviewData.plan),
            moneyDisplay: getMoneyDisplay(reviewData, paymentPlan)
        }),
        [reviewData, paymentPlan]
    );

const getStartDate = (reviewData) => {
    if (!reviewData.startPayment) {
        return '';
    }
    if (moment(reviewData.startPayment).isSame(moment(), 'day')) {
        return 'today';
    }
    return moment(reviewData.startPayment).format('ddd, D MMM');
};

const getPaymentFrequency = (plan) => {
    if (plan === 1) {
        return 'Weekly';
    }
    return IS_USA_SITE ? 'Bi-weekly' : 'Fortnightly';
};

const getMoneyDisplay = (reviewData, paymentPlan) => {
    if (reviewData.plan === 1) {
        return _formatMoney(paymentPlan.pricePerWeek / 100);
    }
    return _formatMoney(paymentPlan.pricePerFortnight / 100);
};
