import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { _url } from "../../config/utils";
import { scrollToHome } from "../../containers/homepage/Util";

const HomeStays = ({ setSelectedTab }) => (
    <Container>
        <Row className="align-items-center">
            <Col md={6}>
                <img alt={"003"} style={{ maxWidth: "100%" }} src={_url("assets/images/003.png")} />
            </Col>
            <Col md={{ size: 6, offset: 0 }}>
                <h2>Pay Later Stays</h2>
                <p>
                    With Pay Later Stays, you can book your accommodation with no upfront payment. It’s simple and
                    affordable. The way travel should be.{" "}
                </p>
                <div className="IconList__box">
                    <div className="IconList__icon">
                        <img alt={"check"} src={_url("assets/icons/check.png")} />
                    </div>
                    <p>No upfront payment required.</p>
                </div>
                <div className="IconList__box">
                    <div className="IconList__icon">
                        <img alt={"check"} src={_url("assets/icons/check.png")} />
                    </div>
                    <p>Free cancellation up to 7 days prior to check in.</p>
                </div>
                <div className="IconList__box">
                    <div className="IconList__icon">
                        <img alt={"check"} src={_url("assets/icons/check.png")} />
                    </div>
                    <p>Unlimited date changes.</p>
                </div>
                <div className="IconList__box">
                    <div className="IconList__icon">
                        <img alt={"check"} src={_url("assets/icons/check.png")} />
                    </div>
                    <p>Interest free and no credit checks.</p>
                </div>
                <div className="Home__section__button">
                    <Button
                        color="primary"
                        onClick={() => {
                            setSelectedTab(2);
                            scrollToHome();
                        }}
                    >
                        Search Stays
                    </Button>
                    {false && (
                        <Link className="Home__section__learnMore" to="/how-it-works#stays">
                            Learn more
                        </Link>
                    )}
                </div>
            </Col>
        </Row>
    </Container>
);

export default HomeStays;
