/* eslint-disable react/button-has-type */
import { isEmpty } from 'lodash';
import React from 'react';
import { MobileFiltersHeader } from './components/MobileFiltersHeader';

export const FlightFilters = ({ searchResult, searchState, setSearchState, onClearFilter, toggleCarriersFilter }) => {
    const carriers = isEmpty(searchResult.carriers) ? [] : Object.keys(searchResult.carriers);
    return (
        <div>
            <div className={`filters ${searchState.filterToggled ? 'opened' : ''}`}>
                <MobileFiltersHeader searchState={searchState} setSearchState={setSearchState} />

                {searchResult.carriersVisibilityFilter && (
                    <div className="filters-accordian filters-carriers">
                        <div className="filters-accordian__heading">Airlines</div>
                        <div className="filters-carriers-controls">
                            <button
                                onClick={onClearFilter}
                                disabled={searchResult.carriersVisibilityFilter.length === 0}
                            >
                                Clear all
                            </button>
                        </div>
                        <div className="filters-accordian__body">
                            {carriers.map((key) => (
                                <div key={key} className="filter">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={key}
                                            onChange={() => toggleCarriersFilter(key)}
                                            checked={!!searchResult.carriersVisibilityFilter.includes(key)}
                                        />
                                        <span>{searchResult.carriers[key].toLowerCase()}</span>
                                    </label>
                                </div>
                            ))}
                            <div className="filter">
                                <label>
                                    <input
                                        type="checkbox"
                                        value="COMBINATIONS"
                                        onChange={() => toggleCarriersFilter('COMBINATIONS')}
                                        checked={
                                            !!(
                                                searchResult.hasAirlineCombinations &&
                                                searchResult.carriersVisibilityFilter.includes('COMBINATIONS')
                                            )
                                        }
                                        disabled={!searchResult.hasAirlineCombinations}
                                    />
                                    <span>Airline Combinations</span>
                                </label>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div
                className="filter-button btn btn-default btn-block d-md-block d-lg-none"
                onClick={() =>
                    setSearchState({
                        ...searchState,
                        filterToggled: true
                    })
                }
            >
                Filters
            </div>
        </div>
    );
};
