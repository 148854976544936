import { useNavigate } from 'react-router-dom'
import { _url } from '../../config/utils'

const HeaderLogo = ({
  path,
  handleSeeAllPropClick,
  toggleMobileMenu,
  isOpen,
  setEditSearchOpen,
}) => {
  const navigate = useNavigate()

  return (
    <div className='Header__logo'>
      <a
        className='navbar-brand'
        onClick={() => {
          if (path.includes('review')) {
            if (
              window.confirm('Are you sure you want to go home? Your current booking will be lost.')
            ) {
              if (isOpen) {
                toggleMobileMenu()
              }
              navigate('/')
            }
          } else {
            if (isOpen) {
              toggleMobileMenu()
            }
            navigate('/')
          }
          setEditSearchOpen(false)
        }}
      >
        <img
          alt='logo'
          width='170'
          className='logo blue-logo'
          src={_url('assets/images/plt-blue-logo.png')}
        />
        <img
          alt='logo'
          width='170'
          className='logo white-logo'
          src={_url('assets/images/plt-white-logo.png')}
        />
      </a>
      {path.includes('/stays/detail') && (
        <div className='back-to-results'>
          <a onClick={handleSeeAllPropClick}>See all properties</a>
        </div>
      )}
    </div>
  )
}

export default HeaderLogo
