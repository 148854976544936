import { Col, Row } from 'reactstrap'
import { updateCarriersFilter } from '../../../redux/modules/search'

export const SearchResultCount = ({ filteredFlights, flights, dispatch }) => {
  if (!flights || flights.length === 0) {
    return null
  }
  return (
    <Row>
      <Col>
        <p className='search-results-number'>
          {`${filteredFlights.length !== flights.length ? `${filteredFlights.length} of ` : ''}`}
          {flights.length} result
          {flights.length === 1 ? '' : 's'}
          {filteredFlights.length !== flights.length && (
            <a className='show-all' onClick={() => dispatch(updateCarriersFilter([]))}>
              {' '}
              (Show all)
            </a>
          )}
        </p>
      </Col>
      <Col style={{ textAlign: 'end' }} className='text-right search-results-sort-box'>
        Sort by: Best
      </Col>
    </Row>
  )
}
