import { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { brokenImage } from '../../constants/AppConstants'

const SlideCarouselWrapper = ({ images, additionalImgStyle }) => {
  const [carouselImages, setCarouselImages] = useState(images)
  return (
    <Carousel indicators={false} interval={null}>
      {carouselImages.map((image, index) => (
        <Carousel.Item key={image.id}>
          <img
            src={image.url}
            style={{ objectFit: 'cover', height: 250, ...additionalImgStyle }}
            alt='slideshow'
            onError={(event) => {
              event.target.src = brokenImage
              event.onerror = null
              const copyImage = carouselImages
              copyImage.splice(index, 1)
              setCarouselImages(copyImage)
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default SlideCarouselWrapper
