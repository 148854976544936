import message from 'antd/es/message'
import jwtDecode from 'jwt-decode'
import internalApi from '../../config/internalApi'
import { createAction } from '../utils'
import { REMOVE_CARDS } from './cards'
import { REMOVE_REFERRALS } from './referrals'

const LOGIN = 'plt-web/auth/LOGIN'
const GET_CUSTOMER_INFO = 'plt-web/auth/GET_CUSTOMER_INFO'
const LOGOUT = 'plt-web/auth/LOGOUT'
export const TOGGLE_LOGIN_MODAL = 'plt-web/auth/TOGGLE_LOGIN_MODAL'

const initialState = {
  token: '',
  user: {},
  isAuthenticated: false,
  loginModal: false,
}

export default function reducer(state = initialState, { type, ...action }) {
  switch (type) {
    case LOGIN: {
      const token = action.payload
      const { name, email, sub, phone, exp } = jwtDecode(token)
      const firstName = name.replace(/ .*/, '')
      const capitalisedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'loggedIn',
        user: {
          id: sub,
          name,
          email,
          phone,
        },
      })

      message.success(`Welcome back, ${firstName}!`)

      return {
        token: action.payload,
        user: {
          firstName: capitalisedFirstName,
          email,
          id: sub,
          phone,
          exp,
        },
        isAuthenticated: true,
        loginModal: false,
      }
    }
    case LOGOUT: {
      message.success('You\'ve been successfully logged out!')
      return initialState
    }
    case GET_CUSTOMER_INFO: {
      const { id, customerIdentifier, firstName, ...customer } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          ...customer,
        },
      }
    }
    case TOGGLE_LOGIN_MODAL: {
      return {
        ...state,
        loginModal: !state.loginModal,
      }
    }
    default:
      return state
  }
}

export const updateAuth =
  (data = null) =>
  (dispatch) => {
    dispatch(createAction(LOGIN, data))
  }

export const logoutAuth = () => (dispatch) => {
  localStorage.clear()
  dispatch(createAction(LOGOUT, ''))
}

export const loginUser = (params) => (dispatch) =>
  internalApi.post('/v2/auth/login', params).then((response) => {
    dispatch(createAction(LOGIN, response.access_token))
    return response
  })

export const logoutUser = () => (dispatch) => {
  localStorage.clear()

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'loggedOut',
  })

  dispatch(createAction(REMOVE_CARDS))
  dispatch(createAction(REMOVE_REFERRALS))
  dispatch(createAction(LOGOUT, ''))
}

export const getCustomerInfo = () => (dispatch, getState) => {
  const { auth } = getState()
  internalApi.get('/v2/customer/me', {}, { 'x-access-token': auth.token }).then((response) => {
    dispatch(createAction(GET_CUSTOMER_INFO, response))
    // return response;
  })
}
