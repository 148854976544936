import { Grid, Typography } from '@mui/material';
import React from 'react';
import CardContainerView from '../../common/CardContainer';

const PropertyPolicySection = ({ policy }) => (
    <CardContainerView>
        <Grid container>
            <Grid mb={['20px', undefined]} item md={3} xs={12}>
                <Typography variant="h5" fontWeight={'bold'}>
                    Policies
                </Typography>
            </Grid>
            <Grid item md={9} xs={12}>
                <Grid container spacing={'30px'}>
                    <Grid item xs={12} md={6}>
                        <Typography fontSize={'20px'} fontWeight={'bold'} variant="body1" gutterBottom>
                            Check-in
                        </Typography>
                        <Typography variant="body1">
                            Check-in from {policy.checkin.beginTime} - {policy.checkin.endTime}
                        </Typography>
                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: policy.checkin.instructions }} />
                        <Typography variant="body1">Minimum check-in age- {policy.checkin.minAge}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography fontSize={'20px'} fontWeight={'bold'} variant="body1" gutterBottom>
                            Check-out
                        </Typography>
                        <Typography variant="body1">Check-out before {policy.checkout.time}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={'20px'} fontWeight={'bold'} variant="body1" gutterBottom>
                            Special instructions
                        </Typography>
                        <Typography variant="body1">{policy.checkin.specialInstructions}</Typography>
                    </Grid>
                    {policy.attributes.map((item) => (
                        <Grid item xs={12} key={item.label}>
                            <Typography fontSize={'20px'} fontWeight={'bold'} variant="body1" gutterBottom>
                                {item.label}
                            </Typography>
                            {item.value.map((value) => (
                                <Typography my={1} variant="body1" key={value}>
                                    {value}
                                </Typography>
                            ))}
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Typography fontSize={'20px'} fontWeight={'bold'} variant="body1" gutterBottom>
                            Property payment types
                        </Typography>
                        {policy.onSitePayments.types.map(
                            (value) => value.icon && <img key={value.id} src={value.icon} alt={value.name} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </CardContainerView>
);

export default PropertyPolicySection;
