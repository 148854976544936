import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useHome = () => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();
    return {
        selectedTab,
        setSelectedLocation,
        selectedLocation,
        setSelectedTab,
        location,
        dispatch
    };
};