export const flightsType = {
    return: 'return',
    oneWay: 'one-way',
    multiCity: 'multi-city'
};

export const CHANGE_FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY = 3;
export const INITIAL_SHOW_MORE_SIZE = 8;
export const ADULTS = 'adults';
export const CHILDREN = 'children';
export const CHILDREN_AGES = 'childrenages';
export const INFANTS = 'infants';
export const brokenImage = '/assets/images/broken.jpg';
export const titleOptions = ['Mr', 'Miss', 'Mrs', 'Ms', 'Master'];
export const REVIEW_USA = 'https://www.trustpilot.com/review/paylatertravel.com';
export const REVIEW_AU = 'https://au.trustpilot.com/review/paylatertravel.com.au';
export const SHOW_BOOKING = false;