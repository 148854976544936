import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const SpecialPromotionInfoModal = ({ specialPromotion, handleClose }) => (
    <Dialog maxWidth={'sm'} fullWidth open onClose={handleClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            Special Promotions
            {
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            }
        </DialogTitle>
        <DialogContent>
            {specialPromotion.length > 0 &&
                specialPromotion.map((permission) => (
                    <div key={permission['@runno']} style={{ marginBottom: 30 }}>
                        <Typography variant={'h6'}>{permission.specialName}</Typography>
                        <Typography>{permission.condition}</Typography>
                        <Typography>{permission.description}</Typography>
                    </div>
                ))}
        </DialogContent>
    </Dialog>
);

export default SpecialPromotionInfoModal;
