import { useMemo } from 'react';

export const useFiltereredFlights = (flights, searchResult) =>
    useMemo(() => {
        if (!flights || !searchResult.carriersVisibilityFilter) {
            return [];
        }

        return flights.filter((itinerary) => {
            // if there is only one carrier for this itinerary & its active
            if (
                itinerary.carriers.length === 1 &&
                (searchResult.carriersVisibilityFilter.length === 0 ||
                    searchResult.carriersVisibilityFilter.includes(itinerary.carriers[0]))
            ) {
                return true;
            }
            if (
                itinerary.carriers.length > 1 &&
                (searchResult.carriersVisibilityFilter.length === 0 ||
                    searchResult.carriersVisibilityFilter.includes('COMBINATIONS'))
            ) {
                return true;
            }
            return false;
        });
    }, [flights, searchResult]);
