import { getYear } from 'date-fns'
import { range } from 'lodash'
import moment from 'moment'
import { IS_USA_SITE } from '../constants'

export const getAddDays = (days) => {
  const result = new Date()
  result.setDate(result.getDate() + days)
  return result
}

export const getDiffDays = (startDate, endDate) => {
  const start = moment(startDate, 'YYYY-MM-DD')
  const end = moment(endDate, 'YYYY-MM-DD')
  return moment.duration(end.diff(start)).asDays()
}

export const getCurrency = () => {
  if (IS_USA_SITE) {
    return 'US'
  }
  return 'AU'
}

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

export const windowScroll = (id) => {
  window.scrollTo({
    top: document.getElementById(id).getBoundingClientRect().top + window.scrollY - (80 + 68),
    behavior: 'smooth',
  })
}

export const yearsDropdown = range(1900, getYear(new Date()) + 1, 1)
export const monthsDropdown = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const formatCurrency = (amount) => amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

export const formatPhoneNumber = (phoneNumber) => {
  const x = phoneNumber && phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`
}
