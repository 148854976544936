import Style from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledDatePicker = Style.div`
    border: ${(props) => (props.error ? '2px red solid' : '2px #58a946 solid')} !important;
`;

export const NewSticker = Style.div`
    position: absolute;
    margin-top: -30px;
    margin-left: 40px;
    color: black;
    padding: 5px 10px;
    border-radius: 10px;
    background: rgb(244, 208, 63);
    font-weight: bold;
`;

export const CaptionText = Style(Typography)`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 400};
    font-size: ${(props) => props.fontSize || 12}px;
    line-height: 18px;
    color: ${(props) => props.color || '#828282'};
`;

export const CustomDatePickerContainer = Style.div`
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    background: #fff;
    flex: 1 0;
    padding: 8px 14px;
    cursor: pointer;
    &:hover {
        box-shadow: inset 0 0 0 2px #19b4d1;
    }
`;

export const CustomDatePickerLabel  = Style.div`
    font-weight: 700;
    font-size: 12px;
    color: #000;
    line-height: 16px;
    letter-spacing: normal;
`;

export const CustomDatePickerDate = Style.div`
    font-size: 14px!important;
    line-height: 1.5;
    color: #1e1e1e;
`;