import { datadogRum } from '@datadog/browser-rum'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'
import Coronavirus from '../components/Coronavirus'
import Header2 from '../components/header/Header2'
import ScrollToTop from '../components/ScrollToTop'
import PropertyDetailView from '../components/stays/propertydetail/PropertyDetailView'
import HotelSearchView from '../components/stays/result/HotelSearchView'
import AdminView from './admin'
import {
  AdminFlightDeposit,
  AdminFlightTermCondition,
  AdminStaysTermCondition,
  BookingComplete,
  BookingContract,
  BookingDetail,
  Bookings,
  ChangeFlightPage,
  ConfirmBooking,
  Faq,
  FlightSearchPage,
  Homepage,
  HowItWorks,
  Login,
  PassengerDetail,
  PrivacyPolicy,
  PrivateRoute,
  Refer,
  RestrictedRoute,
  ReviewPage,
  Term,
} from './LoadingView'
import { LicenseInfo } from '@mui/x-license-pro';
import { MUI_LICENSE_KEY } from '../constants'

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const App = () => {
  const user = useSelector((state) => state.auth)
  const location = useLocation()
  const auth = useSelector((state) => state.auth)
  const loading = useSelector((state) => state.loading)

  const { id: userId, email: userEmail, name: userName, phone: userPhone } = user

  useEffect(() => {
    setTagManager()

    datadogRum.init({
      applicationId: '1e8e61a0-eada-4ad5-a6ae-8dc5ebfa8fed',
      clientToken: 'pubfe3de757580b6543d9d5412bb7f0fb22',
      site: 'datadoghq.com',
      service: 'plt-web',
      env: process.env.REACT_APP_ENVIRONMENT,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })

    datadogRum.startSessionReplayRecording()
  }, [])

  const setTagManager = () => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_ENV_AUTH,
      preview: process.env.REACT_APP_GTM_ENV_PREVIEW,
      dataLayer: {
        user: {
          id: userId,
          email: userEmail,
          name: userName,
          phone: userPhone,
        },
        nodeEnv: process.env.NODE_ENV,
      },
    }
    TagManager.initialize(tagManagerArgs)

    // SCRIPT FOR HOTELS BOOKING.COM WIDGET
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://aff.bstatic.com/static/affiliate_base/js/flexiproduct.js?v=${+new Date()}`
    const p = document.getElementsByTagName('script')[0]
    p.parentNode.insertBefore(script, p)
  }
  let transparentHeader = false
  let hideHeader = false
  const path = location.pathname
  if (['/', '/flights', '/stays'].includes(path)) transparentHeader = true
  if (path.match(/\/stays\/[^.]*\/[^.]*/)) transparentHeader = true
  if (path.includes('/stays/detail')) transparentHeader = false
  if (path.includes('/admin')) hideHeader = true

  return (
    <div className='PLT'>
      {!hideHeader && <Header2 transparent={transparentHeader} auth={auth} />}
      <div className={'Content'}>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/admin' element={<AdminView />} />
            <Route path='/admin/depositconfig' element={<AdminFlightDeposit />} />
            <Route path='/admin/flighttnc' element={<AdminFlightTermCondition />} />
            <Route path='/admin/staystnc' element={<AdminStaysTermCondition />} />
            <Route path='/flightssearch/s/:multicity' element={<FlightSearchPage />} />
            <Route
              path='/flightssearch/s/:origin/:destination/:departureDate/:returnDate'
              element={<FlightSearchPage />}
            />
            <Route
              path='/flightssearch/s/:origin/:destination/:departureDate'
              element={<FlightSearchPage />}
            />
            <Route path='/flightssearch/passenger' element={<PassengerDetail />} />
            <Route path='/flights/v2/passenger' element={<PassengerDetail />} />
            <Route path='/flightssearch/v2/passenger' element={<PassengerDetail />} />
            <Route path='/flights/review' element={<ReviewPage />} />
            <Route path='/stays/search' element={<HotelSearchView />} />
            <Route path='/stays/confirm' element={<BookingComplete />} />
            <Route path='/stays/detail' element={<PropertyDetailView />} />
            <Route path='/stays/checkout' element={<BookingContract />} />
            <Route path='/faq' element={<Faq />} />
            <Route path='/terms' element={<Term />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/confirm' element={<ConfirmBooking />} />
            <Route path='/confirmbooking' element={<ConfirmBooking />} />
            <Route path='/how-it-works' element={<HowItWorks />} />
            <Route path='/travel-alert/coronavirus' element={<Coronavirus />} />
            <Route
              path='/login'
              element={
                <RestrictedRoute auth={auth}>
                  <Login />
                </RestrictedRoute>
              }
            />
            <Route
              path='/refer'
              element={
                <PrivateRoute auth={auth}>
                  <Refer />
                </PrivateRoute>
              }
            />
            <Route
              path='/bookings'
              element={
                <PrivateRoute auth={auth}>
                  <Bookings />
                </PrivateRoute>
              }
            />
            <Route
              path='/bookings/:reference'
              element={
                <PrivateRoute auth={auth}>
                  <BookingDetail />
                </PrivateRoute>
              }
            />
            <Route
              path='/bookings/:reference/change/flights'
              element={
                <PrivateRoute auth={auth}>
                  <ChangeFlightPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </ScrollToTop>
      </div>
      {loading.status && (
        <div className='loading'>
          <FontAwesomeIcon spin style={{ fontSize: '30px' }} icon={faSpinner} />
        </div>
      )}
    </div>
  )
}

export default App
