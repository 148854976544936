import message from 'antd/es/message'
import moment from 'moment'
import internalApi from '../../config/internalApi'
import { FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY } from '../../constants'
import { createAction } from '../utils'

const UPDATE_SEARCH = 'plt-web/search/UPDATE_SEARCH'
const UPDATE_SEARCH_LOADING = 'plt-web/search/UPDATE_SEARCH_LOADING'
const UPDATE_SEARCH_FLIGHTS = 'plt-web/search/UPDATE_SEARCH_FLIGHTS'
const SELECT_HOTEL = 'plt-web/search/SELECT_HOTEL'
const UPDATE_SEARCH_RESULTS = 'plt-web/search/UPDATE_SEARCH_RESULTS'
const LOADING_SEARCH_RESULTS = 'plt-web/search/LOADING_SEARCH_RESULTS'
const SET_CARRIERS_FILTER = 'plt-web/search/SET_CARRIERS_FILTER'
const EMPTY_SEARCH_RESULTS = 'plt-web/search/EMPTY_SEARCH_RESULTS'

const initialState = {
  query: {
    flights: {
      origin: null,
      destination: null,
      type: 'return', // return or one-way
      departureDate: moment().add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY, 'days').format('YYYY-MM-DD'),
      returnDate: moment()
        .add(FLIGHT_SEARCH_MIN_DAYS_FROM_TODAY + 14, 'days')
        .format('YYYY-MM-DD'),
      adults: 1,
      children: 0,
      infants: 0,
      multiCityLegs: [
        {
          origin: null,
          destination: null,
          departureDate: '',
        },
        {
          origin: null,
          destination: null,
          departureDate: '',
        },
      ],
    },
    hotels: {
      destination: null,
      checkInDate: moment().add(180, 'days').format('YYYY-MM-DD'),
      checkOutDate: moment().add(181, 'days').format('YYYY-MM-DD'),
      adults: 2,
      childAges: [],
      rooms: 1,
    },
  },
  results: {
    data: [],
  },
  activeTab: 1, // 1 = flights, 2 = stays
  loading: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_SEARCH: {
      if (action.productType === 'hotel') {
        return {
          ...state,
          query: {
            ...state.query,
            hotels: {
              ...state.query.hotels,
              ...action.data,
            },
          },
          activeTab: 2,
        }
      }
      return {
        ...state,
        query: {
          ...state.query,
          flights: action.data,
        },
        activeTab: 1,
      }
    }
    case LOADING_SEARCH_RESULTS: {
      return {
        ...state,
        results: {
          data: [],
        },
        loading: true,
      }
    }
    case EMPTY_SEARCH_RESULTS: {
      return {
        ...state,
        results: {
          data: [],
        },
      }
    }
    case SELECT_HOTEL: {
      return {
        ...state,
        results: {
          ...state.results,
          selectedHotel: {
            hotelId: action.data,
          },
        },
      }
    }
    case UPDATE_SEARCH_LOADING: {
      return {
        ...state,
        loading: true,
      }
    }
    case UPDATE_SEARCH_FLIGHTS: {
      if (action.payload.data === undefined) {
        return {
          ...state,
          results: {
            data: [],
            errors: { message: 'No results' },
          },
          loading: false,
        }
      }
      return {
        ...state,
        results: {
          data: action.payload.data,
          type: 'flights',
          carriers: action.payload.dictionaries ? action.payload.dictionaries.carriers : {},
          carriersVisibilityFilter: action.payload.carriersVisibilityFilter,
          hasAirlineCombinations: action.payload.hasAirlineCombinations,
        },
        loading: false,
      }
    }
    case UPDATE_SEARCH_RESULTS: {
      return {
        ...state,
        results: {
          type: 'hotels',
          data: action.payload.data,
        },
        loading: false,
      }
    }
    case SET_CARRIERS_FILTER: {
      return {
        ...state,
        results: {
          ...state.results,
          carriersVisibilityFilter: action.payload,
        },
      }
    }
    default:
      return state
  }
}

export const updateSearch = (data, productType = null) => ({
  type: UPDATE_SEARCH,
  data,
  productType,
})

export const selectHotel = (data = {}) => ({
  type: SELECT_HOTEL,
  data,
})

export const updateSearchLoading = () => ({
  type: LOADING_SEARCH_RESULTS,
})

export const emptySearchResults = (data = {}) => ({
  type: EMPTY_SEARCH_RESULTS,
})

export const updateSearchFlights = (searchQuery, saveState, auth) => (dispatch) => {
  // cast ints
  searchQuery.adults = searchQuery.adults ? parseInt(searchQuery.adults) : 1
  searchQuery.children = searchQuery.children ? parseInt(searchQuery.children) : 0
  searchQuery.infants = searchQuery.infants ? parseInt(searchQuery.infants) : 0

  dispatch(updateSearch(searchQuery))
  dispatch(updateSearchLoading())

  // send events
  const formattedDepartureDate = moment(searchQuery.departureDate, 'YYYY-MM-DD')

  let formattedSearchQuery = {
    ...searchQuery,
    originIataCode:
      searchQuery.type !== 'multi-city'
        ? searchQuery.origin.id
        : searchQuery.multiCityLegs[0].origin.id,
    originAirportName:
      searchQuery.type !== 'multi-city'
        ? searchQuery.origin.name
        : searchQuery.multiCityLegs[0].origin.name,
    originCityName:
      searchQuery.type !== 'multi-city'
        ? searchQuery.origin.city
        : searchQuery.multiCityLegs[0].origin.city,
    originCountry:
      searchQuery.type !== 'multi-city'
        ? searchQuery.origin.country
        : searchQuery.multiCityLegs[0].origin.city,
    departureDayOfYear: formattedDepartureDate.dayOfYear(),
    departureWeekOfYear: formattedDepartureDate.week(),
    departureMonth: formattedDepartureDate.month() + 1,
    departureYear: formattedDepartureDate.year(),
    departureEpoch: formattedDepartureDate.unix(),
    isFamily: !!(searchQuery.children > 0 || searchQuery.infants > 0),
  }

  if (searchQuery.type !== 'one-way') {
    const formattedReturnDate = moment(searchQuery.returnDate, 'YYYY-MM-DD')
    formattedSearchQuery = {
      ...formattedSearchQuery,
      destinationIataCode:
        searchQuery.type !== 'multi-city'
          ? searchQuery.destination.id
          : searchQuery.multiCityLegs[0].destination.id,
      destinationAirportName:
        searchQuery.type !== 'multi-city'
          ? searchQuery.destination.name
          : searchQuery.multiCityLegs[0].destination.name,
      destinationCityName:
        searchQuery.type !== 'multi-city'
          ? searchQuery.destination.city
          : searchQuery.multiCityLegs[0].destination.city,
      destinationCountry:
        searchQuery.type !== 'multi-city'
          ? searchQuery.destination.country
          : searchQuery.multiCityLegs[0].destination.city,
      returnDayOfYear: formattedReturnDate.dayOfYear(),
      returnWeekOfYear: formattedReturnDate.week(),
      returnMonth: formattedReturnDate.month() + 1,
      returnYear: formattedReturnDate.year(),
      returnEpoch: formattedReturnDate.unix(),
    }
  }

  // push search event to GTM dataLayer
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'bookingSearchStarted',
    searchData: formattedSearchQuery,
  })

  // build the paramaters for flight search
  let params = {
    adults: searchQuery.adults,
    children: searchQuery.children,
    infants: searchQuery.infants,
    currencyCode: searchQuery.currencyCode,
    travelClass: 'ECONOMY',
    limit: 200,
    usingCredit: searchQuery.c === 't' ? true : undefined,
    includedAirlineCodes: searchQuery.includedAirlineCodes || undefined,
    // source: (window.location.hostname.includes('.com.au') || window.location.hostname.includes('localhost')) ? 'sabre' : 'amadeus'
    source: 'sabre',
  }

  const { multiCityLegs } = searchQuery
  const multiCityQueries = []

  if (searchQuery.type === 'multi-city') {
    multiCityLegs.forEach((leg) => {
      multiCityQueries.push({
        origin: leg.origin.id,
        destination: leg.destination.id,
        departureDate: moment(leg.departureDate).format('YYYY-MM-DD'),
      })
    })
    params = {
      ...params,
      // originName: multiCityLegs[0].origin.name,
      // destinationName: multiCityLegs[multiCityLegs.length - 1].destination.name,
      flights: multiCityQueries,
    }
  } else {
    params = {
      ...params,
      flights: [
        {
          origin: searchQuery.origin.id,
          destination: searchQuery.destination.id,
          departureDate: searchQuery.departureDate,
        },
      ],
      // origin: searchQuery.origin.id,
      // originName: searchQuery.origin.name,
      // destination: searchQuery.destination.id,
      // destinationName: searchQuery.destination.name,
      // departureDate: searchQuery.departureDate,
      // returnDate: searchQuery.returnDate,
    }
    if (searchQuery.returnDate) {
      params.flights.push({
        origin: searchQuery.destination.id,
        destination: searchQuery.origin.id,
        departureDate: searchQuery.returnDate,
      })
    }
  }

  internalApi
    .post('/v2/flight-offers', params, { 'x-access-token': auth ? auth.token : undefined })
    .then((response) => {
      const flights = response.data
      const carriers = []
      let hasAirlineCombinations = false
      flights.forEach((flight) => {
        if (flight.carriers.length === 1) {
          carriers.push(flight.carriers[0])
        } else if (flight.carriers.length > 1) {
          hasAirlineCombinations = true
        }
      })
      const updatedCarriersArray = [...new Set(carriers)]
      const updatedCarriersDictionary = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key of updatedCarriersArray) {
        updatedCarriersDictionary[key] = response.dictionaries.carriers[key]
      }

      if (hasAirlineCombinations) {
        updatedCarriersArray.push('COMBINATIONS')
      }

      response.dictionaries.carriers = updatedCarriersDictionary
      response.carriersVisibilityFilter = []
      response.hasAirlineCombinations = hasAirlineCombinations
      dispatch(createAction(UPDATE_SEARCH_FLIGHTS, response))
    })
    .catch((err) => {
      // console.log(err);
      message.error(err.response?.data?.error?.message || 'An error occured.')
      dispatch(createAction(UPDATE_SEARCH_FLIGHTS, []))
    })
}

export const updateSearchResults = (query) => (dispatch) => {
  dispatch(createAction(LOADING_SEARCH_RESULTS))

  // push search event to GTM dataLayer
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'hotelBookingSearchStarted',
    searchData: query,
  })

  // build params
  const params = {
    ...query,
    roomQuantity: query.rooms ? parseInt(query.rooms) : 1,
    adults: query.adults ? parseInt(query.adults) : 2,
    rooms: undefined,
    includeClosed: true,
    bestRateOnly: false,
    childAges: query.childAges.toString(),
    // paymentPolicy: 'GUARANTEE',
    // boardType: 'ROOM_ONLY'
  }

  // validate everything is here
  const requiredParams = ['destination', 'checkInDate', 'checkOutDate', 'adults', 'roomQuantity']

  let validRequest = true

  requiredParams.forEach((field) => {
    if (!params[field]) {
      validRequest = false
    }
  })

  if (!validRequest) {
    dispatch(createAction(UPDATE_SEARCH_RESULTS, { data: [] }))
  } else {
    dispatch(updateSearch(query, 'hotel'))
    internalApi
      .get('/v2/hotel-offers', params)
      .then((response) => {
        if (response.errors) {
          dispatch(createAction(UPDATE_SEARCH_RESULTS, { data: [] }))
        } else {
          dispatch(createAction(UPDATE_SEARCH_RESULTS, response))
        }
      })
      .catch((err) => {
        message.error(err.response.data ? err.response.data.message : 'An error occured')
        dispatch(createAction(UPDATE_SEARCH_RESULTS, { data: [] }))
      })
  }
}

export const updateCarriersFilter =
  (data = []) =>
  (dispatch) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'bookingFilterAppliedAirlines',
      selectedFilters: data,
    })

    dispatch(createAction(SET_CARRIERS_FILTER, data))
  }
