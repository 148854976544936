import { Button, Dialog, Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';

const FullyRefundable = ({ rates, handleClose, isRefundable }) => {
    const renderRefundableView = () => {
        if (rates.refundable && rates.cancellationPolicy) {
            return (
                <>
                    <Grid item xs={12}>
                        <Typography variant={'body1'}>
                            Cancel your reservation before {moment(rates.cancellationPolicy[0].start).format('ddd, MMM, DD hh:mm a')} and you'll get a full
                            refund.
                        </Typography>
                    </Grid>
                    <Grid item mt={'10px'} xs={12}>
                        <Typography variant={'body1'}>{rates.cancellationPolicy[0].penalties}</Typography>
                    </Grid>
                    <Grid item mt={'10px'} xs={12}>
                        <Typography variant={'body1'}>Times are based on the property's local time.</Typography>
                    </Grid>
                </>
            );
        }
        return (
            <Grid item xs={12}>
                <Typography variant={'body1'}>
                    If you change or cancel your booking you will not get a refund or credit to use for a futue stay.
                    This policy will apply regardless of COVID-19, subject to any local consumer laws.
                </Typography>
            </Grid>
        );
    };
    return (
        <Dialog open onClose={handleClose}>
            <Grid p={'30px'} container>
                {renderRefundableView()}
            </Grid>
            <Divider />
            <Grid px={'30px'} py={'20px'} item xs={12}>
                <Button
                    fullWidth
                    p={'10px'}
                    onClick={handleClose}
                    style={{ textTransform: 'none' }}
                    variant={'outlined'}
                >
                    Got it
                </Button>
            </Grid>
        </Dialog>
    );
};

export default FullyRefundable;
