import { IconButton, InputAdornment } from "@mui/material";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useBooleanState } from "../../../hooks/CommonHooks";
import SearchTextfieldWrapper from "../../common/SearchTextfieldWrapper";
import { getSearchedLocation } from "./Action";
import { Close } from "@mui/icons-material";
import { SearchLocationContainer } from "./Style";

const SearchLocationView = ({ fromLocation, setFromLocation }) => {
    const [location, setLocation] = useState(fromLocation);
    const [fromSearchedAirports, setFromSearchedAirports] = useState([]);
    const isFromSearchLoading = useBooleanState(false);

    useEffect(() => {
        setLocation(fromLocation);
    }, [fromLocation]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchFromAirports = useCallback(
        debounce((text) => {
            if (text) {
                isFromSearchLoading.setTrue();
                getSearchedLocation(
                    text,
                    (res) => {
                        console.log("options", res);
                        setFromSearchedAirports(res);
                        isFromSearchLoading.setFalse();
                    },
                    () => {
                        setFromSearchedAirports([]);
                        isFromSearchLoading.setFalse();
                    }
                );
            }
        }, 300),
        []
    );

    const handleFromSearchTextChange = (text) => {
        setLocation(text);
        searchFromAirports(text);
    };

    return (
        <SearchLocationContainer item xs={12} md={3}>
            <SearchTextfieldWrapper
                name={"from"}
                size={"small"}
                value={location}
                options={fromSearchedAirports || []}
                onInputChange={handleFromSearchTextChange}
                onOptionSelect={(locationText, locationCode) => {
                    setFromLocation(locationText, locationCode);
                }}
                isloading={isFromSearchLoading.value}
                label={"Destination"}
                InputProps={{
                    endAdornment: location && (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    setFromLocation(null);
                                    setLocation("");
                                    searchFromAirports("");
                                }}
                            >
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
        </SearchLocationContainer>
    );
};

export default SearchLocationView;
