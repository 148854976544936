export const UPDATE_DETAIL_FLIGHT = 'plt-web/detailFlight/UPDATE_DETAIL_FLIGHT';

const initialState = {};

export default function reducer(state = initialState, { type, ...action }) {
  switch (type) {
    case UPDATE_DETAIL_FLIGHT: {
      return action.data;
    }
    default: return state;
  }
}

export const updateDetailFlight = (data = {}) => ({
  type: UPDATE_DETAIL_FLIGHT,
  data
});
