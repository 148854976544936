/* eslint-disable react/button-has-type */
/* eslint-disable prefer-const */
import axios from 'axios'
import classNames from 'classnames'
import { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  Navbar,
  NavItem,
} from 'reactstrap'

import { _url } from '../config/utils'
import { IS_USA_SITE } from '../constants'
import Banner from '../containers/banner'
import { withRouter } from '../hooks/CommonHooks'
import { logoutUser, TOGGLE_LOGIN_MODAL } from '../redux/modules/auth'
import EditSearch from './EditSearch'
import LoginForm from './LoginForm'

const countrySites = {
  US: 'paylatertravel.com',
  AU: 'paylatertravel.com.au',
}
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      accountDropdownOpen: false,
      scrollY: window.scrollY,
      editSearchOpen: false,
      countryCode: 'AU',
      countryName: 'Australia',
      countryBanner: false,
      countryModal: false,
      showBanner: true,
    }
  }

  componentDidMount() {
    this.getGeoInfo()
    window.addEventListener('scroll', this.handleScroll)

    // if token is expired then logout the user
    if (this.props.auth.user.email && !this.props.auth.user.exp) this.props.logoutUser()
    if (this.props.auth.user.exp <= new Date()) this.props.logoutUser()
    this.getGeoInfo()
  }

  getGeoInfo = () => {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        const { data } = response
        const { country_code: countryCode, country_name: countryName } = data
        const hostname = window.location.hostname
          .replace('pltstage.', '')
          .replace('www.', '')
          .replace('local.', '')

        if (
          !countrySites[countryCode] ||
          hostname === countrySites[countryCode] ||
          hostname === 'localhost'
        )
          return

        this.setState({
          countryCode,
          countryName,
          // countryBanner: true,
          countryModal: true,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleScroll = () => {
    this.setState({ scrollY: window.scrollY })
  }

  toggleMobileMenu = () => {
    if (window.innerWidth < 768) {
      if (!this.state.isOpen) {
        document.querySelector('html').style.overflow = 'hidden'
      } else {
        document.querySelector('html').style.overflow = 'auto'
      }
      this.setState({
        isOpen: !this.state.isOpen,
      })
    }
    this.setState({ editSearchOpen: false })
  }

  toggleAccountDropdown = () => {
    this.setState({
      accountDropdownOpen: !this.state.accountDropdownOpen,
    })
  }

  handleSeeAllPropClick = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const params = {
      roominfo: searchParams.get('roominfo'),
      checkindate: searchParams.get('checkindate'),
      checkoutdate: searchParams.get('checkoutdate'),
      locationCode: searchParams.get('locationCode'),
      location: searchParams.get('location'),
    }
    this.props.router.navigate({
      pathname: '/stays/search',
      search: `?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`,
    })
  }

  render() {
    const burger = this.state.isOpen ? ' is-active' : ''
    const path = this.props.router.location.pathname
    let { hostname, search } = window.location
    search += `${search === '' ? '?' : '&'}redirectedFrom=${hostname}`

    return (
      <>
        <Navbar
          className={classNames('Header', {
            'transparent-page': this.props.transparent,
            transparent:
              this.props.transparent && this.state.scrollY === 0 && window.innerWidth > 768,
            hasCountryBanner: this.state.countryBanner,
          })}
          expand='md'
          fixed='top'
          style={{
            marginTop: 0,
            justifyContent: 'center',
          }}
        >
          {['/stays', '/flights', '/'].includes(path) && (
            <Banner
              showBanner={this.state.showBanner}
              hideBanner={() => {
                this.setState({
                  showBanner: false,
                })
              }}
            />
          )}
          <Modal
            isOpen={this.state.countryModal}
            toggle={() => this.setState({ countryModal: !this.state.countryModal })}
            centered
            className='country-modal text-center'
          >
            <ModalBody>
              <h3>Hello!</h3>
              <p>Seems like you are visiting from {this.state.countryName}.</p>
              <a
                className='btn btn-primary'
                href={`https://${countrySites[this.state.countryCode]}${
                  window.location.pathname
                }${search}`}
              >
                Go to the{' '}
                <b>
                  {this.state.countryName} ({this.state.countryCode})
                </b>{' '}
                site
              </a>
            </ModalBody>
          </Modal>
          <div className='Header__logo'>
            <a
              className='navbar-brand'
              onClick={() => {
                if (path.includes('review')) {
                  if (
                    window.confirm(
                      'Are you sure you want to go home? Your current booking will be lost.',
                    )
                  ) {
                    if (this.state.isOpen) {
                      this.toggleMobileMenu()
                    }
                    this.props.router.navigate('/')
                  }
                } else {
                  if (this.state.isOpen) {
                    this.toggleMobileMenu()
                  }
                  this.props.router.navigate('/')
                }
                this.setState({ editSearchOpen: false })
              }}
            >
              <img
                alt='logo'
                width='170'
                className='logo blue-logo'
                src={_url('assets/images/plt-blue-logo.png')}
              />
              <img
                alt='logo'
                width='170'
                className='logo white-logo'
                src={_url('assets/images/plt-white-logo.png')}
              />
            </a>
            {path.includes('/stays/detail') && (
              <div className='back-to-results'>
                <a onClick={this.handleSeeAllPropClick}>See all properties</a>
              </div>
            )}
          </div>
          {path.includes('/flights/s/') && !this.state.isOpen && (
            <EditSearch
              productType='flights'
              searchQuery={this.props.searchQuery.flights}
              open={this.state.editSearchOpen}
              setOpen={(open) => this.setState({ editSearchOpen: open })}
            />
          )}
          {path.includes('/flightssearch/s/') && !this.state.isOpen && (
            <EditSearch
              productType='flights'
              searchQuery={this.props.searchQuery.flights}
              open={this.state.editSearchOpen}
              setOpen={(open) => this.setState({ editSearchOpen: open })}
            />
          )}
          <button
            className={`navbar-toggler hamburger hamburger--collapse${burger}`}
            type='button'
            onClick={this.toggleMobileMenu}
          >
            <span className='hamburger-box'>
              <span className='hamburger-inner' />
            </span>
          </button>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
              <NavItem>
                <NavLink to='/how-it-works' className='nav-link' onClick={this.toggleMobileMenu}>
                  How it works
                </NavLink>
              </NavItem>
              <NavItem>
                {!IS_USA_SITE ? (
                  <NavLink to='/faq' className='nav-link' onClick={this.toggleMobileMenu}>
                    FAQ
                  </NavLink>
                ) : (
                  <a href='https://help.paylatertravel.com/' className='nav-link'>
                    FAQ
                  </a>
                )}
              </NavItem>

              {!this.props.auth.isAuthenticated &&
                path !== '/login' &&
                !path.includes('review') &&
                !path.includes('confirm') && (
                  <button
                    className='header__button d-none d-md-block'
                    onClick={this.props.toggleLoginModal}
                  >
                    Log In
                  </button>
                )}
              {!this.props.auth.isAuthenticated &&
                path === '/login' &&
                !path.includes('review') &&
                !path.includes('confirm') && (
                  <NavItem>
                    <NavLink
                      to='/login'
                      className='header__button d-none d-md-block'
                      onClick={this.toggleMobileMenu}
                    >
                      Log In
                    </NavLink>
                  </NavItem>
                )}
              {!this.props.auth.isAuthenticated &&
                !path.includes('review') &&
                !path.includes('confirm') && (
                  <NavItem>
                    <NavLink
                      to='/login'
                      className='nav-link d-block d-md-none'
                      onClick={this.toggleMobileMenu}
                    >
                      Log In
                    </NavLink>
                  </NavItem>
                )}
              {this.props.auth.isAuthenticated && (
                <Dropdown
                  isOpen={this.state.accountDropdownOpen}
                  toggle={this.toggleAccountDropdown}
                  nav
                  inNavbar
                >
                  <DropdownToggle
                    className='header__button'
                    nav
                    caret={!path.includes('review')}
                    disabled={path.includes('review')}
                  >
                    {window.innerWidth < 768 ? 'My Account' : this.props.auth.user.firstName}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <div className='dropdown-item__primary-wrapper'>
                      <NavLink
                        to='/bookings'
                        className='dropdown-item'
                        onClick={() => {
                          this.toggleMobileMenu()
                          this.toggleAccountDropdown()
                        }}
                      >
                        My Bookings
                      </NavLink>
                      <NavLink
                        to='/refer'
                        className='dropdown-item'
                        onClick={() => {
                          this.toggleMobileMenu()
                          this.toggleAccountDropdown()
                        }}
                      >
                        Credit
                      </NavLink>
                      <NavLink
                        to='/refer'
                        className='dropdown-item refer-nav-link'
                        onClick={() => {
                          this.toggleMobileMenu()
                          this.toggleAccountDropdown()
                        }}
                      >
                        Refer and Get $25!
                      </NavLink>
                      {/* <DropdownItem>
                                        Account Details
                                        </DropdownItem> */}
                    </div>
                    <DropdownItem divider />
                    {/* <DropdownItem>
                                    Customer Support
                                    </DropdownItem> */}
                    <DropdownItem
                      onClick={() => {
                        this.toggleMobileMenu()
                        this.props.logoutUser()
                      }}
                    >
                      Sign Out
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </Nav>
          </Collapse>
          <div className='overlay' onClick={this.toggleMobileMenu} />
          <div
            className={classNames('edit-search-overlay', {
              active: this.state.editSearchOpen,
            })}
            onClick={() => this.setState({ editSearchOpen: false })}
          />
          <Modal
            isOpen={this.props.loginModal}
            toggle={this.props.toggleLoginModal}
            centered
            className='login-modal'
          >
            <ModalHeader toggle={this.props.toggleLoginModal}>Welcome back!</ModalHeader>
            <ModalBody>
              <LoginForm />
            </ModalBody>
          </Modal>
        </Navbar>
      </>
    )
  }
}

const mapStateToprops = (state) => ({
  loginModal: state.auth.loginModal,
  hotelsQuery: state.search.query.hotels,
  destination:
    state.detailHotel.images &&
    state.detailHotel.images[0] &&
    state.detailHotel.images[0].split('/')[0],
})

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => {
    dispatch(logoutUser())
  },
  toggleLoginModal: () => {
    dispatch({ type: TOGGLE_LOGIN_MODAL })
  },
})

export default withRouter(connect(mapStateToprops, mapDispatchToProps)(Header))
