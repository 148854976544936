import React from 'react';
import { Button } from 'reactstrap';
import { HomeHeroImageContainer } from '../../containers/homepage/Style';
import { scrollToHome } from '../../containers/homepage/Util';

const HomePopularDestination = ({ setSelectedTab, setSelectedLocation }) => (
    <div className="stays text-center">
        <h4>Popular destinations</h4>
        <h2>Ready for an adventure?</h2>
        <div className="stays__cardScrollWrapper">
            <div className="stays__cardWrapper">
                <HomeHeroImageContainer
                    bgimage={'assets/images/stays/sydney.jpg'}
                    className="stays__card card stays__card--sydney"
                    onClick={() => {
                        setSelectedTab(2);
                        setSelectedLocation('Sydney_NSW/');
                        scrollToHome();
                    }}
                >
                    <h4>Sydney</h4>
                    <p>New South Wales</p>
                </HomeHeroImageContainer>
                <HomeHeroImageContainer
                    className="stays__card card stays__card--goldcoast"
                    bgimage={'assets/images/stays/goldcoast.jpg'}
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/stays/goldcoast.jpg)`
                    }}
                    onClick={() => {
                        setSelectedTab(2);
                        setSelectedLocation('Gold Coast_QLD/');
                        scrollToHome();
                    }}
                >
                    <h4>Gold Coast</h4>
                    <p>Queensland</p>
                </HomeHeroImageContainer>
                <HomeHeroImageContainer
                    className="stays__card card stays__card--byron-bay"
                    bgimage={'assets/images/stays/byronbay.png'}
                    onClick={() => {
                        setSelectedTab(2);
                        setSelectedLocation('Byron Bay_NSW/');
                        scrollToHome();
                    }}
                >
                    <h4>Byron Bay</h4>
                    <p>New South Wales</p>
                </HomeHeroImageContainer>
                <HomeHeroImageContainer
                    className="stays__card card stays__card--cairns"
                    bgimage={'assets/images/stays/cairns.jpg'}
                    onClick={() => {
                        setSelectedTab(2);
                        setSelectedLocation('Cairns_QLD/');
                        scrollToHome();
                    }}
                >
                    <h4>Cairns</h4>
                    <p>Queensland</p>
                </HomeHeroImageContainer>

                <HomeHeroImageContainer
                    className="stays__card card stays__card--whitsundays"
                    bgimage={'assets/images/stays/whitsundays.jpg'}
                    onClick={() => {
                        setSelectedTab(2);
                        setSelectedLocation('Whitsundays_QLD/');
                        scrollToHome();
                    }}
                >
                    <h4>Whitsundays</h4>
                    <p>Queensland</p>
                </HomeHeroImageContainer>
            </div>
        </div>
        <Button
            color="primary"
            onClick={() => {
                setSelectedTab(2);
                scrollToHome();
            }}
        >
            Explore Stays
        </Button>
        <p style={{ marginTop: '20px' }}>Your getaway is right around the corner!</p>
    </div>
);

export default HomePopularDestination;